import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import moment from "moment";
import useStyles from "./styles";
import {
	Typography,
	Grid,
	Card,
	ButtonBase,
	Box,
	Button,
	CircularProgress,
	Paper,
} from "@material-ui/core";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import ChatBubbleOutlineIcon from "@material-ui/icons/ChatBubbleOutline";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import {
	acceptCollaboration,
	acceptFriendFromNotification,
	getNotifications,
	leaveAsSubscriber,
} from "../../actions/notifications";
import {
	getUnviewed,
	viewAllNotifications,
	deleteAllNotifications,
	deleteNotification,
} from "../../actions/notifications";
import PaginationNotifications from "./PaginationNotifications/PaginationNotifications";
import CancelIcon from "@material-ui/icons/Cancel";

const Notifications = ({ user, setUser }) => {
	const classes = useStyles();
	const dispatch = useDispatch();

	const [notifications, setNotifications] = useState({ type: "like", page: 1 });

	const openLikes = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "like", page: 1 };
		});
	};
	const openCommentsPost = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "commentPost", page: 1 };
		});
	};
	const openCommentsReport = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "commentReport", page: 1 };
		});
	};
	const openResponseComment = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "responseComment", page: 1 };
		});
	};
	const openSubscribe = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "subscribe", page: 1 };
		});
	};
	const openTrip = () => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, type: "trip", page: 1 };
		});
	};

	const handlePageChange = (newPage) => {
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: newPage };
		});
	};

	const toggleViewAll = async () => {
		await dispatch(viewAllNotifications(user?.result?._id));
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: 1 };
		});
	};

	const toggleDeleteAll = async () => {
		await dispatch(deleteAllNotifications(user?.result?._id));
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: 1 };
		});
	};

	const toggleAcceptFriend = async (id) => {
		const result = await dispatch(acceptFriendFromNotification(id));
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: 1 };
		});
	};

	const toggleAcceptTrip = async (tripId, notificationId) => {
		const result = await dispatch(acceptCollaboration(tripId, notificationId));
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: 1 };
		});
	};

	const toggleLeaveAsSubscriber = async (id) => {
		const result = await dispatch(leaveAsSubscriber(id));
		setNotifications((prevNotifications) => {
			return { ...prevNotifications, page: 1 };
		});
	};

	useEffect(() => {
		dispatch(getUnviewed(user?.result?._id, "like"));
		dispatch(getUnviewed(user?.result?._id, "commentPost"));
		dispatch(getUnviewed(user?.result?._id, "commentReport"));
		dispatch(getUnviewed(user?.result?._id, "responseComment"));
		dispatch(getUnviewed(user?.result?._id, "subscribe"));
		dispatch(getUnviewed(user?.result?._id, "trip"));
		dispatch(
			getNotifications(
				user?.result?._id,
				notifications?.page,
				notifications?.type
			)
		);
	}, [notifications]);

	const notificationsContent = useSelector(
		(state) => state.notifications.notificationsList
	);
	const unviewedLikes = useSelector(
		(state) => state.notifications.unviewedLikes
	);
	const unviewedCommentsPost = useSelector(
		(state) => state.notifications.unviewedCommentsPost
	);
	const unviewedCommentsReport = useSelector(
		(state) => state.notifications.unviewedCommentsReport
	);
	const unviewedResponseComment = useSelector(
		(state) => state.notifications.unviewedResponseComment
	);
	const unviewedSubscribe = useSelector(
		(state) => state.notifications.unviewedSubscribe
	);
	const unviewedTrip = useSelector((state) => state.notifications.unviewedTrip);
	const isLoading = useSelector((state) => state.notifications.isLoading);
	const isLoadingUnviewed = useSelector(
		(state) => state.notifications.isLoadingUnviewed
	);

	return (
		<div className={classes.postsOuterContainer}>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={2}
				className={classes.gridContainer}
			>
				<Grid item xs={12} sm={5} md={3}>
					<Card className={classes.profileCard}>
						<Typography variant="h6" className={classes.notificationsTitle}>
							Уведомления
						</Typography>
						<ButtonBase
							component="span"
							name="test"
							onClick={openLikes}
							className={classes.notificationsLink}
						>
							<ThumbUpAltOutlined fontSize="small" className={classes.logo} />
							<Typography>Лайки</Typography>
							<Box
								className={
									unviewedLikes === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedLikes}</Typography>
								)}
							</Box>
						</ButtonBase>
						<hr className={classes.divider} />
						<ButtonBase
							component="span"
							name="test"
							onClick={openSubscribe}
							className={classes.notificationsLink}
						>
							<AccountCircleIcon fontSize="small" className={classes.logo} />
							<Typography className={classes.notificationsType}>
								Подписчики
							</Typography>
							<Box
								className={
									unviewedSubscribe === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedSubscribe}</Typography>
								)}
							</Box>
						</ButtonBase>
						<hr className={classes.divider} />
						<ButtonBase
							component="span"
							name="test"
							onClick={openCommentsReport}
							className={classes.notificationsLink}
						>
							<ChatBubbleOutlineIcon
								fontSize="small"
								className={classes.logo}
							/>
							<Typography className={classes.notificationsType}>
								Комментарии к отчетам
							</Typography>
							<Box
								className={
									unviewedCommentsReport === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedCommentsReport}</Typography>
								)}
							</Box>
						</ButtonBase>
						<hr className={classes.divider} />
						<ButtonBase
							component="span"
							name="test"
							onClick={openCommentsPost}
							className={classes.notificationsLink}
						>
							<ChatBubbleOutlineIcon
								fontSize="small"
								className={classes.logo}
							/>
							<Typography className={classes.notificationsType}>
								Комментарии к постам
							</Typography>
							<Box
								className={
									unviewedCommentsPost === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedCommentsPost}</Typography>
								)}
							</Box>
						</ButtonBase>
						<hr className={classes.divider} />
						<ButtonBase
							component="span"
							name="test"
							onClick={openResponseComment}
							className={classes.notificationsLink}
						>
							<ChatBubbleOutlineIcon
								fontSize="small"
								className={classes.logo}
							/>
							<Typography className={classes.notificationsType}>
								Ответы на комментарии
							</Typography>
							<Box
								className={
									unviewedResponseComment === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedResponseComment}</Typography>
								)}
							</Box>
						</ButtonBase>
						<hr className={classes.divider} />
						<ButtonBase
							component="span"
							name="test"
							onClick={openTrip}
							className={classes.notificationsLink}
						>
							<ChatBubbleOutlineIcon
								fontSize="small"
								className={classes.logo}
							/>
							<Typography className={classes.notificationsType}>
								Приглашения в поездки
							</Typography>
							<Box
								className={
									unviewedTrip === 0
										? classes.notificationsWrapGrey
										: classes.notificationsWrap
								}
							>
								{isLoadingUnviewed ? (
									<CircularProgress color="white" size="0.8rem" />
								) : (
									<Typography>{unviewedTrip}</Typography>
								)}
							</Box>
						</ButtonBase>
						{/*<hr className={classes.divider} />
            <ButtonBase
              component="span"
              name="test"
              onClick={openLikes}
              className={classes.profileLink}
            >
              <Typography>Подписчики</Typography>
            </ButtonBase>*/}
						<Button
							className={`${classes.searchButton} ${classes.viewedButton}`}
							elevation={6}
							onClick={toggleViewAll}
						>
							<Typography className={`${classes.viewedText}`}>
								Отметить как прочитанное
							</Typography>
						</Button>
						<Button
							className={`${classes.searchButton} ${classes.viewedButton} ${classes.deleteButton}`}
							elevation={6}
							onClick={toggleDeleteAll}
						>
							<Typography className={`${classes.viewedText}`}>
								Удалить все
							</Typography>
						</Button>
					</Card>
				</Grid>
				<Grid item xs={12} sm={7} md={9}>
					<Card className={classes.profileCard}>
						<Typography
							variant="h4"
							component="h2"
							className={classes.sectionTitle}
						>
							{notifications?.type === "like"
								? "Лайки"
								: notifications?.type === "commentPost"
								? "Комментарии к постам"
								: notifications?.type === "commentReport"
								? "Комментарии к отчетам"
								: notifications?.type === "responseComment"
								? "Ответы на комментарии"
								: notifications?.type === "subscribe"
								? "Подписчики"
								: notifications?.type === "trip"
								? "Приглашения в поездки"
								: null}
						</Typography>
						{isLoading ? (
							<div className={classes.cardLoading}>
								<CircularProgress color="secondary" size="3rem" />
							</div>
						) : notificationsContent.length === 0 ? (
							<Typography>Уведомлений нет</Typography>
						) : (
							notificationsContent.map((c, i) => (
								<Card className={classes.cardNotification}>
									{c.isViewed === true ? null : (
										<Box className={classes.viewedBox}></Box>
									)}
									<Typography
										className={
											c.isViewed === true
												? classes.gotComments
												: classes.gotCommentsUnviewed
										}
									>
										{notifications?.type === "subscribe" ||
										notifications?.type === "trip"
											? c.name
											: null}
										{notifications?.type === "like"
											? `Лайк от `
											: notifications?.type === "subscribe"
											? ` хочет добавить вас в друзья`
											: notifications?.type === "trip"
											? ` приглашает вас в поездку`
											: `Получен комментарий от `}
										{notifications?.type !== "subscribe" &&
										notifications?.type !== "trip"
											? c.name
											: null}{" "}
									</Typography>
									{notifications?.type !== "subscribe" ? (
										<>
											<Typography className={classes.moment}>
												{moment(c.createdAt).fromNow()}
											</Typography>
											<Button
												className={`${classes.searchButton}`}
												raised
												elevation={6}
												href={
													notifications?.type === "like" ||
													notifications?.type === "responseComment"
														? c.postId
															? `/post/${c.postId}`
															: `/posts/${c.reportId}`
														: notifications?.type === "commentPost"
														? `/post/${c.postId}`
														: notifications?.type === "commentReport"
														? `/posts/${c.reportId}`
														: notifications?.type === "trip"
														? `/trip/${c?.tripId}`
														: "/"
												}
											>
												<Typography>
													Перейти{" "}
													{notifications?.type === "like"
														? ""
														: notifications?.type === "commentPost"
														? "в пост"
														: notifications?.type === "commentReport"
														? "в отчет"
														: notifications?.type === "responseComment"
														? "к ответу"
														: notifications?.type === "trip"
														? "в поездку"
														: null}
												</Typography>
											</Button>
											{notifications?.type === "trip" ? (
												<>
													<Button
														className={`${classes.searchButton}`}
														raised
														elevation={6}
														onClick={() => toggleAcceptTrip(c?.tripId, c?._id)}
													>
														<Typography>Принять</Typography>
													</Button>
												</>
											) : null}
											<Button
												className={classes.deleteOneButton}
												onClick={async () => {
													await dispatch(deleteNotification(c?._id));
													setNotifications((prevNotifications) => {
														return { ...prevNotifications, page: 1 };
													});
												}}
											>
												<CancelIcon />
											</Button>
										</>
									) : null}
									{notifications?.type === "subscribe" ? (
										<>
											<Button
												className={`${classes.searchButton}`}
												raised
												elevation={6}
												onClick={() => toggleAcceptFriend(c?._id)}
											>
												<Typography>Принять</Typography>
											</Button>
											<Button
												className={`${classes.declineButton}`}
												raised
												elevation={6}
												onClick={() => toggleLeaveAsSubscriber(c?._id)}
											>
												<Typography>Оставить в подписчиках</Typography>
											</Button>
										</>
									) : null}
								</Card>
							))
						)}
					</Card>
					<Paper className={classes.pagination}>
						<PaginationNotifications
							onPageChange={handlePageChange}
							currentPage={notifications?.page}
						/>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default Notifications;
