import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	container: {
		display: "flex",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(0.7),
		backgroundColor: "#f9f9f9",
		borderRadius: "8px",
		boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
		marginBottom: theme.spacing(1),
	},
	label: {
		fontSize: "0.9rem",
		fontWeight: 500,
		color: "#555",
	},
	select: {
		minWidth: "150px",
		fontSize: "0.9rem",
		color: "#333",
		backgroundColor: "#fff",
		border: "1px solid #ccc",
		borderRadius: "4px",
		padding: theme.spacing(0.5, 1),
		cursor: "pointer",
		outline: "none",
		"&:focus": {
			borderColor: theme.palette.primary.main,
		},
	},
}));
