// src/components/Profile/ProfileAbout.jsx

import React from "react";
import { Card, Typography, Button, TextField } from "@material-ui/core";
import useStyles from "../styles";
import textFormat from "../../../images/text-format.png";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProfileAbout = ({
	isLoadingUser,
	user,
	checkUser,
	isChangeAbout,
	setIsChangeAbout,
	handleEditChange,
	editFormData,
	handleSubmitForb,
	changeAbout,
}) => {
	const classes = useStyles();

	return (
		<Card className={classes.cardAbout}>
			<div className={classes.titleStatisticsFlex}>
				<Typography variant="h5">О себе</Typography>
				<img
					src={textFormat}
					className={classes.imageAbout}
					alt="totravel"
					height="20px"
					width="20px"
				/>
			</div>
			{isLoadingUser ? (
				<div className={classes.circularOther}>
					<CircularProgress color="secondary" />
				</div>
			) : (
				<>
					{isChangeAbout ? (
						<form className={classes.form} onSubmit={handleSubmitForb}>
							<TextField
								name="about"
								variant="outlined"
								label="О себе"
								fullWidth
								multiline
								maxRows={6}
								value={editFormData?.result?.about}
								onChange={handleEditChange}
							/>
							<Button
								className={classes.buttonPrimary}
								variant="contained"
								size="large"
								type="submit"
								fullWidth
							>
								Написать
							</Button>
						</form>
					) : user?.about ? (
						<>
							<Typography>{user.about}</Typography>
							{checkUser ? (
								<div className={classes.buttonBoxAbout}>
									<Button
										className={classes.buttonPrimary}
										onClick={() => setIsChangeAbout(!isChangeAbout)}
									>
										Изменить описание
									</Button>
								</div>
							) : null}
						</>
					) : (
						<>
							{checkUser ? (
								<>
									<Typography>
										Можете написать о своих путешествиях, количестве стран,
										которые вы посетили
									</Typography>
									<Button
										className={classes.buttonPrimary}
										onClick={changeAbout}
									>
										Расскажите о себе
									</Button>
								</>
							) : (
								<Typography>
									Пользователь не указал информацию о себе
								</Typography>
							)}
						</>
					)}
				</>
			)}
		</Card>
	);
};

export default ProfileAbout;
