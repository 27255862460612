// File: PostContentContainerSkeleton.js
import React from "react";
import { Skeleton } from "@material-ui/lab"; // Skeleton component from Material-UI

const PostContentContainerSkeleton = ({ classes }) => {
	return (
		<div className={classes.postCard}>
			<div className={classes.upperContainer}>
				{/* Skeleton for the title */}
				<Skeleton
					variant="text"
					width="60%"
					height={40}
					className={classes.skeletonTitle}
				/>
				{/* Skeleton for the button */}
				<Skeleton
					variant="rectangular"
					width={120}
					height={36}
					className={classes.legendButtonOpen}
				/>
			</div>
			{/* Skeleton for content */}
			<div>
				{[...Array(2)].map((_, index) => (
					<div key={index} className={classes.contentBlock}>
						{/* Skeleton for image */}
						<Skeleton
							variant="rectangular"
							className={classes.skeletonImage}
							height={200}
						/>
						{/* Skeleton for text */}
						<Skeleton
							variant="text"
							width="80%"
							className={classes.skeletonText}
						/>
						<Skeleton
							variant="text"
							width="60%"
							className={classes.skeletonText}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default PostContentContainerSkeleton;
