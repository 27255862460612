import React, { useState } from "react";
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Button,
	Typography,
	ButtonBase,
	Box,
} from "@material-ui/core";
import ThumbUpAltIcon from "@material-ui/icons/ThumbUpAlt";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";
import useStyles from "./styles.js";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import "moment/locale/ru";

import { deletePost, likePost } from "../../../actions/posts.js";

const Post = ({ user, post, setCurrentId }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const [likes, setLikes] = useState(post?.likes);
	moment.locale("ru");

	const handleLike = async () => {
		dispatch(likePost(post._id));

		if (post.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				post.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...post.likes, user?.result?._id]);
		}
	};

	const Likes = () => {
		if (likes?.length > 0) {
			return likes.find((like) => like === user?.result?._id) ? (
				<>
					<ThumbUpAltIcon fontSize="small" sx={{ mr: "4px" }} />
					&nbsp;
					{likes.length > 2
						? `Вы и ${likes.length - 1} других`
						: `${likes.length} лайк${likes.length > 1 ? "а" : ""}`}
				</>
			) : (
				<>
					<ThumbUpAltOutlined fontSize="small" />
					&nbsp;{likes.length} {likes.length === 1 ? "Лайк" : "Лайка"}
				</>
			);
		}
		return (
			<>
				<ThumbUpAltOutlined fontSize="small" />
				&nbsp;Лайк
			</>
		);
	};

	const openPost = () => (window.location.href = `/posts/${post._id}`);

	return (
		<Card className={classes.card} raised elevation={6}>
			{/*<div className={classes.overlay2}>
        {(user?.result?._id === post?.creator ||
          user?.result?.googleId === post?.creator) && (
          <Button
            style={{ color: "white" }}
            size="small"
            onClick={() => setCurrentId(post._id)}
          >
            <MoreHorizIcon fontSize="medium" />
          </Button>
        )}
          </div>*/}
			<ButtonBase
				component="span"
				name="test"
				className={classes.cardAction}
				onClick={openPost}
			>
				{post.selectedFile ? (
					<CardMedia
						className={classes.media}
						image={post.selectedFile}
						title={post.title}
					/>
				) : (
					<div className={classes.media}></div>
				)}
				<div className={classes.overlay}>
					<Typography variant="h6">{post.name}</Typography>
					<Typography variant="body2">
						{moment(post.createdAt).fromNow()}
					</Typography>
				</div>

				<div className={classes.details}>
					{post.tags
						? post.tags.map((tag) => (
								<Box className={classes.tagBox}>
									<Typography variant="body2">{tag}</Typography>
								</Box>
						  ))
						: "Страны"}
				</div>
				<Typography className={classes.title} variant="h5" gutterBottom>
					{post.title}
				</Typography>
				<CardContent>
					<Typography variant="body2" color="textSecondary" component="p">
						{post.message}
					</Typography>
				</CardContent>
			</ButtonBase>
			<CardActions className={classes.cardActions}>
				<Button
					size="small"
					className={classes.buttonLike}
					disabled={!user?.result}
					onClick={handleLike}
				>
					<Likes />
				</Button>
				<Box className={classes.visitedBox}>
					<VisibilityIcon fontSize="small" className={classes.visitedIcon} />
					<Typography>{post?.visited}</Typography>
				</Box>
			</CardActions>
		</Card>
	);
};

export default Post;
