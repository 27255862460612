import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 600,
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		"@media (max-width: 1100px)": {
			width: 600,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		"@media (max-width: 650px)": {
			width: "100%",
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	},
	cardContent: {
		padding: "8px",
		"@media (max-width: 600px)": {
			padding: "6px",
		},
		"&:last-child": {
			paddingBottom: "8px",
			"@media (max-width: 600px)": {
				padding: "6px",
			},
		},
	},
	headerText: {
		marginBottom: "6px",
		color: "rgba(0, 0, 0, 0.87)",
	},
	sortingBox: {
		display: "flex",
		flexDirection: "column",
		gap: "6px",
	},
	sortingItemBox: {
		display: "flex",
		alignItems: "center",
	},
	sortIconTrue: {
		color: "rgb(236,98,98)",
		marginRight: "5px",
		transition: "color 0.3s",
	},
	sortIconFalse: {
		color: "rgba(0, 0, 0, 0.54)",
		marginRight: "5px",
		transition: "color 0.3s",
	},
	sortButton: {
		justifyContent: "flex-start",
		textTransform: "none",
		padding: "4px 0",
		border: "2px solid transparent",
		transition: "border 0.3s, background-color 0.3s",
		"&:hover": {
			borderColor: "rgba(236,98,98,1)",
			backgroundColor: "rgba(236,98,98,0.1)",
		},
		"&:focus": {
			backgroundColor: "rgba(236,98,98,0.1)",
		},
		"@media (max-width: 1100px)": {
			padding: "3px 0",
		},
	},
}));
