import React, { useEffect, useState } from "react";
import {
	TextField,
	Button,
	MenuItem,
	Select,
	InputLabel,
	FormControl,
	DialogTitle,
	DialogContent,
	DialogActions,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import useStyles from "./styles";
import { validateInput } from "../../../utils/validationUtils";

const StayDialog = ({
	stay,
	setStay,
	onClose,
	handleSaveStay,
	currencyOptions,
	openStayModal,
}) => {
	const classes = useStyles();

	useEffect(() => {
		stay?.departureDate &&
			setStay((prev) => ({
				...prev,
				departureDate: moment(prev.departureDate).format("YYYY-MM-DD"),
			}));
		stay?.arrivalDate &&
			setStay((prev) => ({
				...prev,
				arrivalDate: moment(prev.arrivalDate).format("YYYY-MM-DD"),
			}));
	}, [openStayModal]);

	const [errors, setErrors] = useState({});

	const limits = {
		name: 100,
		address: 1000,
		website: 1000,
		location: 1000,
		totalCost: { min: 0, max: 10000000 },
		numberOfPeople: { min: 1, max: 200 },
	};

	const handleChange = (e) => {
		setStay({ ...stay, [e.target.name]: e.target.value });
		validateInput(e.target.name, e.target.value, limits, setErrors);
	};

	const handleChangeCurrency = (event, newValue) => {
		setStay({ ...stay, currency: newValue || "" });
	};

	return (
		<>
			<DialogTitle>Добавить проживание</DialogTitle>
			<DialogContent>
				<FormControl fullWidth>
					<InputLabel>Тип проживания</InputLabel>
					<Select name="type" value={stay.type} onChange={handleChange}>
						<MenuItem value="hotel">Отель</MenuItem>
						<MenuItem value="apartment">Апартаменты</MenuItem>
						<MenuItem value="hostel">Хостел</MenuItem>
						<MenuItem value="friends">У друзей</MenuItem>
						<MenuItem value="other">Другое</MenuItem>
					</Select>
				</FormControl>

				<TextField
					label="Дата заезда"
					type="date"
					name="arrivalDate"
					value={stay.arrivalDate}
					onChange={handleChange}
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>

				<TextField
					label="Дата выезда"
					type="date"
					name="departureDate"
					value={stay.departureDate}
					onChange={handleChange}
					fullWidth
					InputLabelProps={{ shrink: true }}
				/>

				<TextField
					label="Название места проживания"
					name="name"
					value={stay.name}
					onChange={handleChange}
					fullWidth
					error={!!errors.name}
					helperText={errors.name}
				/>

				<TextField
					label="Адрес места проживания"
					name="address"
					value={stay.address}
					onChange={handleChange}
					fullWidth
				/>

				<TextField
					label="Ссылка на карту"
					name="location"
					value={stay?.location}
					onChange={handleChange}
					fullWidth
					error={!!errors.location}
					helperText={errors.location}
				/>

				<TextField
					label="Сайт места проживания"
					name="website"
					value={stay.website}
					onChange={handleChange}
					fullWidth
					error={!!errors.website}
					helperText={errors.website}
				/>

				<TextField
					label="Стоимость проживания"
					type="number"
					name="totalCost"
					value={stay.totalCost}
					onChange={handleChange}
					fullWidth
					error={!!errors.totalCost}
					helperText={errors.totalCost}
				/>

				<FormControl fullWidth>
					<Autocomplete
						freeSolo // Allows manual input
						options={currencyOptions} // List of available currencies
						value={stay.currency}
						onChange={handleChangeCurrency}
						renderInput={(params) => (
							<TextField
								label="Валюта"
								className={classes.currencyInputText}
								{...params}
							/>
						)}
					/>
				</FormControl>

				<TextField
					label="Количество людей"
					type="number"
					name="numberOfPeople"
					value={stay.numberOfPeople}
					onChange={handleChange}
					fullWidth
					error={!!errors.numberOfPeople}
					helperText={errors.numberOfPeople}
				/>
			</DialogContent>

			<DialogActions>
				<Button color="secondary" onClick={onClose}>
					Отмена
				</Button>
				<Button
					color="primary"
					onClick={handleSaveStay}
					disabled={Object.values(errors).some((err) => err)}
				>
					Сохранить
				</Button>
			</DialogActions>
		</>
	);
};

export default StayDialog;
