// StickyButtonRow.jsx
import React from "react";
import { Button, Card } from "@material-ui/core";
import useStyles from "./styles.js";

const StickyButtonRow = ({ place, scrollToSection }) => {
	const classes = useStyles();

	return (
		<Card className={`${classes.buttonRow} ${classes.sticky}`}>
			<Button
				onClick={() => scrollToSection("mapSection")}
				className={classes.buttonPrimaryMenu}
			>
				Карта
			</Button>
			<Button
				onClick={() => scrollToSection("infoSection")}
				className={classes.buttonPrimaryMenu}
			>
				Информация
			</Button>
			<Button
				onClick={() => scrollToSection("photosSection")}
				className={classes.buttonPrimaryMenu}
			>
				Фотографии
			</Button>
			{place?.placeType === "city" ? (
				<Button
					onClick={() => scrollToSection("cityTodoSection")}
					className={classes.buttonPrimaryMenu}
				>
					Что посетить
				</Button>
			) : null}
			{place?.placeType === "city" ? (
				<Button
					onClick={() => scrollToSection("howToGetSection")}
					className={classes.buttonPrimaryMenu}
				>
					Как добраться
				</Button>
			) : null}
			<Button
				onClick={() => scrollToSection("reviewsSection")}
				className={classes.buttonPrimaryMenu}
			>
				Отзывы
			</Button>
			{place?.postsId[0] !== null && (
				<Button
					onClick={() => scrollToSection("postsSection")}
					className={classes.buttonPrimaryMenu}
				>
					Посты
				</Button>
			)}
		</Card>
	);
};

export default StickyButtonRow;
