import React from "react";
import {
	Paper,
	Typography,
	Button,
	List,
	ListItem,
	ListItemText,
	ListItemIcon,
} from "@material-ui/core";
import {
	AccountCircle,
	Flight,
	Hotel,
	LocationOn,
	AttachMoney,
	Assessment,
} from "@material-ui/icons";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import details from "../../../images/details.png";
import costs from "../../../images/costs.png";
import statistics from "../../../images/statistics.png";
import addCities from "../../../images/addCities.png";
import activities from "../../../images/activities.png";
import tripMaps from "../../../images/tripMaps.png";

const TripsLandingPage = ({ user }) => {
	const classes = useStyles();
	const history = useHistory();

	return (
		<Paper className={classes.landingContainer}>
			<Typography
				variant="h5"
				gutterBottom
				style={{ color: classes.sectionTitle.color, fontWeight: "bold" }}
			>
				Планируй свои поездки на ToTravelRu ✈️🌍
			</Typography>

			{!user && (
				<div className={classes.featureSection}>
					<Typography variant="subtitle1" gutterBottom>
						<span role="img" aria-label="lock">
							🔒
						</span>{" "}
						Сначала тебе нужно создать аккаунт или войти, чтобы начать
						планировать свои крутые поездки!
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => history.push("/auth")}
					>
						Зарегистрироваться / Войти
					</Button>
				</div>
			)}

			<div className={classes.featureSection}>
				<Typography variant="subtitle1" gutterBottom>
					<span role="img" aria-label="plus">
						➕
					</span>{" "}
					Готов создать свою поездку? Следуй инструкции:
				</Typography>
				<List dense>
					{!user && (
						<ListItem>
							<ListItemIcon style={{ color: classes.benefit.color }}>
								<AccountCircle />
							</ListItemIcon>
							<ListItemText primary="1. Зарегистрируйся или войди в свой аккаунт." />
						</ListItem>
					)}
					<ListItem>
						<ListItemIcon style={{ color: classes.benefit.color }}>
							<AccountCircle />
						</ListItemIcon>
						<ListItemText
							primary={`${
								user ? "1." : "2."
							} Перейди на страницу своего профиля.`}
						/>
					</ListItem>
					<ListItem>
						<ListItemIcon style={{ color: classes.benefit.color }}>
							<span style={{ fontSize: "1.2em", marginRight: "16px" }}>🖱️</span>
						</ListItemIcon>
						<ListItemText
							primary={`${
								user ? "2." : "3."
							} Нажми на кнопку "Добавить поездку".`}
						/>
					</ListItem>
				</List>
				{user && (
					<Button
						variant="outlined"
						className={classes.profileButton}
						onClick={() => history.push(`/user/${user?._id}`)}
					>
						Перейти в профиль
					</Button>
				)}
			</div>

			<Typography variant="h6" className={classes.sectionTitle} gutterBottom>
				Отличные функции для улучшения твоего планирования путешествий
			</Typography>

			<div className={classes.benefitsSection}>
				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<LocationOn style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Установи{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							точку начала
						</Typography>{" "}
						своей поездки, все классные{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							места, которые ты посетишь
						</Typography>
						, и{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							точку окончания поездки
						</Typography>
						.
					</Typography>
				</div>

				{/* Add screenshots here - you'll need to import and display them */}
				<div className={classes.screenshotPlaceholder}>
					<img src={addCities} alt="" className={classes.screenshot} />
				</div>

				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<Flight style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Укажи важные детали об{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							авиабилетах
						</Typography>{" "}
						и{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							отелях
						</Typography>{" "}
						, чтобы ничего не забыть.
					</Typography>
				</div>

				<div className={classes.screenshotPlaceholder}>
					<img src={details} alt="" className={classes.screenshot} />
				</div>

				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<Hotel style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Никогда не забывай, что ты хочешь увидеть и сделать! Добавляй все
						крутые{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							места, которые ты посетишь
						</Typography>{" "}
						в каждом городе.
					</Typography>
				</div>

				<div className={classes.screenshotPlaceholder}>
					<img src={activities} alt="" className={classes.screenshot} />
				</div>

				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<AttachMoney style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Легко{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							рассчитывай общую стоимость
						</Typography>{" "}
						своих поездок в разных валютах, чтобы оставаться в рамках бюджета.
					</Typography>
				</div>

				<div className={classes.screenshotPlaceholder}>
					<img src={costs} alt="" className={classes.screenshot} />
				</div>

				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<Assessment style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Получай полезные сведения с помощью классной{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							статистики
						</Typography>{" "}
						о своих планах путешествий.
					</Typography>
				</div>

				<div className={classes.screenshotPlaceholder}>
					<img src={statistics} alt="" className={classes.screenshot} />
				</div>

				<div className={classes.benefit}>
					<ListItemIcon style={{ color: classes.benefit.color }}>
						<Assessment style={{ fontSize: "2em" }} />
					</ListItemIcon>
					<Typography variant="subtitle1">
						Выбирай города через поиск и создавай свою{" "}
						<Typography component="span" style={{ fontWeight: "bold" }}>
							карту путешествий
						</Typography>
						.
					</Typography>
				</div>

				<div className={classes.screenshotPlaceholder}>
					<img src={tripMaps} alt="" className={classes.screenshot} />
				</div>
			</div>

			<Typography
				variant="subtitle2"
				color="textSecondary"
				className={classes.note}
			>
				Начни планировать свое следующее приключение сегодня!
			</Typography>
		</Paper>
	);
};

export default TripsLandingPage;
