import React, { useState, useEffect } from "react";
import {
	Grid,
	TextField,
	Button,
	Typography,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Paper,
} from "@material-ui/core";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import {
	addAlert,
	blockIP,
	clearPasswords,
	deleteAllAlerts,
	emptyImagesForAllPlaces,
	getBlockedIPs,
	unblockIP,
	getClaims,
	getLastTrips,
} from "../../actions/admin.js";
import {
	acceptPlaceDescription,
	deletePlaceDescriptionById,
	getAllPlaceDescriptions,
} from "../../actions/placeDescription.js";
import LastPhotos from "./LastPhotos/LastPhotos.jsx";
import LastComments from "./LastComments/LastComments.jsx";
import LastPlaces from "./LastPlaces/LastPlaces.jsx";

const AdminPanel = ({ user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const placeDescriptions = useSelector((state) => {
		return state.place.placeDescription;
	});

	const [ipToBlock, setIpToBlock] = useState("");
	const blockedIPs = useSelector((state) => state.admin.blockedIPs);
	const claims = useSelector((state) => state.admin.claims);
	const lastTrips = useSelector((state) => state.admin?.lastTrips);

	useEffect(() => {
		dispatch(getAllPlaceDescriptions());
	}, []);

	useEffect(() => {
		dispatch(getBlockedIPs());
		dispatch(getClaims());
	}, [dispatch]);

	const [notificationText, setNotificationText] = useState("");
	const [notificationType, setNotificationType] = useState("");
	const [expireAt, setExpireAt] = useState("");

	const handleNotificationTextChange = (event) => {
		setNotificationText(event.target.value);
	};

	const handleNotificationTypeChange = (event) => {
		setNotificationType(event.target.value);
	};

	const handleExpireAtChange = (event) => {
		setExpireAt(event.target.value);
	};

	const handleAcceptDescription = (descId) => {
		dispatch(acceptPlaceDescription(descId));
	};

	const handleDeclineDescription = (descId) => {
		dispatch(deletePlaceDescriptionById(descId));
	};

	const handleSendNotification = () => {
		dispatch(
			addAlert(notificationText, notificationType, expireAt, user?.result?._id)
		);
		setNotificationText("");
		setNotificationType("");
		setExpireAt("");
	};

	const handleEmptyImagesForAllPlaces = () => {
		dispatch(emptyImagesForAllPlaces());
	};

	const handleDeleteAllAlerts = () => {
		dispatch(deleteAllAlerts(user?.result?._id));
	};

	const handleBlockIP = () => {
		if (ipToBlock) {
			dispatch(blockIP(ipToBlock));
			setIpToBlock("");
		}
	};

	const handleUnblockIP = (ip) => {
		dispatch(unblockIP(ip));
	};

	const handleClearPasswords = () => {
		// Dispatch action to clear passwords
		dispatch(clearPasswords(user?.result?._id)); // Send userId if necessary
	};

	if (user?.result?.userType !== "admin") {
		return <Typography variant="h6">Вы не администратор</Typography>;
	}

	const hasNonEmptyHours = (workingHours) => {
		return Object.values(workingHours).some((hours) => hours.length > 0);
	};

	return (
		<div>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={3}
				className={classes.gridContainer}
			>
				<Grid item xs={12} sm={6} md={3}>
					<div>
						<Typography variant="h6">Удалить все уведомления</Typography>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleDeleteAllAlerts}
						>
							Удалить
						</Button>
					</div>
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					<div>
						<Typography variant="h5">Добавить уведомление</Typography>
						<TextField
							label="Текст уведомления"
							variant="outlined"
							value={notificationText}
							onChange={handleNotificationTextChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Тип уведомления"
							variant="outlined"
							value={notificationType}
							onChange={handleNotificationTypeChange}
							fullWidth
							margin="normal"
						/>
						<TextField
							label="Срок действия"
							variant="outlined"
							type="date"
							value={expireAt}
							onChange={handleExpireAtChange}
							fullWidth
							margin="normal"
							InputLabelProps={{
								shrink: true,
							}}
						/>
						<Button
							variant="contained"
							color="primary"
							onClick={handleSendNotification}
						>
							Отправить уведомление
						</Button>
					</div>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5">Управление заблокированными IP</Typography>
					<TextField
						label="IP Address"
						variant="outlined"
						value={ipToBlock}
						onChange={(e) => setIpToBlock(e.target.value)}
						fullWidth
						margin="normal"
					/>
					<Button variant="contained" color="primary" onClick={handleBlockIP}>
						Заблокировать IP
					</Button>
					<div>
						<Typography variant="h6">Заблокированные IP</Typography>
						{blockedIPs.length > 0 ? (
							blockedIPs.map((item) => (
								<div key={item._id}>
									<Typography>{item.ip}</Typography>
									<Button
										variant="contained"
										color="secondary"
										onClick={() => handleUnblockIP(item.ip)}
									>
										Разблокировать
									</Button>
								</div>
							))
						) : (
							<Typography>Заблокированных IP нет</Typography>
						)}
					</div>
				</Grid>
				<Grid item xs={12}>
					<Typography variant="h5" className={classes.sectionTitle}>
						Список жалоб
					</Typography>
					<TableContainer component={Paper}>
						<Table className={classes.table} aria-label="simple table">
							<TableHead>
								<TableRow>
									<TableCell>ID пользователя</TableCell>
									<TableCell>Тип жалобы</TableCell>
									<TableCell>Описание</TableCell>
									<TableCell>ID места</TableCell>
									<TableCell>ID отчета</TableCell>
									<TableCell>ID поста</TableCell>
									<TableCell>Фото</TableCell>
									<TableCell>Дата создания</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{claims &&
									claims.map((claim) => (
										<TableRow key={claim._id}>
											<TableCell component="th" scope="row">
												{claim?.userId}
											</TableCell>
											<TableCell>{claim?.malignChoose}</TableCell>
											<TableCell>{claim?.description}</TableCell>
											<TableCell>{claim?.placeId}</TableCell>
											<TableCell>{claim?.reportId}</TableCell>
											<TableCell>{claim?.postId}</TableCell>
											<TableCell>
												{claim.photoUrl ? (
													<Button
														component="a"
														href={claim?.photoUrl}
														target="_blank"
														rel="noopener noreferrer"
														color="primary"
														size="small"
													>
														Посмотреть фото
													</Button>
												) : (
													<Typography variant="body2" color="textSecondary">
														Нет фото
													</Typography>
												)}
											</TableCell>
											<TableCell>
												{new Date(claim.createdAt).toLocaleDateString()}
											</TableCell>
										</TableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Grid>
				<Grid item xs={12}>
					<div>
						<Typography variant="h6">Удалить все пароли</Typography>
						<Button
							variant="contained"
							color="secondary"
							onClick={handleClearPasswords}
						>
							Удалить пароли
						</Button>
					</div>
				</Grid>
			</Grid>
			<div className={classes.outerContainerDesc}>
				{placeDescriptions ? (
					placeDescriptions.map((c) => (
						<div key={c?._id} className={classes.containerDesc}>
							<Typography variant="h6">{c?.place}</Typography>
							<Typography>{c?.description}</Typography>
							{c?.phone && <Typography>Phone: {c?.phone}</Typography>}
							{c?.website && <Typography>Website: {c?.website}</Typography>}
							{c?.workingHours && hasNonEmptyHours(c.workingHours) && (
								<div>
									<Typography variant="subtitle1">Working Hours:</Typography>
									{Object.entries(c.workingHours).map(([day, hours]) => (
										<Typography key={day}>
											{day.charAt(0).toUpperCase() + day.slice(1)}:{" "}
											{hours.join(" - ")}
										</Typography>
									))}
								</div>
							)}

							{c?.paymentMethod && (
								<Typography>Payment Method: {c?.paymentMethod}</Typography>
							)}
							{c?.cuisine && (
								<Typography>Cuisine: {c?.cuisine.join(", ")}</Typography>
							)}
							<Button
								className={classes.buttonDesc}
								onClick={() => handleAcceptDescription(c?._id)}
								variant="contained"
								color="primary"
							>
								Принять
							</Button>
							<Button
								className={classes.buttonDesc}
								onClick={() => handleDeclineDescription(c?._id)}
								variant="contained"
								color="secondary"
							>
								Отклонить
							</Button>
						</div>
					))
				) : (
					<Typography>No place descriptions available.</Typography>
				)}
			</div>
			<LastPhotos />
			<LastComments />
			<LastPlaces />
			<Grid item xs={12} sm={6} md={3}>
				<div>
					<Typography variant="h6">
						Последние 10 поездок пользователей
					</Typography>
					<Button
						variant="contained"
						color="primary"
						onClick={() => dispatch(getLastTrips())}
					>
						Загрузить поездки
					</Button>
				</div>
			</Grid>
			{lastTrips && lastTrips.length > 0 && (
				<Grid item xs={12}>
					<Typography variant="h5">Последние поездки</Typography>
					<ul>
						{lastTrips.map((trip) => (
							<li key={trip._id}>
								<Typography variant="subtitle1">
									Поездка: {trip.tripName} (ID: {trip._id})
								</Typography>
								<Typography>
									Пользователь: {trip.userId?.name || trip.userId}
								</Typography>
								{trip.countries && trip.countries.length > 0 && (
									<Typography>Страны: {trip.countries.join(", ")}</Typography>
								)}
								{trip.cities && trip.cities.length > 0 && (
									<Typography>
										Посещенные города:{" "}
										{trip.cities.map((city) => city.name).join(", ")}
									</Typography>
								)}
								<Typography variant="caption">
									Создано: {new Date(trip.createdAt).toLocaleDateString()}
								</Typography>
								<hr />
							</li>
						))}
					</ul>
				</Grid>
			)}
		</div>
	);
};

export default AdminPanel;
