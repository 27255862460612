import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button, Avatar, Container } from "@material-ui/core";
import useStyles from "./styles";
import { Flight } from "@material-ui/icons";

const TripProfile = ({ trip }) => {
	const classes = useStyles();

	return (
		<div className={classes.profile}>
			<Link className={classes.profileLink} to={`/user/${trip?.userId?.login}`}>
				<Avatar
					className={
						trip?.userId?.userType === undefined
							? classes.purple
							: classes.purpleSpecial
					}
					alt={trip?.userId?.name}
					src={trip?.userId?.picture}
				>
					{trip?.userId?.name.charAt(0)}
				</Avatar>
			</Link>
			<Link
				className={
					trip?.userId?.userType === undefined
						? classes.nameBox
						: classes.nameBoxSpecial
				}
				to={`/user/${trip?.userId?.login}`}
			>
				<Container className={classes.nameBox}>
					<Typography className={classes.userName} variant="subtitle1">
						{trip?.userId?.name}
					</Typography>
					<Typography className={classes.userLogin} variant="subtitle2">
						@{trip?.userId?.login}
					</Typography>
				</Container>
			</Link>
			<Link
				to={`/user/${trip?.userId?.login}/trips`}
				style={{ textDecoration: "none" }}
			>
				<Button
					variant="outlined"
					color="secondary"
					className={classes.buttonPrimary}
					startIcon={<Flight />}
				>
					Поездки пользователя
				</Button>
			</Link>
		</div>
	);
};

export default TripProfile;
