import React from "react";
import { Card, Typography } from "@material-ui/core";
import useStyles from "../styles";
import HandleFriend from "../HandleFriend/HandleFriend";
import ProfilePeople from "../ProfilePeople/ProfilePeople";
import ProfilePeopleSkeleton from "../ProfilePeople/ProfilePeopleSkeleton";

const ProfileFriendsSubscribers = ({
	user,
	friends,
	subscribers,
	userCurrent,
	checkUser,
	isLoadingUser,
}) => {
	const classes = useStyles();

	return (
		<>
			<Card className={classes.card}>
				{isLoadingUser ? (
					<ProfilePeopleSkeleton />
				) : (
					<ProfilePeople
						list={friends}
						user={user}
						isLoadingUser={isLoadingUser}
					/>
				)}
			</Card>
			<div className={classes.spaceDivider}></div>
			<Card className={classes.card}>
				{isLoadingUser ? (
					<ProfilePeopleSkeleton />
				) : (
					<ProfilePeople list={subscribers} type="subscribers" user={user} />
				)}
			</Card>

			<div className={classes.spaceDivider}></div>
			{checkUser || !userCurrent ? null : !user?.friends?.includes(
					userCurrent?.result?._id
			  ) && !user?.subscribers?.includes(userCurrent?.result?._id) ? (
				<HandleFriend
					userCurrent={userCurrent}
					user={user}
					type="add"
					isLoadingUser={isLoadingUser}
				/>
			) : null}
			<div className={classes.spaceDivider}></div>
			{checkUser || !userCurrent ? null : user?.subscribers?.includes(
					userCurrent?.result?._id
			  ) ? (
				<HandleFriend
					userCurrent={userCurrent}
					user={user}
					type="unsubscribe"
					isLoadingUser={isLoadingUser}
				/>
			) : null}
			{checkUser || !userCurrent ? null : user?.friends?.includes(
					userCurrent?.result?._id
			  ) ? (
				<HandleFriend
					userCurrent={userCurrent}
					user={user}
					type="removeFriend"
					isLoadingUser={isLoadingUser}
				/>
			) : null}
		</>
	);
};

export default ProfileFriendsSubscribers;
