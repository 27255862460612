import React from "react";
import {
	Flight,
	Train,
	DirectionsBus,
	DirectionsCar,
	DirectionsBoat,
	HelpOutline,
} from "@material-ui/icons";

const TransportIcon = ({ type }) => {
	switch (type) {
		case "plane":
			return <Flight />;
		case "train":
			return <Train />;
		case "bus":
			return <DirectionsBus />;
		case "car":
			return <DirectionsCar />;
		case "ship":
			return <DirectionsBoat />;
		default:
			return <HelpOutline />;
	}
};

export default TransportIcon;
