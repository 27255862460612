// src/components/Profile/ProfileStatisticsSkeleton.jsx

import React from "react";
import { Card, Typography, Box } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useStyles from "../styles";

const ProfileStatisticsSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.cardStatistics}>
			<Typography variant="h5">
				<Skeleton width="40%" />
			</Typography>
			<div className={classes.statisticsFlex}>
				<Typography className={classes.visitedCountriesText}>
					<Skeleton width="60%" />
				</Typography>
				<Skeleton
					variant="rect"
					height={10}
					width="80%"
					style={{ marginTop: 16 }}
				/>
			</div>
			<Typography variant="subtitle1" className={classes.emojiList}>
				<Skeleton width="100%" height={20} />
			</Typography>
			<div className={classes.statisticsFlex}>
				<Typography className={classes.visitedCountriesText}>
					<Skeleton width="60%" />
				</Typography>
				<Skeleton
					variant="rect"
					height={10}
					width="80%"
					style={{ marginTop: 16 }}
				/>
			</div>
		</Card>
	);
};

export default ProfileStatisticsSkeleton;
