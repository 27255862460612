import {
	FETCH_EXCHANGE_RATES,
	START_LOADING_RATES,
	END_LOADING_RATES,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";

export const getExchangeRates = () => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_RATES });
		const { data } = await api.getExchangeRates(); // API request to backend
		dispatch({ type: FETCH_EXCHANGE_RATES, payload: data });
		dispatch({ type: END_LOADING_RATES });
	} catch (error) {
		console.error("Error fetching exchange rates:", error);
		dispatch({ type: END_LOADING_RATES });
	}
};
