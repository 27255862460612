import React from "react";
import { Button } from "@material-ui/core";
import { Link } from "react-router-dom";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";

const PostButtons = ({
	previousPost,
	nextPost,
	positionPost,
	numberOfPosts,
	reportLink,
	classes,
}) => {
	return (
		<div className={classes.postContentButtons}>
			<Button
				onClick={previousPost}
				variant="contained"
				className={classes.buttonPrimary}
				disabled={positionPost === 0}
			>
				Предыдущий пост
			</Button>
			<Button
				component={Link}
				to={reportLink}
				variant="contained"
				className={classes.buttonPrimary}
			>
				<KeyboardReturnIcon className={classes.writePostImg} />
				Вернуться в оглавление
			</Button>
			<Button
				onClick={nextPost}
				variant="contained"
				className={classes.buttonPrimary}
				disabled={numberOfPosts === positionPost + 1}
			>
				Следующий пост
			</Button>
		</div>
	);
};

export default PostButtons;
