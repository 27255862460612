import React, { useState } from "react";
import {
	Typography,
	TextField,
	Card,
	Avatar,
	Switch,
	FormControlLabel,
} from "@material-ui/core";
import SearchGeo from "../../Maps/SelectGeo/SearchGeo/SearchGeo";
import useStyles from "./styles";

const PlaceSelector = ({
	title,
	hinter,
	place,
	setPlace,
	validateInput,
	inputName,
	limits,
	errors,
	setErrors,
	handlePlace,
}) => {
	const classes = useStyles();
	const [useGeoSearch, setUseGeoSearch] = useState(true);

	const handleToggle = () => {
		setUseGeoSearch((prev) => !prev);
	};

	return (
		<div>
			<Typography variant="h6">{title}</Typography>
			{hinter && (
				<div>
					<Typography variant="caption" color="textSecondary">
						{hinter}
					</Typography>
				</div>
			)}

			<FormControlLabel
				control={
					<Switch
						checked={useGeoSearch}
						onChange={handleToggle}
						color="secondary"
					/>
				}
				label={useGeoSearch ? "Поиск города" : "Ввести название самостоятельно"}
			/>

			{useGeoSearch ? (
				<>
					<Typography>Воспользуйтесь поиском города</Typography>
					<SearchGeo onSelectedPlace={handlePlace} filter="city" />
					{place?.name && (
						<Card variant="outlined" className={classes.placeCardCity}>
							<Avatar
								style={{ backgroundColor: "#3f51b5", marginRight: "10px" }}
							>
								{place.name.charAt(0).toUpperCase()}
							</Avatar>
							<Typography variant="h6">{place.name}</Typography>
						</Card>
					)}
				</>
			) : (
				<>
					<Typography>Введите название самостоятельно</Typography>
					<TextField
						size="small"
						label={inputName}
						variant="outlined"
						fullWidth
						value={place.name}
						onChange={(e) => {
							setPlace({ ...place, name: e.target.value });
							validateInput(inputName, e.target.value, limits, setErrors);
						}}
						error={!!errors?.[inputName]}
						helperText={errors?.[inputName]}
					/>
				</>
			)}
		</div>
	);
};

export default PlaceSelector;
