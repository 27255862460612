import {
	FETCH_USER_TRIPS,
	START_LOADING_TRIPS,
	END_LOADING_TRIPS,
	CREATE_TRIP_REQUEST,
	CREATE_TRIP_SUCCESS,
	CREATE_TRIP_FAIL,
	FETCH_TRIP,
	UPDATE_TRIP,
} from "../constants/actionTypes.js";
import * as api from "../api/index.js";

export const getUserTrips = (userId) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_TRIPS });
		const { data } = await api.getUserTrips(userId);
		dispatch({ type: FETCH_USER_TRIPS, payload: data });
		dispatch({ type: END_LOADING_TRIPS });
	} catch (error) {
		console.error("Error fetching user trips:", error);
	}
};

export const createTrip = (tripData) => async (dispatch) => {
	try {
		dispatch({ type: CREATE_TRIP_REQUEST });
		const { data } = await api.createTrip(tripData);

		dispatch({ type: CREATE_TRIP_SUCCESS, payload: data.trip });
	} catch (error) {
		dispatch({
			type: CREATE_TRIP_FAIL,
			payload: error.response?.data?.message || "Failed to create trip",
		});
	}
};

export const getTripById = (tripId) => async (dispatch) => {
	try {
		const { data } = await api.getTripById(tripId);
		dispatch({
			type: FETCH_TRIP,
			payload: { trip: data.trip, collaborators: data.collaborators },
		});
	} catch (error) {
		console.error("Error fetching trip:", error);
	}
};

export const updateTrip = (tripId, tripData) => async (dispatch) => {
	try {
		const { data } = await api.updateTrip(tripId, tripData);
		dispatch({ type: UPDATE_TRIP, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const addCollaborator = (tripId, login) => async (dispatch) => {
	try {
		const { data } = await api.addCollaborator(tripId, login);
		dispatch({ type: UPDATE_TRIP, payload: data });
	} catch (error) {
		console.error("Error adding collaborator", error);
	}
};

export const removeCollaborator = (tripId, userId) => async (dispatch) => {
	try {
		const { data } = await api.removeCollaborator(tripId, userId);
		dispatch({ type: UPDATE_TRIP, payload: data });
	} catch (error) {
		console.error("Error removing collaborator", error);
	}
};
