import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Typography,
	Select,
	MenuItem,
	TextField,
	Button,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment";
import useStyles from "./styles";
import { validateInput } from "../../../utils/validationUtils";

const ArrivalDialog = ({
	openCityModal,
	arrival,
	setArrival,
	handleCloseModal,
	handleSaveArrival,
	currencyOptions,
	transportOptions,
}) => {
	const classes = useStyles();
	const [errors, setErrors] = useState({});
	const limits = {
		flightNumber: 30,
		departureAirport: 200,
		arrivalAirport: 200,
		trainNumber: 30,
		departureStation: 200,
		arrivalStation: 200,
		totalCost: { min: 0, max: 10000000 },
		numberOfPeople: { min: 1, max: 200 },
	};

	useEffect(() => {
		if (arrival?.departureTime) {
			setArrival((prev) => ({
				...prev,
				departureTime: moment(prev.departureTime).format("YYYY-MM-DD HH:mm"),
			}));
		}
		if (arrival?.arrivalTime) {
			setArrival((prev) => ({
				...prev,
				arrivalTime: moment(prev.arrivalTime).format("YYYY-MM-DD HH:mm"),
			}));
		}
	}, [openCityModal, setArrival, arrival?.departureTime, arrival?.arrivalTime]);

	const handleChange = (e) => {
		const { name, value } = e.target;
		validateInput(name, value, limits, setErrors);
		setArrival((prev) => ({ ...prev, [name]: value }));
	};

	const handleCurrencyChange = (event, newValue) => {
		setArrival((prevArrival) => ({ ...prevArrival, currency: newValue }));
	};

	return (
		<Dialog open={openCityModal !== null} onClose={handleCloseModal}>
			<DialogTitle>Добавить вариант прибытия</DialogTitle>
			<DialogContent>
				<Typography>Как вы будете добираться?</Typography>
				<Select
					fullWidth
					name="type"
					value={arrival.type}
					onChange={handleChange}
				>
					{transportOptions.map((option) => (
						<MenuItem key={option.value} value={option.value}>
							{option.label}
						</MenuItem>
					))}
				</Select>

				<TextField
					fullWidth
					name="totalCost"
					label="Стоимость одного билета"
					type="number"
					value={arrival.totalCost}
					onChange={handleChange}
					error={!!errors.totalCost}
					helperText={errors.totalCost}
				/>

				<Typography>Выберите валюту</Typography>
				<Autocomplete
					freeSolo
					options={currencyOptions}
					value={arrival.currency}
					onChange={handleCurrencyChange}
					renderInput={(params) => (
						<TextField className={classes.currencyInputText} {...params} />
					)}
				/>

				<TextField
					fullWidth
					name="numberOfPeople"
					label="Сколько человек едет?"
					type="number"
					value={arrival.numberOfPeople}
					onChange={handleChange}
					error={!!errors.numberOfPeople}
					helperText={errors.numberOfPeople}
				/>

				<TextField
					fullWidth
					name="departureTime"
					InputLabelProps={{ shrink: true }}
					type="datetime-local"
					label={arrival.type === "plane" ? "Время вылета" : "Время отъезда"}
					value={arrival.departureTime}
					onChange={handleChange}
				/>

				<TextField
					fullWidth
					name="arrivalTime"
					InputLabelProps={{ shrink: true }}
					type="datetime-local"
					label={arrival.type === "plane" ? "Время прилета" : "Время приезда"}
					value={arrival.arrivalTime}
					onChange={handleChange}
				/>

				{arrival.type === "plane" && (
					<>
						<TextField
							fullWidth
							name="flightNumber"
							label="Номер рейса"
							value={arrival.flightNumber || ""}
							onChange={handleChange}
							error={!!errors.flightNumber}
							helperText={errors.flightNumber}
						/>
						<TextField
							fullWidth
							name="departureAirport"
							label="Аэропорт вылета"
							value={arrival.departureAirport || ""}
							onChange={handleChange}
							error={!!errors.departureAirport}
							helperText={errors.departureAirport}
						/>
						<TextField
							fullWidth
							name="arrivalAirport"
							label="Аэропорт прилета"
							value={arrival.arrivalAirport || ""}
							onChange={handleChange}
							error={!!errors.arrivalAirport}
							helperText={errors.arrivalAirport}
						/>
					</>
				)}

				{arrival.type === "train" && (
					<>
						<TextField
							fullWidth
							name="trainNumber"
							label="Номер поезда"
							value={arrival.trainNumber || ""}
							onChange={handleChange}
							error={!!errors.trainNumber}
							helperText={errors.trainNumber}
						/>
						<TextField
							fullWidth
							name="departureStation"
							label="Станция отправления"
							value={arrival.departureStation || ""}
							onChange={handleChange}
							error={!!errors.departureStation}
							helperText={errors.departureStation}
						/>
						<TextField
							fullWidth
							name="arrivalStation"
							label="Станция прибытия"
							value={arrival.arrivalStation || ""}
							onChange={handleChange}
							error={!!errors.arrivalStation}
							helperText={errors.arrivalStation}
						/>
					</>
				)}
			</DialogContent>

			<DialogActions>
				<Button onClick={handleCloseModal} color="secondary">
					Отмена
				</Button>
				<Button
					onClick={handleSaveArrival}
					disabled={Object.values(errors).some((err) => err)}
					color="primary"
				>
					Сохранить
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ArrivalDialog;
