import {
	FETCH_USER_TRIPS,
	START_LOADING_TRIPS,
	END_LOADING_TRIPS,
	CREATE_TRIP_REQUEST,
	CREATE_TRIP_SUCCESS,
	CREATE_TRIP_FAIL,
	FETCH_TRIP,
	UPDATE_TRIP,
} from "../constants/actionTypes";

const initialState = {
	trips: [],
	trip: null,
	collaborators: [],
	isLoading: false,
	error: null,
};

export default function tripsReducer(state = initialState, action) {
	switch (action.type) {
		case START_LOADING_TRIPS:
			return { ...state, isLoading: true };
		case END_LOADING_TRIPS:
			return { ...state, isLoading: false };
		case FETCH_USER_TRIPS:
			return { ...state, trips: action.payload };
		case CREATE_TRIP_REQUEST:
			return { ...state, isLoading: true };
		case CREATE_TRIP_SUCCESS:
			return {
				...state,
				isLoading: false,
				trips: [...state.trips, action.payload],
				trip: action.payload,
			};
		case CREATE_TRIP_FAIL:
			return { ...state, isLoading: false, error: action.payload };
		case FETCH_TRIP:
			return {
				...state,
				trip: action.payload.trip, // Store trip separately
				collaborators: action.payload.collaborators, // Store collaborators separately
			};
		case UPDATE_TRIP:
			return {
				...state,
				trips: state.trips.map((trip) =>
					trip._id === action.payload._id ? action.payload : trip
				),
				trip: action.payload,
			};
		default:
			return state;
	}
}
