import React, { useState, useEffect } from "react";
import {
	AppBar,
	Typography,
	Toolbar,
	Avatar,
	Button,
	Container,
	IconButton,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import Hidden from "@material-ui/core/Hidden";
import decode from "jwt-decode";
import NotificationsIcon from "@material-ui/icons/Notifications";
import { getUnviewed } from "../../actions/notifications";

import useStyles from "./styles.js";

import totravel from "../../images/totravel.png";
import CustomMenu from "./CustomMenu/CustomMenu.jsx";
import { logout } from "../../actions/auth.js";

const Navbar = ({ user, setUser }) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

	const dispatch = useDispatch();

	const [anchorEl, setAnchorEl] = useState(null);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		dispatch(getUnviewed(user?.result?._id, "all"));
	}, []);

	const unviewed = useSelector((state) => state.notifications.unviewed);

	const deleteCookie = (name) => {
		document.cookie =
			name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
	};

	const logoutHandler = () => {
		dispatch(logout()); // Dispatch the logout action
		deleteCookie("token");
		setUser(null); // Reset the user state in the frontend
		history.push("/"); // Redirect to the home page
	};

	return (
		<AppBar className={classes.appBar} position="static" color="inherit">
			<Link to="/" className={classes.brandContainer}>
				<Hidden xsDown>
					<Typography className={classes.heading} variant="h4" align="center">
						to
					</Typography>
				</Hidden>
				<Hidden xsDown>
					<Typography
						className={classes.heading_black}
						variant="h4"
						align="center"
					>
						travel
					</Typography>
				</Hidden>
				<img
					className={classes.image}
					src={totravel}
					alt="totravel"
					height="40px"
				/>
			</Link>
			<Toolbar className={classes.toolbar}>
				{user ? (
					<div className={classes.profile}>
						<Link className={classes.notificationLink} to={`/notifications`}>
							<div className={classes.notificationBox}>
								<Typography className={classes.notificationNumber}>
									{unviewed ? (unviewed > 10 ? "10+" : unviewed) : undefined}
								</Typography>
							</div>
							<NotificationsIcon fontSize="large" />
						</Link>
						<Link
							className={classes.profileLink}
							to={`/user/${user?.result?.login}`}
						>
							<Avatar
								className={
									user?.result?.userType === undefined
										? classes.purple
										: classes.purpleSpecial
								}
								alt={user?.result?.name}
								src={user?.result?.picture}
							>
								{user?.result?.name.charAt(0)}
							</Avatar>
						</Link>
						<Hidden smDown>
							<Link
								className={
									user?.result?.userType === undefined
										? classes.nameBox
										: classes.nameBoxSpecial
								}
								to={`/user/${user?.result?.login}`}
							>
								<Container className={classes.nameBox}>
									<Typography className={classes.userName} variant="subtitle1">
										{user?.result?.name}
									</Typography>
									<Typography className={classes.userLogin} variant="subtitle2">
										@{user?.result?.login}
									</Typography>
								</Container>
							</Link>
						</Hidden>
						<Button
							variant="contained"
							className={classes.buttonSecondary}
							onClick={logoutHandler}
						>
							Выйти
						</Button>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={handleClick}
							className={classes.menuIconButton}
						>
							<MenuIcon />
						</IconButton>
						<CustomMenu
							anchorEl={anchorEl}
							handleClose={handleClose}
							user={user}
						/>{" "}
						{/* Include the Menu component */}
					</div>
				) : (
					<div className={classes.profile}>
						<Button
							component={Link}
							to="/auth"
							variant="contained"
							className={classes.buttonPrimary}
						>
							Войти
						</Button>
						<IconButton
							edge="start"
							color="inherit"
							aria-label="menu"
							onClick={handleClick}
							className={classes.menuIconButton}
						>
							<MenuIcon />
						</IconButton>
						<CustomMenu
							anchorEl={anchorEl}
							handleClose={handleClose}
							user={user}
						/>{" "}
						{/* Include the Menu component */}
					</div>
				)}
			</Toolbar>
		</AppBar>
	);
};

export default Navbar;
