import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
	Card,
	CardContent,
	Typography,
	Button,
	CardMedia,
	CircularProgress,
	FormControlLabel,
	Switch,
	Divider,
} from "@material-ui/core";
import moment from "moment";
import { useHistory } from "react-router-dom";
import {
	fetchMorePostsFeed,
	fetchPostsFeed,
	fetchPostsFeedFriend,
} from "../../actions/feed";
import VisibilityIcon from "@material-ui/icons/Visibility";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import SortIcon from "@material-ui/icons/Sort";
import PostReportCard from "../PostReportCard/PostReportCard";
import FeedSort from "./FeedSort/FeedSort";
import PostReportCardLoading from "../PostReportCard/PostReportCardLoading/PostReportCardLoading";

const useStyles = makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 600,
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		"@media (max-width: 1100px)": {
			width: 600,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		"@media (max-width: 650px)": {
			width: "100%",
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	},
	rootLoading: {
		marginBottom: theme.spacing(2),
		marginTop: theme.spacing(2),
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 600,
		height: "325px",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		"@media (max-width: 600px)": {
			height: "269px",
		},
		"@media (max-width: 1100px)": {
			width: 600,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		"@media (max-width: 650px)": {
			width: "100%",
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	},
	filterCard: {
		position: "absolute",
		border: "1px solid",
		borderColor: "rgba(236,98,98,1)",
		borderRadius: "15px",
		float: "left", // Float the filter card to the left
		"@media (max-width: 1100px)": {
			position: "inherit",
			display: "flex",
			width: "100%",
		},
		"@media (max-width: 400px)": {
			position: "inherit",
			display: "flex",
			flexDirection: "column",
			width: "100%",
		},
	},
	cardContent: {
		padding: "12px",
		"@media (max-width: 600px)": {
			padding: "6px",
		},
		"&:last-child": {
			paddingBottom: "0",
		},
	},
	loadMoreButton: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px auto",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	loadMoreButtonLoading: {
		borderRadius: "20px",
		width: "155px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		margin: "7px auto",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	postContent: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		width: "100%",
	},
	postContentBox: {
		width: "100%",
	},
	previewImage: {
		height: 200,
		objectFit: "cover",
		"@media (max-width: 600px)": {
			height: 150,
		},
	},
	visibility: {
		display: "flex",
		alignItems: "center",
		color: "rgba(236,98,98,1)",
		marginLeft: "5px",
	},
	visibilityCount: {
		marginRight: "2px",
	},
	likesBox: {
		display: "flex",
		color: "rgba(236,98,98,1)",
	},
	infoBox: {
		display: "flex",
		justifyContent: "space-between",
	},
	likesVisitedBox: {
		display: "flex",
	},
	timeText: {
		color: "rgba(236,98,98,1)",
	},
	loadMoreBox: {
		display: "flex",
		justifyContent: "center",
		clear: "both", // Clear the float to prevent content from floating around the filter card
	},
	feedBox: {
		position: "relative",
		"@media (max-width: 1100px)": {
			display: "flex",
			flexDirection: "column",
		},
	},
	sortingBox: {
		display: "flex",
		flexDirection: "column",
	},
	sortingItemBox: {
		display: "flex",
		alignItems: "center",
	},
	sortIconTrue: {
		color: "rgb(236,98,98)",
		marginRight: "5px",
	},
	sortIconFalse: {
		color: "rgba(0, 0, 0, 0.54)",
		marginRight: "5px",
	},
	sortButton: {
		justifyContent: "flex-start",
		textTransform: "none",
		padding: "6px 0",
		"@media (max-width: 1100px)": {
			padding: "4px 0",
		},
	},
}));

const Feed = ({ user }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const posts = useSelector((state) => state.feed.postsFeed);
	const isLoading = useSelector((state) => state.feed?.isLoading);
	const isLoadingMore = useSelector((state) => state.feed?.isLoadingMore);

	const [filterFriend, setFilterFriend] = useState(false);
	const [isSortedTime, setIsSortedTime] = useState(true);
	const [isSortedVisited, setIsSortedVisited] = useState(false);
	const [isSortedLikes, setIsSortedLikes] = useState(false);

	useEffect(() => {
		dispatch(fetchPostsFeed());
	}, [dispatch]);

	const handleLoadMore = () => {
		isSortedTime
			? dispatch(fetchMorePostsFeed(posts.length))
			: isSortedVisited
			? dispatch(fetchMorePostsFeed(posts.length, "visited"))
			: isSortedLikes
			? dispatch(fetchMorePostsFeed(posts.length, "likes"))
			: console.log("Что-то пошло не так");
	};

	const handlePostClick = (postId) => {
		history.push(`/post/${postId}`);
	};

	const handleFilterFriend = async (event) => {
		setFilterFriend(event.target.checked);
	};

	const handleSortTime = async (event) => {
		setIsSortedTime(true);
		setIsSortedVisited(false);
		setIsSortedLikes(false);
	};

	const handleSortLikes = async (event) => {
		setIsSortedTime(false);
		setIsSortedVisited(false);
		setIsSortedLikes(true);
	};

	const handleSortVisited = async (event) => {
		setIsSortedTime(false);
		setIsSortedVisited(true);
		setIsSortedLikes(false);
	};

	useEffect(() => {
		if (filterFriend === false) {
			dispatch(fetchPostsFeed());
		} else {
			dispatch(fetchPostsFeedFriend(user?.result?._id));
		}
	}, [filterFriend]);

	useEffect(() => {
		isSortedTime
			? dispatch(fetchPostsFeed())
			: isSortedVisited
			? dispatch(fetchPostsFeed("visited"))
			: isSortedLikes
			? dispatch(fetchPostsFeed("likes"))
			: console.log("Что-то пошло не так");
	}, [isSortedLikes, isSortedTime, isSortedVisited]);

	return (
		<>
			<Helmet>
				<title>Лента постов - ToTravelRu</title>
				<meta
					name="description"
					content="Лента постов о путешествиях по всему миру: Россия, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия - ToTravelRu"
				/>
				<meta
					name="keywords"
					content="ToTravelRu, путешествия, отчеты, посты, отчеты о путешествиях, лента постов, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия"
				/>
			</Helmet>
			<Typography variant="h5">Лента постов</Typography>
			<div className={classes.feedBox}>
				<Card className={`${classes.filterCard}`}>
					{/* Filter and sort options can be added here */}
					{user ? (
						<CardContent className={classes.cardContent}>
							<Typography variant="h6">Фильтры:</Typography>
							<FormControlLabel
								control={
									<Switch
										checked={filterFriend}
										onChange={handleFilterFriend}
										name="checkedA"
									/>
								}
								label="Посты друзей"
							/>
						</CardContent>
					) : null}
					<Divider />
					{filterFriend ? null : (
						<FeedSort
							isSortedTime={isSortedTime}
							isSortedVisited={isSortedVisited}
							isSortedLikes={isSortedLikes}
							handleSortTime={handleSortTime}
							handleSortVisited={handleSortVisited}
							handleSortLikes={handleSortLikes}
							classes={classes}
						/>
					)}
				</Card>
				{isLoading ? (
					<>
						<PostReportCardLoading />
						<PostReportCardLoading />
					</>
				) : (
					posts?.map((post, i) => (
						<PostReportCard
							post={post}
							handlePostClick={handlePostClick}
							user={user}
						/>
					))
				)}
			</div>
			<div className={classes.loadMoreBox}>
				{isLoadingMore ? (
					<Button
						variant="contained"
						color="primary"
						className={classes.loadMoreButtonLoading}
					>
						<CircularProgress color="white" size="1.5rem" />
					</Button>
				) : (
					<Button
						variant="contained"
						color="primary"
						className={classes.loadMoreButton}
						onClick={handleLoadMore}
					>
						Загрузить ещё
					</Button>
				)}
			</div>
		</>
	);
};

export default Feed;
