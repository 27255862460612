import {
	GET_NOTIFICATIONS,
	START_LOADING_NOTIFICATIONS,
	END_LOADING_NOTIFICATIONS,
	GET_UNVIEWED_LIKES,
	GET_UNVIEWED_COMMENTSPOST,
	GET_UNVIEWED_COMMENTSREPORT,
	GET_UNVIEWED_TRIPS,
	GET_UNVIEWED,
	START_LOADING_UNVIEWED,
	END_LOADING_UNVIEWED,
	GET_MESSAGE_NOTIFICATIONS,
	GET_UNVIEWED_RESPONSECOMMENT,
	GET_UNVIEWED_SUBSCRIBE,
	ACCEPT_FRIEND_FROM_NOTIFICATION,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const getNotifications = (id, page, type) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_NOTIFICATIONS });
		const { data } = await api.getNotifications(id, page, type);
		dispatch({ type: GET_NOTIFICATIONS, payload: data });
		dispatch({ type: END_LOADING_NOTIFICATIONS });
	} catch (error) {
		console.log(error.message);
	}
};

export const getUnviewed = (id, type) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.getUnviewed(id, type);
		type === "all"
			? dispatch({ type: GET_UNVIEWED, payload: data })
			: type === "like"
			? dispatch({ type: GET_UNVIEWED_LIKES, payload: data })
			: type === "commentPost"
			? dispatch({ type: GET_UNVIEWED_COMMENTSPOST, payload: data })
			: type === "commentReport"
			? dispatch({ type: GET_UNVIEWED_COMMENTSREPORT, payload: data })
			: type === "responseComment"
			? dispatch({ type: GET_UNVIEWED_RESPONSECOMMENT, payload: data })
			: type === "subscribe"
			? dispatch({ type: GET_UNVIEWED_SUBSCRIBE, payload: data })
			: type === "trip"
			? dispatch({ type: GET_UNVIEWED_TRIPS, payload: data })
			: dispatch({ type: GET_UNVIEWED_COMMENTSREPORT, payload: data });
		dispatch({ type: END_LOADING_UNVIEWED });
	} catch (error) {
		console.log(error.message);
	}
};

export const viewAllNotifications = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.viewAllNotifications(id);
		dispatch({ type: GET_MESSAGE_NOTIFICATIONS, payload: data });
		dispatch({ type: END_LOADING_UNVIEWED });
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteAllNotifications = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.deleteAllNotifications(id);
		dispatch({ type: END_LOADING_UNVIEWED });
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteNotification = (id) => async (dispatch) => {
	try {
		dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.deleteNotification(id);
		dispatch({ type: END_LOADING_UNVIEWED });
	} catch (error) {
		console.log(error.message);
	}
};

export const acceptFriendFromNotification = (id) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.acceptFriendFromNotification(id);
		dispatch({ type: ACCEPT_FRIEND_FROM_NOTIFICATION, payload: data });
		//dispatch({ type: END_LOADING_UNVIEWED });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const acceptCollaboration = (tripId, notificationId) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.acceptCollaboration(tripId, notificationId);
		//dispatch({ type: END_LOADING_UNVIEWED });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};

export const leaveAsSubscriber = (id) => async (dispatch) => {
	try {
		//dispatch({ type: START_LOADING_UNVIEWED });
		const { data } = await api.leaveAsSubscriber(id);
		//dispatch({ type: ACCEPT_FRIEND_FROM_NOTIFICATION, payload: data });
		//dispatch({ type: END_LOADING_UNVIEWED });
		return data;
	} catch (error) {
		console.log(error.message);
	}
};
