// File: PostProfileSkeleton.js
import React from "react";
import { Card, Avatar, Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab"; // Skeleton component from Material-UI
import useStyles from "./styles";

const PostProfileSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.profileCard}>
			<Grid
				container
				spacing={2}
				alignItems="center"
				className={classes.profileLink}
			>
				<Grid item>
					<Skeleton variant="circle">
						<Avatar className={classes.profilePicture} />
					</Skeleton>
				</Grid>
				<Grid item className={classes.gridItem}>
					<Skeleton width="60%" style={{ margin: "0 auto" }}>
						<Typography variant="h6" className={classes.profileNameText}>
							Loading...
						</Typography>
					</Skeleton>
					<Skeleton width="40%" style={{ margin: "0 auto" }}>
						<Typography className={classes.profileNameLogin}>
							@Loading...
						</Typography>
					</Skeleton>
				</Grid>
				<Grid item>
					<Skeleton width={80}>
						<Typography className={classes.adminSponsorLabel}>
							Loading...
						</Typography>
					</Skeleton>
				</Grid>
			</Grid>
		</Card>
	);
};

export default PostProfileSkeleton;
