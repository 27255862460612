import React, { useState } from "react";
import {
	Button,
	Card,
	TextField,
	Typography,
	CircularProgress,
} from "@material-ui/core";
import useStyles from "./styles";
import Resizer from "react-image-file-resizer";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { updateProfile } from "../../../actions/users.js";
import { addYandexAvatar } from "../../../actions/users.js";
import PublishIcon from "@material-ui/icons/Publish";
import CancelIcon from "@material-ui/icons/Cancel";

const editInitialState = JSON.parse(localStorage.getItem("profile"));

const EditProfile = ({ isChangeProfile, setIsChangeProfile }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [editFormData, setEditFormData] = useState(editInitialState);
	const [fileName, setFileName] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	const handleEditChange = (e) => {
		setEditFormData({
			...editFormData,
			result: { ...editFormData.result, [e.target.name]: e.target.value },
		});
	};
	const handleSubmitForb = async (e) => {
		e.preventDefault();
		if (editFormData === editInitialState) {
			setIsChangeProfile((prevIsChangeProfile) => !prevIsChangeProfile);
		} else {
			const checkingUser = await dispatch(updateProfile(editFormData));
			if (checkingUser?.error) {
				setErrorMessage((prev) => checkingUser?.error);
			}
			if (!checkingUser?.error) {
				setIsChangeProfile((prevIsChangeProfile) => !prevIsChangeProfile);
			}
		}
	};
	const isLoadingAvatar = useSelector((state) => state.users.isLoadingAvatar);

	return (
		<Card className={classes.card}>
			<div className={`${classes.flex} ${classes.width100}`}>
				<Typography className={classes.editTitle} variant="h5">
					Изменение профиля
				</Typography>
				<Button
					className={`${classes.buttonDelete}`}
					onClick={() =>
						setIsChangeProfile((prevIsChangeProfile) => !prevIsChangeProfile)
					}
				>
					<CancelIcon />
				</Button>
			</div>
			<form className={classes.form} onSubmit={handleSubmitForb}>
				<Typography className={classes.editTitle} variant="h6">
					Изменить аватар
				</Typography>
				<div className={classes.fileInput}>
					<label className={classes.labelInput}>
						<input
							type="file"
							className={classes.imageInput}
							onChange={async (event) => {
								try {
									const file = event.target.files[0];
									if (file.size > 10000000) {
										alert("Выберите файл поменьше.");
									} else {
										const fileNameInput = event.target.files[0].name;
										const image = await resizeFile(file);
										let yandexImage = await dispatch(addYandexAvatar(image));

										setEditFormData({
											...editFormData,
											result: { ...editFormData.result, picture: yandexImage },
										});
										setFileName(fileNameInput);
									}
								} catch (err) {
									console.log(err);
								}
							}}
						/>
						{isLoadingAvatar ? null : <PublishIcon />}
						{isLoadingAvatar ? (
							<CircularProgress
								style={{ color: "white", margin: "0 auto" }}
								size="1rem"
							/>
						) : (
							<Typography className={classes.uploadText}>
								ЗАГРУЗИТЬ АВАТАР
							</Typography>
						)}
					</label>
					{fileName ? (
						<div className={classes.fileNameBox}>
							<Typography className={classes.fileText}>
								{fileName ? fileName : null}
							</Typography>
						</div>
					) : null}
				</div>
				<Typography className={classes.editTitle} variant="h6">
					Изменить логин
				</Typography>
				<TextField
					name="login"
					variant="outlined"
					label="Логин"
					fullWidth
					value={editFormData.result.login}
					onChange={handleEditChange}
				/>
				<Typography className={classes.editTitle} variant="h6">
					Изменить имя и фамилию
				</Typography>
				<TextField
					name="name"
					variant="outlined"
					label="Заголовок"
					fullWidth
					value={editFormData.result.name}
					onChange={handleEditChange}
				/>
				<Button
					className={classes.buttonPrimary}
					variant="contained"
					size="large"
					type="submit"
					disabled={isLoadingAvatar}
					fullWidth
				>
					Сохранить изменения
				</Button>
			</form>
			{errorMessage ? <Typography>{errorMessage}</Typography> : null}
		</Card>
	);
};

export default EditProfile;
