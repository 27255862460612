import React, { useState, useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import { Grid, Paper, Button } from "@material-ui/core";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import { useDispatch } from "react-redux";
import {
	updateProfile,
	getUserInfoByLogin,
	updateCountries,
	getSubscribersByLogin,
	getFriendsByLogin,
} from "../../actions/users.js";
import EditProfile from "./EditProfile/EditProfile";
import PaginationProfile from "./PaginationProfile/PaginationProfile";
import { getPostsByUser } from "../../actions/posts";
import { useSelector } from "react-redux";
import { batch } from "react-redux";
import countryList from "react-select-country-list";
import ProfileHeader from "./ProfileHeader/ProfileHeader.jsx";
import ProfileFriendsSubscribers from "./ProfileFriendsSubscribers/ProfileFriendsSubscribers.jsx";
import ProfileAbout from "./ProfileAbout/ProfileAbout.jsx";
import ProfileStatistics from "./ProfileStatistics/ProfileStatistics.jsx";
import ProfileReports from "./ProfileReports/ProfileReports.jsx";
import ProfileAboutSkeleton from "./ProfileAbout/ProfileAboutSkeleton.jsx";
import ProfileStatisticsSkeleton from "./ProfileStatistics/ProfileStatisticsSkeleton.jsx";
import ProfileReportsSkeleton from "./ProfileReports/ProfileReportsSkeleton.jsx";
import PaginationProfileSkeleton from "./PaginationProfile/PaginationProfileSkeleton.jsx";
import PlannedTrips from "../Trips/PlannedTrips/PlannedTrips.jsx"; // Import PlannedTrips component
import CreateTrip from "../Trips/CreateTrip/CreateTrip.jsx";

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

const Profile = ({ userCurrent }) => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const query = useQuery();
	const page = query.get("page") || 1;
	const numberCountries = 233;
	const [isChangeProfile, setIsChangeProfile] = useState(false);
	const [isChangeAbout, setIsChangeAbout] = useState(false);
	const [isChangeCountries, setIsChangeCountries] = useState(false);
	const [showPlannedTrips, setShowPlannedTrips] = useState(false);
	const [showCreateTrip, setShowCreateTrip] = useState(false);

	const history = useHistory();

	const navigateToTrips = () => {
		setShowPlannedTrips((prev) => !prev);
		history.push(`/user/${user?.login}/trips`);
	};

	const navigateToProfile = () => {
		setShowPlannedTrips((prev) => !prev);
		history.push(`/user/${user?.login}`);
	};

	const toggleCreateTrip = () => {
		setShowCreateTrip((prev) => !prev);
	};

	const changeProfile = () => {
		setIsChangeProfile((prevIsChangeProfile) => !prevIsChangeProfile);
	};
	const changeAbout = () => {
		setIsChangeAbout((prevIsChangeAbout) => !prevIsChangeAbout);
	};

	const urlLogin = window.location.pathname.split("/")[2];

	useEffect(() => {
		async function fetchData() {
			await batch(() => {
				dispatch(getUserInfoByLogin(urlLogin));
				dispatch(getPostsByUser(urlLogin));
				dispatch(getSubscribersByLogin(urlLogin));
				dispatch(getFriendsByLogin(urlLogin));
			});
		}
		fetchData();

		const pathname = window.location.pathname;
		if (pathname.includes("/trips")) {
			setShowPlannedTrips(true);
		}
	}, [urlLogin]);

	let user = useSelector((state) => {
		return state.users.result[0];
	});

	const [country, setCountry] = useState(user ? user?.countriesList : "");
	useEffect(() => {
		setCountry(user ? user?.countriesList : []);
	}, [user]);
	const [countryEmoji, setCountryEmoji] = useState("");
	useEffect(() => {
		country?.length > 0
			? setCountryEmoji(country.map((c) => c?.label.split(" ")[0]))
			: console.log("");
	}, [country]);

	const [countryReport, setCountryReport] = useState(
		user ? user?.countriesReportList : ""
	);
	const [friends, setFriends] = useState([]);
	const [subscribers, setSubscribers] = useState([]);

	useEffect(() => {
		setCountryReport(user ? user?.countriesReportList : []);
	}, [user]);
	useEffect(() => {
		setFriends(user ? user?.friendsInfo : []);
	}, [user?.friendsInfo]);
	useEffect(() => {
		setSubscribers(user ? user?.subscribersInfo : []);
	}, [user?.subscribersInfo]);
	const [countryReportEmoji, setCountryReportEmoji] = useState("");
	useEffect(() => {
		countryReport?.length > 0
			? setCountryReportEmoji(countryReport.map((c) => c.split(" ")[0]))
			: console.log("");
	}, [countryReport]);

	const options = useMemo(() => countryList().getData(), []);

	const changeHandler = (country) => {
		setCountry(country);
	};

	const submitCountryList = async () => {
		const login = user?.login;
		await dispatch(updateCountries(country, login));
		setIsChangeCountries(!isChangeCountries);
	};

	const checkUser = user?.login
		? userCurrent?.result?.login
			? user?.login === userCurrent?.result?.login
			: false
		: false;

	const isLoadingUser = useSelector((state) => {
		return state.users.isLoading;
	});
	const userPosts = useSelector((state) => state.posts.post?.data);
	const editInitialState = JSON.parse(localStorage.getItem("profile"));
	const [editFormData, setEditFormData] = useState({ result: user });
	useEffect(() => {
		setEditFormData(user ? { result: user } : null);
	}, [user]);

	const handleEditChange = (e) => {
		setEditFormData({
			...editFormData,
			result: { ...editFormData.result, [e.target.name]: e.target.value },
		});
	};

	const handleSubmitForb = (e) => {
		e.preventDefault();
		if (editFormData === editInitialState) {
			console.log("Вы ничего не изменили");
		} else {
			const checking = dispatch(updateProfile(editFormData));
			setIsChangeAbout(!isChangeAbout);
		}
	};

	return (
		<>
			<Helmet>
				<title>{`Пользователь ${user ? user?.login : ""}`} - ToTravelRu</title>
				<meta
					name="description"
					content={`Страница пользователя ${user ? user?.name : ""}, @${
						user ? user?.login : ""
					} - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${user ? user?.name : ""}, ${
						user ? user?.login : ""
					} , отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu`}
				/>
			</Helmet>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={3}
				className={classes.gridContainer}
			>
				<Grid item xs={12} sm={6} md={3}>
					<ProfileHeader
						user={user}
						userCurrent={userCurrent}
						isLoadingUser={isLoadingUser}
						changeProfile={changeProfile}
					/>
					<div className={classes.spaceDivider}></div>
					<ProfileFriendsSubscribers
						user={user}
						friends={friends}
						subscribers={subscribers}
						userCurrent={userCurrent}
						checkUser={checkUser}
						isLoadingUser={isLoadingUser}
					/>
					{!showCreateTrip && (
						<Button
							variant="contained"
							color="primary"
							fullWidth
							onClick={showPlannedTrips ? navigateToProfile : navigateToTrips}
							className={classes.buttonPrimary}
						>
							{showPlannedTrips ? "Назад к профилю" : "Поездки пользователя"}
						</Button>
					)}
					{checkUser && (
						<Button
							variant="contained"
							color="primary"
							fullWidth
							onClick={toggleCreateTrip}
							className={classes.buttonPrimary}
						>
							{showCreateTrip ? "Назад к профилю" : "Добавить поездку"}
						</Button>
					)}
				</Grid>
				<Grid item xs={12} sm={6} md={9}>
					{showCreateTrip ? (
						<CreateTrip user={user} />
					) : showPlannedTrips ? (
						<PlannedTrips user={user} userCurrent={userCurrent} />
					) : (
						<>
							{isChangeProfile ? (
								<EditProfile
									isChangeProfile={isChangeProfile}
									setIsChangeProfile={setIsChangeProfile}
								/>
							) : (
								<Grid
									container
									justifyContent="space-between"
									alignItems="stretch"
									spacing={3}
									className={classes.gridContainer}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
										className={classes.gridContainerBox}
									>
										{isLoadingUser ? (
											<ProfileAboutSkeleton />
										) : (
											<ProfileAbout
												isLoadingUser={isLoadingUser}
												user={user}
												checkUser={checkUser}
												isChangeAbout={isChangeAbout}
												setIsChangeAbout={setIsChangeAbout}
												handleEditChange={handleEditChange}
												editFormData={editFormData}
												handleSubmitForb={handleSubmitForb}
												changeAbout={changeAbout}
											/>
										)}
									</Grid>
									<Grid
										item
										xs={12}
										sm={12}
										md={6}
										className={classes.gridContainerBox}
									>
										{isLoadingUser ? (
											<ProfileStatisticsSkeleton />
										) : (
											<ProfileStatistics
												user={user}
												isLoadingUser={isLoadingUser}
												country={country}
												countryEmoji={countryEmoji}
												countryReport={countryReport}
												countryReportEmoji={countryReportEmoji}
												numberCountries={233}
												isChangeCountries={isChangeCountries}
												setIsChangeCountries={setIsChangeCountries}
												changeHandler={changeHandler}
												submitCountryList={submitCountryList}
												checkUser={checkUser}
												options={options}
											/>
										)}
									</Grid>
								</Grid>
							)}
							{isLoadingUser ? (
								<ProfileReportsSkeleton />
							) : (
								<ProfileReports userPosts={userPosts} />
							)}
							{userPosts === undefined ? (
								<Paper className={classes.pagination}>
									<PaginationProfileSkeleton />
								</Paper>
							) : user?.reportsId.length ? (
								<Paper className={classes.pagination}>
									<PaginationProfile page={page} urlLogin={urlLogin} />
								</Paper>
							) : null}
						</>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default Profile;
