import React from "react";
import {
	Flight,
	Train,
	DirectionsBus,
	DirectionsCar,
	DirectionsBoat,
	HelpOutline,
} from "@material-ui/icons";

const StayTypeTranslate = ({ type }) => {
	switch (type) {
		case "hotel":
			return "Отель";
		case "apartment":
			return "Апартаменты";
		case "hostel":
			return "Хостел";
		case "friends":
			return "У друзей";
		case "other":
			return "Другое";
		default:
			return "Другое";
	}
};

export default StayTypeTranslate;
