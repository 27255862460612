import React, { useState } from "react";
import { Typography, IconButton, Collapse, Button } from "@material-ui/core";
import { ExpandMore, ExpandLess, Hotel } from "@material-ui/icons";
import moment from "moment";
import useStyles from "./styles";
import StayTypeTranslate from "./StayTypeTranslate";
import SettingsIcon from "@material-ui/icons/Settings";

const StayDetails = ({ stay, trip, index, checkUser, handleAddStay, city }) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	if (!stay) return null; // Don't render if there's no stay data

	const totalCost = stay.totalCost
		? `${stay.totalCost.toLocaleString("ru-RU")} ${stay.currency}`
		: "Не указано";

	return (
		<>
			<div className={classes.stayBlock}>
				<div
					className={classes.stayHeader}
					onClick={() => setExpanded(!expanded)}
				>
					<div className={classes.stayFlex}>
						<Typography className={classes.arrivalBlockIcon}>
							<Hotel />
						</Typography>
						<Typography variant="subtitle1">
							<strong>{stay.name || "Не указано"}</strong>
						</Typography>
						<IconButton size="small">
							{expanded ? <ExpandLess /> : <ExpandMore />}
						</IconButton>
						{checkUser && (
							<Button
								onClick={() => handleAddStay(city, index)}
								className={classes.buttonClear}
							>
								<SettingsIcon />
							</Button>
						)}
					</div>
					<div className={classes.stayFlex}>
						<Typography variant="subtitle1">
							💰 Стоимость проживания: {totalCost}
						</Typography>
					</div>
				</div>

				<Collapse in={expanded} timeout="auto" unmountOnExit>
					<div className={classes.stayBlockText}>
						<Typography variant="body2">
							🏨 <strong>Тип проживания:</strong>{" "}
							{<StayTypeTranslate type={stay.type} /> || "Не указано"}
							{stay.address && (
								<>
									<br />
									📍 <strong>Адрес:</strong> {stay.address}
								</>
							)}
							{stay.location && (
								<>
									<br />
									📍{" "}
									<a
										href={stay.location}
										target="_blank"
										rel="noopener noreferrer"
									>
										<strong>Ссылка на карту</strong>
									</a>
								</>
							)}
							{stay.arrivalDate && (
								<>
									<br />
									📅 <strong>Дата заезда:</strong>{" "}
									{moment(stay.arrivalDate).format("DD MMMM YYYY")}
								</>
							)}
							{stay.departureDate && (
								<>
									<br />
									🚪 <strong>Дата выезда:</strong>{" "}
									{moment(stay.departureDate).format("DD MMMM YYYY")}
								</>
							)}
							{stay.numberOfPeople && (
								<>
									<br />
									👥 <strong>Количество человек:</strong> {stay.numberOfPeople}
								</>
							)}
							{stay.website && (
								<>
									<br /> 🔗{" "}
									<a
										href={stay.website}
										target="_blank"
										rel="noopener noreferrer"
									>
										<strong>Сайт</strong>
									</a>
								</>
							)}
						</Typography>
					</div>
				</Collapse>
			</div>
		</>
	);
};

export default StayDetails;
