import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	paper: {
		marginTop: theme.spacing(4),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		padding: theme.spacing(2),
		border: "1px solid rgba(236,98,98,1)",
		borderRadius: "15px",
	},
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
		},
	},
	login: {
		display: "flex",
		alignItems: "center",
		padding: "8px",
		width: "100%",
	},
	loginAt: {
		marginRight: "10px",
	},
	avatar: {
		margin: theme.spacing(1),
		backgroundColor: "rgba(236,98,98,1)",
	},
	checkList: {
		fontSize: "0.9rem",
		fontFamily: ["Roboto", "Helvetica", "Arial", "sans-serif"],
		fontWeight: 400,
		lineHeight: 1.334,
		letterSpacing: "0em",
		color: "rgba(0,0,0,1)",
		alignItems: "center",
		"& li": {
			alignItems: "center",
		},
	},
	form: {
		width: "100%", // Fix IE 11 issue.
		marginTop: theme.spacing(3),
	},
	submit: {
		margin: theme.spacing(2, 0, 0),
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSignUp: {
		margin: theme.spacing(2, 0, 0),
	},
	googleButton: {
		marginBottom: theme.spacing(2),
	},
	privacyText: {
		fontSize: "14px",
	},
	privacyRef: {
		textDecoration: "none",
	},
	buttonPassword: {
		display: "flex",
		margin: "0 0 0 auto",
	},
	errorBox: {
		backgroundColor: "rgba(237,237,237,1)",
		borderRadius: "10px",
	},
	infoBanner: {
		backgroundColor: "#f3f3f3",
		padding: "15px",
		borderRadius: "8px",
		textAlign: "center",
		marginBottom: "20px",
		marginTop: theme.spacing(2),
	},
	bannerTitle: {
		fontWeight: "bold",
		color: "#333",
	},
	bannerText: {
		color: "#555",
	},
	prosTitle: {
		marginTop: "10px",
		fontWeight: "bold",
	},
	prosList: {
		marginTop: "10px",
		padding: "0",
		backgroundColor: "#f9f9f9",
		borderRadius: "8px",
	},
	prosItem: {
		padding: "8px 16px",
		borderBottom: "1px solid #ddd",
		fontSize: "0.9rem",
		color: "#333",
		"&:last-child": {
			borderBottom: "none",
		},
	},
	codeSentMessage: {
		marginTop: "10px",
		color: "#1976d2", // MUI primary color
		fontSize: "0.9rem",
		textAlign: "center",
	},
}));
