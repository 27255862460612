import React from "react";
import { Link } from "react-router-dom";
import { Typography, Button } from "@material-ui/core";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import moment from "moment";

const ResponseBox = ({ user, classes, c, i, a, toggleCommentResponse }) => {
	return (
		<div
			className={`${classes.flex} ${classes.alignCenter} ${classes.marginBottom}`}
		>
			<div className={classes.emptySpaceResponse}></div>

			<div className={`${classes.responseContainer} ${classes.commentTextBox}`}>
				<div className={classes.profileTimeBoxResponse}>
					<Link
						className={
							c.userType === undefined
								? classes.nameBoxResponse
								: classes.nameBoxSpecialResponse
						}
						to={`/user/${c.userLogin}`}
					>
						<Typography
							key={a}
							variant="subtitle1"
							className={classes.userName}
						>
							{c.userName}
						</Typography>
					</Link>
					<Typography className={classes.momentCommentResponse}>
						{moment(c.createdAt).fromNow()}
					</Typography>
				</div>
				<Typography key={a} className={classes.commentTextResponse}>
					{c.text}
				</Typography>
			</div>
			<div className={classes.commentButtons}>
				{user && (
					<Button
						className={`${classes.flex} ${classes.likesBox}`}
						onClick={() => toggleCommentResponse(c, i, a)}
					>
						<ReplyOutlinedIcon />
					</Button>
				)}
			</div>
		</div>
	);
};

export default ResponseBox;
