import React from "react";
import { Button, Typography } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ReplyOutlinedIcon from "@material-ui/icons/ReplyOutlined";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

const CommentButtons = ({
	classes,
	c,
	i,
	user,
	handleLike,
	toggleCommentResponse,
	editMode,
	setEditMode,
}) => {

	return (
		<div className={classes.commentButtons}>
			<Button
				className={`${classes.flex} ${classes.likesBox}`}
				onClick={() => handleLike(c, i)}
			>
				<Typography className={classes.likesNumber}>
					{c.likes.length}
				</Typography>
				{c.likes.find((like) => like === user?.result?._id) ? (
					<FavoriteIcon />
				) : (
					<FavoriteBorderIcon />
				)}
			</Button>
			{user && (
				<Button
					className={`${classes.flex} ${classes.likesBox}`}
					onClick={() => toggleCommentResponse(c, i)}
				>
					<ReplyOutlinedIcon />
				</Button>
			)}
			{c.userId === user?.result?._id && (
				<Button
					className={`${classes.flex} ${classes.likesBox}`}
					onClick={() => setEditMode(!editMode)}
				>
					<MoreHorizIcon />
				</Button>
			)}
		</div>
	);
};

export default CommentButtons;
