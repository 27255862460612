import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import countryList from "react-select-country-list";
import { createTrip } from "../../../actions/trips";
import {
	Stepper,
	Step,
	StepLabel,
	TextField,
	Button,
	Paper,
	Typography,
	List,
	ListItem,
	Card,
	Avatar,
	Snackbar,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import useStyles from "./styles";
import { validateInput } from "../../../utils/validationUtils";
import { useHistory } from "react-router-dom";
import PlaceSelector from "./PlaceSelector";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";

const CreateTrip = () => {
	const classes = useStyles();
	const history = useHistory();

	const [tripName, setTripName] = useState("");
	const [countries, setCountries] = useState([]);
	const [countriesFull, setCountriesFull] = useState([]);
	const [cities, setCities] = useState([]);
	const [newCity, setNewCity] = useState({
		name: "",
		visitDates: { startDate: "", endDate: "" },
	});
	const [startPlace, setStartPlace] = useState({
		name: "",
	});
	const [endPlace, setEndPlace] = useState({
		name: "",
	});
	const [error, setError] = useState("");
	const [errors, setErrors] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [snackbarOpen, setSnackbarOpen] = useState(false);
	const [snackbarMessage, setSnackbarMessage] = useState("");

	const limits = {
		tripName: 500,
		startPlace: 200,
		newCity: 200,
		endPlace: 200,
	};

	const steps = ["Детали поездки", "Точка старта", "Города", "Точка окончания"];

	const options = useMemo(() => countryList().getData(), []);

	const dispatch = useDispatch();
	const tripState = useSelector((state) => state?.trips?.trip);

	const handleNext = () => setActiveStep((prevStep) => prevStep + 1);
	const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

	const handleSelectedCity = async (selectedPlace) => {
		setNewCity((prevCity) => ({
			...prevCity,
			name: selectedPlace.placeName,
			cityId: selectedPlace._id,
		}));
	};

	const handleSelectedStartPlace = async (selectedPlace) => {
		setStartPlace((prevCity) => ({
			...prevCity,
			name: selectedPlace.placeName,
			cityId: selectedPlace._id,
		}));
	};

	const handleSelectedEndPlace = async (selectedPlace) => {
		setEndPlace((prevCity) => ({
			...prevCity,
			name: selectedPlace.placeName,
			cityId: selectedPlace._id,
		}));
	};

	const handleAddCity = () => {
		if (
			!newCity.name ||
			!newCity.visitDates.startDate ||
			!newCity.visitDates.endDate
		) {
			setError("Пожалуйста, заполните все поля города.");
			return;
		}
		setCities([...cities, newCity]);
		setNewCity({ name: "", visitDates: { startDate: "", endDate: "" } });
		setError("");
		setSnackbarMessage("Город добавлен!"); // Set the message
		setSnackbarOpen(true); // Open the snackbar
	};

	const addCountries = (c) => {
		setCountriesFull(c);
		let countryArr = c.map((c) => (c.label ? (c = c.label) : c));
		setCountries(countryArr);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (!tripName || cities.length === 0) {
			setError("Все поля обязательны для заполнения");
			return;
		}
		setLoading(true);
		dispatch(createTrip({ tripName, countries, cities, startPlace, endPlace }))
			.then(() => {
				setIsSuccess(true);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false); // Stop loading in case of error
				setError("Произошла ошибка при добавлении поездки");
			});
	};

	useEffect(() => {
		if (tripState?._id && isSuccess === true) {
			window.location.href = `/trip/${tripState?._id}`;
		}
	}, [tripState, isSuccess]);

	return (
		<Paper className={classes.container}>
			<Typography variant="h5" className={classes.createTripTitle}>
				Добавить новую поездку
			</Typography>
			{isSuccess ? (
				<Typography>Вы успешно добавили поездку</Typography>
			) : (
				<>
					<Stepper
						activeStep={activeStep}
						alternativeLabel
						className={classes.stepperRoot}
					>
						{steps.map((label, index) => (
							<Step key={index}>
								<StepLabel className={classes.stepLabel}>{label}</StepLabel>
							</Step>
						))}
					</Stepper>
					<form className={classes.form} onSubmit={handleSubmit}>
						{activeStep === 0 && (
							<>
								<Typography variant="caption" color="textSecondary">
									Укажите название поездки
								</Typography>
								<TextField
									size="small"
									label="Название поездки*"
									variant="outlined"
									fullWidth
									value={tripName}
									onChange={(e) => {
										setTripName(e.target.value);
										validateInput(
											"tripName",
											e.target.value,
											limits,
											setErrors
										);
									}}
									error={!!errors?.tripName}
									helperText={errors?.tripName}
								/>
								<Typography variant="caption" color="textSecondary">
									Выберите страны, которые вы планируете посетить
								</Typography>
								<Select
									size="small"
									options={options}
									value={countriesFull}
									onChange={addCountries}
									styles={{
										container: (styles) => ({
											...styles,
											minHeight: "40px",
										}),
										control: (styles) => ({
											...styles,
											fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
											minHeight: "40px",
										}),
										option: (styles) => {
											return {
												...styles,
												fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
											};
										},
									}}
									isMulti
									placeholder="Выберите страны поездки"
								/>
							</>
						)}
						{activeStep === 1 && (
							<>
								<PlaceSelector
									title="Добавить точку старта поездки"
									hinter="Где начнется ваше путешествие? Найдите в поиске город. Если поиск не даст результатов, нажмите на кнопку и впишите город самостоятельно"
									place={startPlace}
									setPlace={setStartPlace}
									handlePlace={handleSelectedStartPlace}
									validateInput={validateInput}
									inputName="Точка старта поездки"
									limits={limits}
									errors={errors}
									setErrors={setErrors}
								/>
							</>
						)}
						{activeStep === 2 && (
							<>
								<PlaceSelector
									title="Добавить города"
									hinter="Какие города вы посетите? Найдите в поиске города. Если поиск не даст результатов, нажмите на кнопку и впишите город самостоятельно"
									place={newCity}
									setPlace={setNewCity}
									handlePlace={handleSelectedCity}
									validateInput={validateInput}
									inputName="Название города*"
									limits={limits}
									errors={errors}
									setErrors={setErrors}
								/>
								<Typography variant="caption" color="textSecondary">
									После того, как вы выбрали город, укажите, когда вы приедете и
									уедете из него
								</Typography>
								<TextField
									label="Дата приезда*"
									type="date"
									InputLabelProps={{ shrink: true }}
									fullWidth
									value={newCity.visitDates.startDate}
									onChange={(e) =>
										setNewCity({
											...newCity,
											visitDates: {
												...newCity.visitDates,
												startDate: e.target.value,
											},
										})
									}
								/>
								<TextField
									label="Дата отъезда*"
									type="date"
									InputLabelProps={{ shrink: true }}
									fullWidth
									value={newCity.visitDates.endDate}
									onChange={(e) =>
										setNewCity({
											...newCity,
											visitDates: {
												...newCity.visitDates,
												endDate: e.target.value,
											},
										})
									}
								/>
								<Typography variant="caption" color="textSecondary">
									После того, как вы всё указали, добавьте город
								</Typography>
								<Button
									variant="contained"
									className={classes.buttonSecondary}
									onClick={handleAddCity}
								>
									Добавить город
								</Button>
								{cities.length > 0 && (
									<Typography variant="caption" color="textSecondary">
										Список городов
									</Typography>
								)}

								<List className={classes.cityList}>
									{cities.map((city, index) => (
										<ListItem key={index}>
											<Card variant="outlined" className={classes.placeCard}>
												<div className={classes.flex}>
													<Avatar
														style={{
															backgroundColor: "#3f51b5",
															marginRight: "10px",
														}}
													>
														{city?.name.charAt(0).toUpperCase()}
													</Avatar>
													<div>
														<Typography variant="h6">{city?.name}</Typography>
														<Typography>
															({city?.visitDates?.startDate} -{" "}
															{city?.visitDates?.endDate})
														</Typography>
													</div>
												</div>
												<Button
													onClick={() => {
														const newCities = [...cities];
														newCities.splice(index, 1);
														setCities(newCities);
													}}
													color="secondary"
													size="small"
												>
													<HighlightOffIcon />
												</Button>
											</Card>
										</ListItem>
									))}
								</List>
							</>
						)}
						{activeStep === 3 && (
							<>
								<PlaceSelector
									title="Добавить точку окончания поездки"
									hinter="В какой город вы вернетесь? Найдите в поиске город. Если поиск не даст результатов, нажмите на кнопку и впишите город самостоятельно"
									place={endPlace}
									setPlace={setEndPlace}
									handlePlace={handleSelectedEndPlace}
									validateInput={validateInput}
									inputName="Точка окончания поездки"
									limits={limits}
									errors={errors}
									setErrors={setErrors}
								/>
							</>
						)}

						{error && (
							<Typography className={classes.error}>{error}</Typography>
						)}
						<div className={classes.createTripButtons}>
							<Button
								variant="contained"
								className={classes.buttonPrimary}
								disabled={activeStep === 0}
								onClick={handleBack}
							>
								Назад
							</Button>
							<Button
								variant="contained"
								className={classes.buttonPrimary}
								onClick={
									activeStep === steps.length - 1 ? handleSubmit : handleNext
								}
								disabled={
									Object.values(errors).some((err) => err) ||
									!tripName ||
									(activeStep === 2 && cities.length === 0)
								}
							>
								{activeStep === steps.length - 1 ? "Создать поездку" : "Далее"}
							</Button>
						</div>
					</form>
				</>
			)}
			<Snackbar
				open={snackbarOpen}
				autoHideDuration={3000} // How long the message stays visible (in milliseconds)
				onClose={() => setSnackbarOpen(false)} // Function to close the snackbar
				anchorOrigin={{ vertical: "bottom", horizontal: "left" }} // Where the snackbar appears
			>
				<Alert onClose={() => setSnackbarOpen(false)} severity="success">
					{snackbarMessage}
				</Alert>
			</Snackbar>
		</Paper>
	);
};

export default CreateTrip;
