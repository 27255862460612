import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Typography, TextField, Button } from "@material-ui/core";
import CommentProfile from "../CommentProfile/CommentProfile";
import CommentButtons from "../CommentButtons/CommentButtons";
import { updateCommentPost, updateCommentReport } from "../../../actions/posts";

const CommentBox = ({
	classes,
	c,
	i,
	user,
	handleLike,
	toggleCommentResponse,
	type,
	id,
	setComments,
	initialCommentsOrder,
}) => {
	const dispatch = useDispatch();
	const [editMode, setEditMode] = useState(false);
	const [updatedComment, setUpdatedComment] = useState(c.text);

	useEffect(() => {
		setUpdatedComment(c.text);
	}, [c.text]);

	const handleEdit = async () => {
		const initialNumber = initialCommentsOrder.indexOf(i);
		const updatedData = { text: updatedComment, commentNumber: initialNumber };
		const actionResponse =
			type === "post"
				? await dispatch(updateCommentPost(updatedData, id))
				: await dispatch(updateCommentReport(updatedData, id));
		if (actionResponse) {
			setComments((prevComments) =>
				prevComments.map((comment, index) =>
					index === initialNumber
						? { ...comment, text: updatedComment }
						: comment
				)
			);
		}
		setEditMode(false);
	};

	return (
		<>
			<div
				className={`${classes.flex} ${classes.alignCenter} ${classes.mobileComment} ${classes.marginBottom}`}
			>
				<CommentProfile classes={classes} c={c} i={i} />
				<div className={classes.commentTextBox}>
					<Typography key={i} className={classes.commentText}>
						{c.text}
					</Typography>
				</div>
				<CommentButtons
					classes={classes}
					c={c}
					i={i}
					user={user}
					handleLike={handleLike}
					toggleCommentResponse={toggleCommentResponse}
					editMode={editMode}
					setEditMode={setEditMode}
				/>
			</div>
			{editMode && (
				<div className={classes.editContainer}>
					<Typography>Изменить комментарий</Typography>
					<TextField
						fullWidth
						variant="outlined"
						value={updatedComment}
						onChange={(e) => setUpdatedComment(e.target.value)}
						className={classes.editField}
					/>
					<Button
						color="primary"
						variant="contained"
						onClick={handleEdit}
						className={classes.buttonPrimaryChange}
					>
						Изменить
					</Button>
					<Button
						color="secondary"
						variant="outlined"
						onClick={() => setEditMode(false)}
						className={classes.buttonSecondary}
					>
						Отменить изменения
					</Button>
				</div>
			)}
		</>
	);
};

export default CommentBox;
