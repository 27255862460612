import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	menuButton: {
		position: "absolute",
		top: theme.spacing(1),
		right: theme.spacing(1),
		color: "white",
		backgroundColor: "rgba(0, 0, 0, 0.2)",
		"&:hover": {
			backgroundColor: "rgba(0, 0, 0, 0.7)",
		},
		zIndex: 1,
	},
}));
