import React from "react";
import { Card, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useStyles from "../styles";

const ProfileAboutSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.cardAbout}>
			<Typography variant="h5">
				<Skeleton width="40%" />
			</Typography>
			<div className={classes.circularOther}>
				<Skeleton
					variant="rect"
					height={100}
					width="100%"
					style={{ marginBottom: 16 }}
				/>
				<Skeleton variant="rect" height={36} width="30%" />
			</div>
		</Card>
	);
};

export default ProfileAboutSkeleton;
