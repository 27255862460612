import { makeStyles } from "@material-ui/core/styles";
import { deepPurple } from "@material-ui/core/colors";

export default makeStyles((theme) => ({
	container: {
		padding: theme.spacing(3),
		backgroundColor: "#fff",
		borderRadius: "15px",
		boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
		"@media (max-width: 950px)": {
			padding: theme.spacing(2),
		},
	},
	title: {
		marginBottom: theme.spacing(2),
		fontWeight: "bold",
	},
	timelineTitle: {
		marginTop: theme.spacing(3),
		fontWeight: "bold",
	},
	cityList: {
		marginTop: theme.spacing(1),
	},
	cityItem: {
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
		paddingTop: theme.spacing(1),
		paddingBottom: theme.spacing(1),
		paddingLeft: 0,
		paddingRight: 0,
	},
	cityRow: {
		display: "flex",
		alignItems: "center",
		justifyContent: "space-between",
		padding: theme.spacing(2),
		borderRadius: "10px",
		backgroundColor: "#f9f9f9",
		marginBottom: theme.spacing(1),
	},
	cityInfo: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1),
		width: "100%",
	},
	cityName: {
		fontWeight: "bold",
	},
	textField: {
		marginBottom: theme.spacing(2),
	},
	dateField: {
		width: "100%",
	},
	buttonPrimary: {
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		//padding: "10px 20px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		borderRadius: "20px",
		backgroundColor: theme.palette.grey[300],
		color: theme.palette.text.primary,
		//padding: "10px 20px",
		"&:hover": {
			backgroundColor: theme.palette.grey[400],
		},
	},
	errorText: {
		color: theme.palette.error.main,
		marginTop: theme.spacing(1),
	},
	successText: {
		color: theme.palette.success.main,
		marginTop: theme.spacing(1),
	},
	selectContainer: {
		marginTop: theme.spacing(2),
	},
	deleteButton: {
		color: theme.palette.error.main,
		"&:hover": {
			backgroundColor: "rgba(255,0,0,0.1)",
		},
	},
	buttonsFlex: {
		display: "flex",
		gap: theme.spacing(1),
		marginTop: theme.spacing(1),
		flexWrap: "wrap",
	},
	divider: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	arrivalBlock: {
		display: "flex",
		justifyContent: "space-between",
		flexWrap: "wrap",
		alignItems: "center",
	},
	arrivalBlockText: {
		display: "flex",
		gap: theme.spacing(0.5),
		flexWrap: "wrap",
		alignItems: "center",
	},
	totalCost: {
		textAlign: "right",
		"@media (max-width: 950px)": {
			textAlign: "left",
		},
	},
	arrivalBlockIcon: {
		color: "rgba(236,98,98,1)",
	},
	stayHeader: {
		display: "flex",
		gap: theme.spacing(1),
		flexWrap: "wrap",
		alignItems: "center",
		justifyContent: "space-between",
	},
	stayFlex: {
		display: "flex",
		gap: theme.spacing(1),
	},
	dateButtons: {
		marginTop: theme.spacing(1),
		display: "flex",
		gap: theme.spacing(1),
		flexWrap: "wrap",
	},
	dateButton: {
		textTransform: "none",
		border: "1px solid rgba(236,98,98,1)",
		color: "rgba(236,98,98,1)",
	},
	dateButtonClicked: {
		textTransform: "none",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		//padding: "10px 20px",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	timelineContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		padding: theme.spacing(1),
		backgroundColor: theme.palette.grey[100],
		borderRadius: 8,
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	timelinePeriod: {
		fontWeight: "bold",
		textAlign: "center",
	},
	addActivityButton: {
		width: "100%",
		marginTop: theme.spacing(1),
	},
	cityTimeline: {
		marginTop: theme.spacing(1),
		width: "100%",
	},
	timelinePeriodContainer: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	activitiesContainer: {
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
	},
	profileLink: {
		textDecoration: "none",
	},
	purple: {
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		marginRight: "5px",
	},
	purpleSpecial: {
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
		color: theme.palette.getContrastText(deepPurple[500]),
		backgroundColor: deepPurple[500],
		marginRight: "5px",
	},
	nameBox: {
		height: "40px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 15px",
		margin: "0",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		textDecoration: "none",
		boxSizing: "content-box",
	},
	nameBoxSpecial: {
		height: "40px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		borderRadius: "20px",
		padding: "0px 15px",
		margin: "0x",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "space-around",
		textDecoration: "none",
		border: "1px solid",
		borderColor: "rgb(9,9,121)",
	},
	profile: {
		display: "flex",
		alignItems: "center",
		flexWrap: "wrap",
		gap: theme.spacing(1),
	},
	userName: {
		fontWeight: 700,
		lineHeight: 1,
	},
	userLogin: {
		lineHeight: 1,
	},
	totalCostCurrencyBlock: {
		display: "flex",
		gap: theme.spacing(0.5),
		flexWrap: "wrap",
		alignItems: "center",
		marginBottom: theme.spacing(1),
	},
	buttonClear: {
		color: "rgb(200,200,200)",
		padding: 0,
		minWidth: "45px",
	},
	activityFlex: {
		display: "flex",
		gap: theme.spacing(0.5),
		justifyContent: "space-between",
	},
	currencyInputText: {
		minWidth: "70px",
	},
	itemsListContainer: {
		padding: 0,
	},
	noteText: {
		fontStyle: "italic",
	},
}));
