import React from "react";
import { useSelector } from "react-redux";
import {
	Grid,
	CircularProgress,
	Typography,
	Box,
	Paper,
} from "@material-ui/core";
import SentimentVeryDissatisfiedIcon from "@material-ui/icons/SentimentVeryDissatisfied";

import Post from "./Post/Post.js";
import useStyles from "./styles.js";
import ReportCardSkeleton from "./Post/ReportCardSkeleton.jsx";

const Posts = ({ user, setCurrentId }) => {
	const { posts, isLoading } = useSelector((state) => state.posts);

	const classes = useStyles();

	if (!posts.length && !isLoading)
		return (
			<Box className={classes.box}>
				<SentimentVeryDissatisfiedIcon className={classes.boxImg} />
				<Typography className={classes.boxText}>Нет отчетов</Typography>
				<Typography className={classes.boxText}>
					Вы можете написать отчет об этой стране
				</Typography>
			</Box>
		);

	return isLoading ? (
		<Grid
			className={classes.container}
			container
			alignItems="stretch"
			spacing={3}
		>
			{[...Array(6)].map((_, index) => (
				<Grid item xs={12} sm={12} md={6} lg={4} key={index}>
					<ReportCardSkeleton />
				</Grid>
			))}
		</Grid>
	) : (
		<Grid
			className={classes.container}
			container
			alignItems="stretch"
			spacing={3}
		>
			{posts.map((post) => (
				<Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
					<Post user={user} post={post} setCurrentId={setCurrentId}></Post>
				</Grid>
			))}
		</Grid>
	);
};

export default Posts;
