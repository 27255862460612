import React from "react";
import { Typography, Button, CircularProgress } from "@material-ui/core";
import useStyles from "./styles.js";
import { translatePlaceTypePrepositionalCase } from "../../../utils/placeUtils";
import PlaceInformation from "./PlaceInformation/PlaceInformation.jsx";
import SuggestPlace from "./SuggestPlace/SuggestPlace.jsx";
import SuggestPlaceInfo from "./SuggestPlaceInfo/SuggestPlaceInfo.jsx";


const PlaceInformationBox = ({
	place,
	isLoading,
	dispatchResult,
	isSuggestedText,
	switchSuggestText,
	averageHotelRate,
	averageCheck,
	averageTicketAdult,
	averageTicketChild,
  user,
	userId,
	setIsSuggestedText,
	isInfoFormOpen,
	setIsInfoFormOpen,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.gridItem}>
			<Typography variant="h5">
				Информация о{" "}
				{translatePlaceTypePrepositionalCase(place?.placeType)?.toLowerCase()}{" "}
				{place?.placeName}
			</Typography>
			{isLoading ? (
				<CircularProgress
					style={{ color: "white", margin: "0 auto" }}
					size="1rem"
				/>
			) : place?.description ? (
				<div className={classes.descriptionTextBox}>
					<Typography className={classes.descriptionText}>
						{place?.description}
					</Typography>
				</div>
			) : dispatchResult ? (
				<Typography>
					Спасибо! Администраторы скоро рассмотрят Ваше предложение!
				</Typography>
			) : (
				<Button
					className={
						isSuggestedText
							? classes.buttonSecondarySuggestText
							: classes.buttonPrimarySuggestText
					}
					onClick={switchSuggestText}
				>
					{isSuggestedText ? "Закрыть" : "Предложить описание места"}
				</Button>
			)}
			<PlaceInformation
				place={place}
				averageHotelRate={averageHotelRate}
				averageCheck={averageCheck}
				averageTicketAdult={averageTicketAdult}
				averageTicketChild={averageTicketChild}
			/>
			{isSuggestedText ? (
				<SuggestPlace
					place={place}
					userId={userId}
					classes={classes}
					isSuggestedText={isSuggestedText}
					setIsSuggestedText={setIsSuggestedText}
				/>
			) : null}

			{isInfoFormOpen && (
				<SuggestPlaceInfo
					place={place}
					userId={userId}
					classes={classes}
					setIsInfoFormOpen={setIsInfoFormOpen}
				/>
			)}
			{user && (
				<Button
					className={classes.buttonPrimary}
					onClick={() => setIsInfoFormOpen(!isInfoFormOpen)}
					style={{ margin: "20px 0" }}
				>
					{isInfoFormOpen ? "Закрыть" : "Добавить информацию о месте"}
				</Button>
			)}
		</div>
	);
};

export default PlaceInformationBox;
