import {
	ADD_ALERT,
	DELETE_ALL_ALERTS,
	ADD_LAST_PHOTOS,
	ADD_LAST_COMMENTS,
	ADD_LAST_PLACES,
	ADMIN_GET_CLAIMS,
	ADMIN_GET_LAST_TRIPS,
} from "../constants/actionTypes";
import * as api from "../api/index.js";

export const addAlert = (alertText, type, expireAt, id) => async (dispatch) => {
	try {
		const { data } = await api.addAlert(alertText, type, expireAt, id);
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLatestAlert = () => async (dispatch) => {
	try {
		const { data } = await api.getLatestAlert();
		dispatch({ type: ADD_ALERT, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const deleteAllAlerts = (id) => async (dispatch) => {
	try {
		const { data } = await api.deleteAllAlerts(id);
		dispatch({ type: DELETE_ALL_ALERTS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastPhotos = () => async (dispatch) => {
	try {
		const { data } = await api.getLastPhotos();
		dispatch({ type: ADD_LAST_PHOTOS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastComments = () => async (dispatch) => {
	try {
		const { data } = await api.getLastComments();
		dispatch({ type: ADD_LAST_COMMENTS, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const getLastPlaces = () => async (dispatch) => {
	try {
		const { data } = await api.getLastPlaces();
		dispatch({ type: ADD_LAST_PLACES, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

export const emptyImagesForAllPlaces = () => async () => {
	try {
		const { data } = await api.emptyImagesForAllPlaces();
		//dispatch({ type: ADD_LAST_PLACES, payload: data });
	} catch (error) {
		console.log(error.message);
	}
};

// Block an IP
export const blockIP = (ip) => async (dispatch) => {
	try {
		const { data } = await api.blockIP(ip);
		dispatch({ type: "BLOCK_IP_SUCCESS", payload: data });
	} catch (error) {
		console.error("Failed to block IP:", error);
	}
};

// Get blocked IPs
export const getBlockedIPs = () => async (dispatch) => {
	try {
		const { data } = await api.getBlockedIPs();
		dispatch({ type: "FETCH_BLOCKED_IPS_SUCCESS", payload: data });
	} catch (error) {
		console.error("Failed to fetch blocked IPs:", error);
	}
};

// Unblock an IP
export const unblockIP = (ip) => async (dispatch) => {
	try {
		await api.unblockIP(ip);
		dispatch({ type: "UNBLOCK_IP_SUCCESS", payload: ip });
	} catch (error) {
		console.error("Failed to unblock IP:", error);
	}
};

export const clearPasswords = (userId) => async (dispatch) => {
	try {
		const response = await api.clearPasswords(userId);
		// Handle success (optional)
		dispatch({ type: "CLEAR_PASSWORDS_SUCCESS", payload: response.data });
	} catch (error) {
		console.error(error);
		dispatch({ type: "CLEAR_PASSWORDS_ERROR", payload: error.response.data });
	}
};

export const getClaims = () => async (dispatch) => {
	try {
		const { data } = await api.getClaims(); // Create this API call
		dispatch({ type: ADMIN_GET_CLAIMS, payload: data });
	} catch (error) {
		console.log(error);
	}
};

export const getLastTrips = () => async (dispatch) => {
	try {
		const { data } = await api.fetchLastTrips(); // Create this API call function
		dispatch({ type: ADMIN_GET_LAST_TRIPS, payload: data });
	} catch (error) {
		console.error("Error fetching last trips:", error);
	}
};
