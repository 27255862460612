import React, { useState, useEffect } from "react";
import {
	Avatar,
	Button,
	Paper,
	Grid,
	Typography,
	Container,
	CircularProgress,
	List,
	ListItem,
} from "@material-ui/core";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
//import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
//import { toast } from "react-toastify";

import useStyles from "./styles";
import Input from "./Input";
import { signup, requestCode, verifyCode } from "../../actions/auth"; // Import new actions

const initialState = {
	firstName: "",
	lastName: "",
	email: "",
	code: "",
	login: "",
};

const Auth = () => {
	const classes = useStyles();
	const [showPassword, setShowPassword] = useState(false);
	const [isSignup, setIsSignup] = useState(false);
	const [formData, setFormData] = useState(initialState);
	const [isLoginValid, setIsLoginValid] = useState(true);
	const [isCodeSent, setIsCodeSent] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const dispatch = useDispatch();
	const history = useHistory();
	const isLoading = useSelector((state) => state.auth?.isLoading);

	const maxFieldLengths = {
		firstName: 50,
		lastName: 50,
		email: 150,
		login: 30,
		password: 300,
		confirmPassword: 300,
		code: 6,
	};

	const validateLength = (value, maxLength) => {
		return value.length <= maxLength;
	};

	const handleShowPassword = () => {
		setShowPassword((prevShowPassword) => !prevShowPassword);
	};

	const switchMode = () => {
		setIsSignup((prevIsSignup) => !prevIsSignup);
		setIsCodeSent(false);
		setFormData(initialState);
	};

	const validateLogin = (login) => {
		const loginRegex = /^[a-z0-9]{1,30}$/; // Restrict to lowercase Latin letters and numbers, max 30 chars
		return loginRegex.test(login);
	};

	const handleLoginChange = (e) => {
		const login = e.target.value;
		// Restrict length and character set
		if (validateLength(login, maxFieldLengths.login)) {
			setFormData({ ...formData, login });
			if (!validateLogin(login)) {
				setIsLoginValid(false);
			} else {
				setIsLoginValid(true);
			}
		}
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		if (isSignup) {
			if (isLoginValid) {
				dispatch(signup(formData, history));
			} else {
				setErrorMessage("Логин не соответствует критериям");
			}
		} else {
			if (isCodeSent) {
				if (!formData.code) {
					setErrorMessage("Введите код");
					return;
				}
				try {
					await dispatch(verifyCode(formData.email, formData.code, history));
				} catch (error) {
					setErrorMessage(error);
				}
			} else {
				if (!formData.email) {
					setErrorMessage("Введите email");
					return;
				}
				try {
					await dispatch(requestCode(formData.email));
					setIsCodeSent(true);
					setErrorMessage("");
				} catch (error) {
					console.log(error);
					setErrorMessage(error);
				}
			}
		}
	};

	const handleResendCode = async (e) => {
		e.preventDefault();
		if (!formData.email) {
			setErrorMessage("Введите email");
			return;
		}
		try {
			await dispatch(requestCode(formData.email));
			setIsCodeSent(true);
			setErrorMessage("Код направлен повторно. Проверьте папку спам");
		} catch (error) {
			console.log(error);
			setErrorMessage(error);
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		if (validateLength(value, maxFieldLengths[name])) {
			setFormData({ ...formData, [name]: value });
		}
	};

	useEffect(() => {
		if (formData?.code.length === 6) {
			handleSubmit(new Event("submit")); // Create a fake event to trigger submission
		}
	}, [formData?.code]);

	return (
		<Container component="main" maxWidth="xs">
			<Paper className={classes.paper}>
				<Avatar className={classes.avatar}>
					<LockOutlinedIcon />
				</Avatar>
				<Typography variant="h5">
					{isSignup ? "Регистрация" : "Вход"}
				</Typography>
				<form className={classes.form} onSubmit={handleSubmit}>
					<Grid container spacing={2}>
						{isSignup && (
							<>
								<div className={classes.login}>
									<Typography className={classes.loginAt}>@</Typography>
									<Input
										name="login"
										label="Придумайте логин"
										handleChange={handleLoginChange}
										autoFocus
										error={!isLoginValid}
										helperText={
											!isLoginValid &&
											"Логин должен содержать только латинские символы и цифры, не более 30 символов."
										}
									/>
								</div>
								<Input
									name="firstName"
									label="Имя"
									handleChange={handleChange}
									autoFocus
									half
									helperText={`Не более ${maxFieldLengths.firstName} символов`}
								/>
								<Input
									name="lastName"
									label="Фамилия"
									handleChange={handleChange}
									half
									helperText={`Не более ${maxFieldLengths.lastName} символов`}
								/>
							</>
						)}
						{!isCodeSent && (
							<Input
								name="email"
								label="Email"
								handleChange={handleChange}
								type="email"
								required
							/>
						)}

						{isCodeSent && (
							<Typography className={classes.codeSentMessage}>
								📩 Код был отправлен вам на почту. Проверьте папку "Спам", если
								код не пришел.
							</Typography>
						)}

						{isCodeSent && (
							<Input
								name="code"
								label="Введите код"
								handleChange={handleChange}
								type="text"
								required
							/>
						)}
					</Grid>

					{errorMessage && (
						<Typography color="error">{errorMessage}</Typography>
					)}

					<Button
						type="submit"
						fullWidth
						variant="contained"
						className={classes.submit}
						disabled={isSignup && !isLoginValid}
					>
						{isLoading ? (
							<CircularProgress
								style={{ color: "white", margin: "0 auto" }}
								size="1.5rem"
							/>
						) : isSignup ? (
							"Зарегистрироваться"
						) : isCodeSent ? (
							"Подтвердить код"
						) : (
							"Получить код"
						)}
					</Button>

					{isCodeSent && (
						<Button
							fullWidth
							variant="outlined"
							className={classes.submit}
							onClick={handleResendCode}
						>
							Отправить код снова
						</Button>
					)}

					<Grid container justify="flex-end">
						<Grid item>
							<Button onClick={switchMode} className={classes.buttonSignUp}>
								{isSignup
									? "Уже есть аккаунт? Войти"
									: "Нет аккаунта? Зарегистрируйтесь"}
							</Button>
						</Grid>
					</Grid>
					<div className={classes.infoBanner}>
						<Typography variant="h6" className={classes.bannerTitle}>
							🔑 Мы перешли на аутентификацию без пароля!
						</Typography>
						<Typography variant="body2" className={classes.bannerText}>
							Теперь можно входить на сайт при помощи одноразовых кодов.
						</Typography>
						<Typography variant="body2" className={classes.prosTitle}>
							📌 Почему это удобно?
						</Typography>
						<List className={classes.prosList}>
							<ListItem className={classes.prosItem}>
								<Typography variant="body2">
									✅ Не нужно запоминать сложные пароли
								</Typography>
							</ListItem>
							<ListItem className={classes.prosItem}>
								<Typography variant="body2">
									{" "}
									✅ Повышенная безопасность
								</Typography>
							</ListItem>
							<ListItem className={classes.prosItem}>
								<Typography variant="body2">
									✅ Быстрая и удобная авторизация
								</Typography>
							</ListItem>
						</List>
					</div>
				</form>
			</Paper>
		</Container>
	);
};

export default Auth;
