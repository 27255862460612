import {
	ADD_ALERT,
	DELETE_ALL_ALERTS,
	ADD_LAST_PHOTOS,
	ADD_LAST_COMMENTS,
	ADD_LAST_PLACES,
	FETCH_BLOCKED_IPS_SUCCESS,
	BLOCK_IP_SUCCESS,
	UNBLOCK_IP_SUCCESS,
	ADMIN_GET_CLAIMS,
	ADMIN_GET_LAST_TRIPS,
} from "../constants/actionTypes";

const adminReducer = (
	state = {
		alert: null,
		lastPhotos: [],
		lastComments: [],
		lastPlaces: [],
		blockedIPs: [],
		claims: [],
	},
	action
) => {
	switch (action.type) {
		case ADD_ALERT:
			return { ...state, alert: action.payload };
		case DELETE_ALL_ALERTS:
			return { ...state, alert: null };
		case ADD_LAST_PHOTOS:
			return { ...state, lastPhotos: action.payload };
		case ADD_LAST_COMMENTS:
			return { ...state, lastComments: action.payload };
		case ADD_LAST_PLACES:
			return { ...state, lastPlaces: action.payload };
		case FETCH_BLOCKED_IPS_SUCCESS:
			return { ...state, blockedIPs: action.payload };
		case BLOCK_IP_SUCCESS:
			return { ...state, blockedIPs: [...state.blockedIPs, action.payload] };
		case UNBLOCK_IP_SUCCESS:
			return {
				...state,
				blockedIPs: state.blockedIPs.filter((ip) => ip.ip !== action.payload),
			};
		case ADMIN_GET_CLAIMS:
			return { ...state, claims: action.payload };
		case ADMIN_GET_LAST_TRIPS:
			return { ...state, lastTrips: action.payload };
		default:
			return state;
	}
};

export default adminReducer;
