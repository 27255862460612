import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { updateTrip } from "../../../actions/trips";
import {
	List,
	ListItem,
	ListItemText,
	Checkbox,
	IconButton,
	TextField,
	Button,
	Typography,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import useStyles from "./styles";

const PackingList = ({ trip, tripId, checkUser }) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const [packingList, setPackingList] = useState(trip.packingList || []);
	const [item, setItem] = useState("");

	const togglePacked = (id) => {
		const updatedList = packingList.map((item) =>
			item.id === id ? { ...item, packed: !item.packed } : item
		);
		let updatedTripData = {
			...trip,
			packingList: updatedList,
			userId:
				typeof trip.userId === "object" && trip.userId._id
					? trip.userId._id
					: trip.userId,
			cities: trip.cities.map((city) => ({
				...city,
				cityId:
					typeof city.cityId === "object" && city.cityId._id
						? city.cityId._id
						: city.cityId,
			})),
		};
		setPackingList(updatedList);
		dispatch(updateTrip(tripId, updatedTripData));
	};

	const addItem = (item) => {
		if (item.trim()) {
			const newItem = { id: Date.now(), item, packed: false };
			const updatedList = [...packingList, newItem];
			let updatedTripData = {
				...trip,
				packingList: updatedList,
				userId:
					typeof trip.userId === "object" && trip.userId._id
						? trip.userId._id
						: trip.userId,
				cities: trip.cities.map((city) => ({
					...city,
					cityId:
						typeof city.cityId === "object" && city.cityId._id
							? city.cityId._id
							: city.cityId,
				})),
			};
			setPackingList(updatedList);
			dispatch(updateTrip(tripId, updatedTripData));
		}
	};

	const removeItem = (id) => {
		const updatedList = packingList.filter((item) => item.id !== id);
		let updatedTripData = {
			...trip,
			packingList: updatedList,
			userId:
				typeof trip.userId === "object" && trip.userId._id
					? trip.userId._id
					: trip.userId,
			cities: trip.cities.map((city) => ({
				...city,
				cityId:
					typeof city.cityId === "object" && city.cityId._id
						? city.cityId._id
						: city.cityId,
			})),
		};
		setPackingList(updatedList);
		dispatch(updateTrip(tripId, updatedTripData));
	};

	if (!checkUser) {
		return <></>;
	}

	return (
		<div>
			<Typography variant="h6" className={classes.timelineTitle}>
				Список вещей в дорогу:
			</Typography>
			{packingList.length === 0 && checkUser && (
				<Typography>
					Составьте список вещей в дорогу, чтобы ничего не забыть
				</Typography>
			)}
			{packingList.length > 0 && checkUser && (
				<Typography>Отметьте то, что вы уже собрали</Typography>
			)}
			<List>
				{packingList.map((item) => (
					<ListItem key={item.id} className={classes.itemsListContainer}>
						<Checkbox
							checked={item.packed}
							onChange={() => togglePacked(item.id)}
						/>
						<ListItemText primary={item.item} />
						<IconButton onClick={() => removeItem(item.id)}>
							<DeleteIcon />
						</IconButton>
					</ListItem>
				))}
			</List>
			<div className={classes.profile}>
				<TextField
					label="Добавить вещь"
					value={item}
					onChange={(e) => setItem(e.target.value)}
					onKeyPress={(e) => {
						if (e.key === "Enter") addItem(item);
					}}
				/>
				<Button onClick={() => addItem(item)}>Добавить</Button>
			</div>
		</div>
	);
};

export default PackingList;
