import React from "react";
import {
	Card,
	CardActions,
	CardContent,
	CardMedia,
	Typography,
	Box,
} from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles.js";

const ReportCardSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.card} raised elevation={6}>
			<CardMedia className={classes.media}></CardMedia>
			<div className={classes.overlay}>
				<Skeleton variant="text" width={120} height={30} />
				<Skeleton variant="text" width={90} height={20} />
			</div>
			<div className={classes.details}>
				<Box className={classes.tagBox}>
					<Skeleton variant="text" width={60} height={20} />
				</Box>
				<Box className={classes.tagBox}>
					<Skeleton variant="text" width={60} height={20} />
				</Box>
			</div>
			<Typography className={classes.title} variant="h5" gutterBottom>
				<Skeleton variant="text" width="80%" height={30} />
			</Typography>
			<CardContent>
				<Skeleton variant="text" width="90%" height={20} />
				<Skeleton variant="text" width="85%" height={20} />
				<Skeleton variant="text" width="80%" height={20} />
			</CardContent>
			<CardActions className={classes.cardActions}>
				<Skeleton variant="circle" width={30} height={30} />
				<Skeleton variant="text" width={50} height={20} />
				<Box className={classes.visitedBox}>
					<Skeleton variant="circle" width={20} height={20} />
					<Skeleton variant="text" width={30} height={20} />
				</Box>
			</CardActions>
		</Card>
	);
};

export default ReportCardSkeleton;
