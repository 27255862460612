import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";

const ResponseWrite = ({
	classes,
	c,
	response,
	setResponse,
	handleResponse,
	i,
}) => {
	return (
		<div className={classes.responseBox}>
			<Typography gutterBottom variant="h6">
				Написать ответ @{c.userLogin}
			</Typography>
			<TextField
				fullWidth
				rows={4}
				variant="outlined"
				label="Ответ"
				multiline
				value={response}
				onChange={(e) => setResponse(e.target.value)}
				inputProps={{ maxLength: 3500 }}
			/>
			<Button
				style={{ marginTop: "10px" }}
				fullWidth
				disabled={!response}
				variant="contained"
				className={classes.buttonPrimary}
				onClick={() => handleResponse(c, i)}
			>
				Опубликовать
			</Button>
		</div>
	);
};

export default ResponseWrite;
