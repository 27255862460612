import React from "react";
import { Typography, TextField, Button } from "@material-ui/core";

const CommentWrite = ({ comment, setComment, handleClick, classes }) => {
	return (
		<div style={{ width: "100%" }}>
			<Typography gutterBottom variant="h6">
				Написать комментарий
			</Typography>
			<TextField
				fullWidth
				rows={4}
				variant="outlined"
				label="Комментарий"
				multiline
				value={comment}
				onChange={(e) => setComment(e.target.value)}
				inputProps={{ maxLength: 3500 }}
			/>
			<Button
				style={{ marginTop: "10px" }}
				fullWidth
				disabled={!comment}
				variant="contained"
				className={classes.buttonPrimary}
				onClick={handleClick}
			>
				Опубликовать
			</Button>
		</div>
	);
};

export default CommentWrite;
