// components/PostProfileButtons.js
import React from "react";
import { Button, Typography, Paper } from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import RoomIcon from "@material-ui/icons/Room";
import Calendar from "../../Calendar/Calendar";

const PostProfileButtons = ({
	postContent,
	user,
	classes,
	handleLike,
	handleDeletePost,
	handleUpdatePost,
	setGeoChoose,
	geoChoose,
	handleButtonGeoClick,
	deleteAsk,
	setDeleteAsk,
	isUpdatePost,
}) => {
	const Likes = () => {
		if (postContent?.likes?.length > 0) {
			return postContent.likes.includes(user?.result?._id) ? (
				<>
					<FavoriteIcon />
					&nbsp; {postContent.likes.length}
				</>
			) : (
				<>
					<FavoriteBorderIcon />
					&nbsp;
					{postContent.likes.length}{" "}
				</>
			);
		}
		return (
			<>
				<FavoriteBorderIcon />
				&nbsp;
			</>
		);
	};

	return (
		<>
			<div className={classes.calendarLike}>
				<div className={classes.calendarOuterContainer}>
					<Calendar createdAt={postContent.createdAt} />
				</div>
				{user?.result ? (
					<Button
						className={
							postContent?.likes.find((like) => like === user?.result?._id)
								? classes.likeButtonClicked
								: classes.likeButtonNotClicked
						}
						onClick={handleLike}
					>
						<Likes />
					</Button>
				) : (
					<Button
						className={
							postContent?.likes.find((like) => like === user?.result?._id)
								? classes.likeButtonClicked
								: classes.likeButtonNotClicked
						}
						disabled
					>
						<Likes />
					</Button>
				)}
			</div>
			{user?.result?._id === postContent?.creator && (
				<>
					{deleteAsk ? (
						<>
							<Paper className={classes.postCardDelete}>
								<Typography className={classes.writePostText}>
									Вы точно хотите удалить пост?
								</Typography>
								<div className={classes.yesNo}>
									<Button
										onClick={handleDeletePost}
										className={`${classes.buttonYes} ${classes.writePost} `}
									>
										<Typography className={classes.writePostText}>
											Да
										</Typography>
									</Button>
									<Button
										onClick={() => setDeleteAsk(!deleteAsk)}
										className={`${classes.buttonNo} ${classes.writePost} `}
									>
										<Typography className={classes.writePostText}>
											Нет
										</Typography>
									</Button>
								</div>
							</Paper>
						</>
					) : (
						<Button
							onClick={() => setDeleteAsk(!deleteAsk)}
							className={`${classes.buttonSecondary} ${classes.deletePost} `}
						>
							<DeleteIcon className={classes.writePostImg} />
							<Typography className={classes.writePostText}>
								Удалить пост
							</Typography>
						</Button>
					)}
					<Button
						className={classes.buttonPrimaryWidth}
						onClick={handleUpdatePost}
					>
						<UpdateIcon className={classes.writePostImg} />
						{isUpdatePost ? (
							<Typography className={classes.writePostText}>
								Вернуться
							</Typography>
						) : (
							<Typography className={classes.writePostText}>
								Изменить пост
							</Typography>
						)}
					</Button>
					{postContent?.place.length !== 0 ? null : (
						<Button
							onClick={() => setGeoChoose(!geoChoose)}
							className={`${classes.geographyButton}`}
						>
							<RoomIcon className={classes.writePostImg} />
							<Typography className={classes.writePostText}>
								Разместить на карте
							</Typography>
						</Button>
					)}
					{postContent?.place.length !== 0 ? (
						<Button
							onClick={() => setGeoChoose(!geoChoose)}
							className={`${classes.geographyButtonChange}`}
						>
							<RoomIcon className={classes.writePostImg} />
							<Typography className={classes.writePostText}>
								Изменить место на карте
							</Typography>
						</Button>
					) : null}
				</>
			)}
			{postContent?.place.length !== 0 ? (
				<Button
					onClick={handleButtonGeoClick}
					className={`${classes.geographyButton}`}
				>
					<RoomIcon className={classes.writePostImg} />
					<Typography className={classes.writePostText}>
						Показать на карте
					</Typography>
				</Button>
			) : null}
		</>
	);
};

export default PostProfileButtons;
