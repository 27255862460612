import React, { useState, useEffect } from "react";
import moment from "moment";
import { Button, Grid, Typography } from "@material-ui/core";
import useStyles from "./styles";
import AddActivityDialog from "./AddActivityDialog";
import Activity from "./Activity";

const timePeriods = ["Утро", "День", "Вечер", "Ночь"];

const CityTimeline = ({
	visitDates,
	currencyOptions,
	city,
	setOpenActivitiesModal,
	index,
	handleSaveActivities,
	checkUser,
}) => {
	const classes = useStyles();
	const [selectedDate, setSelectedDate] = useState(null);

	const dates = [];
	let startDate = moment(visitDates.startDate).startOf("day");
	const endDate = moment(visitDates.endDate).startOf("day");

	const [openDialog, setOpenDialog] = useState(false);
	const [selectedPeriod, setSelectedPeriod] = useState(null);
	const [activities, setActivities] = useState(city?.activities || []);
	const [typeCreateEdit, setTypeCreateEdit] = useState("");
	const [filteredIndex, setFilteredIndex] = useState("");
	const [selectedActivity, setSelectedActivity] = useState("");

	while (startDate.isSameOrBefore(endDate)) {
		dates.push(startDate.clone());
		startDate.add(1, "day");
	}

	const handleOpenDialog = (period, type, initialIndex, activity) => {
		setSelectedPeriod(period);
		type && setTypeCreateEdit(type);
		type === "edit" && setFilteredIndex(initialIndex);
		activity && setSelectedActivity(activity);
		setOpenDialog(true);
		setOpenActivitiesModal(index);
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		setTypeCreateEdit("");
		setSelectedActivity("");
		setFilteredIndex("");
	};

	const handleSaveActivity = (activity) => {
		setActivities((prevActivities) => {
			if (typeCreateEdit === "edit" && filteredIndex !== "") {
				const updatedActivities = prevActivities.map((item, index) =>
					index === filteredIndex ? activity : item
				);
				handleSaveActivities(updatedActivities);
				return updatedActivities;
			} else {
				const updatedActivities = [...prevActivities, activity];
				handleSaveActivities(updatedActivities);
				return updatedActivities;
			}
		});
	};

	// Toggle date selection
	const handleDateSelect = (date) => {
		setSelectedDate((prev) => (prev === date ? null : date));
	};

	const costSelectedDate =
		activities.length > 0 &&
		activities
			.filter(
				(activity) =>
					moment(activity.date).format("YYYY-MM-DD") === selectedDate
			)
			.reduce((acc, activity) => {
				if (activity.totalCost && activity.currency) {
					acc[activity.currency] =
						(acc[activity.currency] || 0) + activity.totalCost;
				}
				return acc;
			}, {});

	const formattedCostEntries = Object.entries(costSelectedDate).filter(
		([, amount]) => amount > 0
	);
	const formattedCost =
		formattedCostEntries.length > 0
			? formattedCostEntries
					.map(([currency, amount]) => `${amount} ${currency}`)
					.join(" ")
			: null;

	return (
		<div className={classes.cityTimeline}>
			<div className={classes.dateButtons}>
				{dates.map((date) => (
					<Button
						key={date.format("YYYY-MM-DD")}
						variant={
							selectedDate === date.format("YYYY-MM-DD")
								? "contained"
								: "outlined"
						}
						onClick={() => handleDateSelect(date.format("YYYY-MM-DD"))}
						className={
							selectedDate === date.format("YYYY-MM-DD")
								? classes.dateButtonClicked
								: classes.dateButton
						}
					>
						{date.format("DD MMM")}
					</Button>
				))}
			</div>

			{selectedDate && (
				<>
					{formattedCost && (
						<Typography className={classes.totalCost}>
							💰 Общая стоимость за день: {formattedCost}
						</Typography>
					)}
					<Grid container spacing={2} className={classes.timelineContainer}>
						{timePeriods.map((period) => (
							<Grid
								item
								xs={12}
								sm={6}
								md={3}
								key={period}
								className={classes.timelinePeriodContainer}
							>
								<Typography
									variant="subtitle1"
									className={classes.timelinePeriod}
								>
									{period}
								</Typography>
								<Activity
									activities={activities}
									selectedDate={selectedDate}
									period={period}
									handleOpenDialog={handleOpenDialog}
									checkUser={checkUser}
								/>
								{checkUser && (
									<Button
										variant="contained"
										className={classes.buttonPrimary}
										onClick={() => handleOpenDialog(period, "")}
									>
										Добавить активность
									</Button>
								)}
							</Grid>
						))}
						<AddActivityDialog
							open={openDialog}
							onClose={handleCloseDialog}
							onSave={handleSaveActivity}
							selectedPeriod={selectedPeriod}
							selectedDate={selectedDate}
							currencyOptions={currencyOptions}
							type={typeCreateEdit}
							selectedActivity={selectedActivity}
							setSelectedActivity={setSelectedActivity}
						/>
					</Grid>
				</>
			)}
		</div>
	);
};

export default CityTimeline;
