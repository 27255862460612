import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
	Typography,
	Button,
	CircularProgress,
	Box,
	Grid,
} from "@material-ui/core";
import { findPlacesByCity } from "../../../../actions/places";
import PlacesInCityList from "./PlacesInCityList/PlacesInCityList";

const PlaceCityToDo = ({ place, type }) => {
	const dispatch = useDispatch();
	const placesInCity = useSelector((state) => state.place.placesByCity);
	const museums = placesInCity
		? placesInCity.filter(
				(placeIn) =>
					placeIn.placeType === "museum" && placeIn.city === place._id
		  )
		: null;
	const restaurants = placesInCity
		? placesInCity.filter(
				(placeIn) =>
					placeIn.placeType === "restaurant" && placeIn.city === place._id
		  )
		: null;
	const sites = placesInCity
		? placesInCity.filter(
				(placeIn) => placeIn.placeType === "site" && placeIn.city === place._id
		  )
		: null;
	const parks = placesInCity
		? placesInCity.filter(
				(placeIn) => placeIn.placeType === "park" && placeIn.city === place._id
		  )
		: null;
	const hotels = placesInCity
		? placesInCity.filter(
				(placeIn) => placeIn.placeType === "hotel" && placeIn.city === place._id
		  )
		: null;
	const churches = placesInCity
		? placesInCity.filter(
				(placeIn) =>
					placeIn.placeType === "church" && placeIn.city === place._id
		  )
		: null;

	useEffect(() => {
		dispatch(findPlacesByCity(place?._id, 1, "museum"));
		dispatch(findPlacesByCity(place?._id, 1, "restaurant"));
		dispatch(findPlacesByCity(place?._id, 1, "site"));
		dispatch(findPlacesByCity(place?._id, 1, "park"));
		dispatch(findPlacesByCity(place?._id, 1, "hotel"));
		dispatch(findPlacesByCity(place?._id, 1, "church"));
	}, []);

	return (
		<>
			{type === "short" ? null : (
				<Typography variant="h6">
					Что посетить в городе {place?.placeName}?
				</Typography>
			)}
			<Grid container spacing={2}>
				<PlacesInCityList
					placesType="museum"
					placesForList={museums}
					place={place}
				/>
				<PlacesInCityList
					placesType="restaurant"
					placesForList={restaurants}
					place={place}
				/>
				<PlacesInCityList
					placesType="site"
					placesForList={sites}
					place={place}
				/>
				{parks.length > 0 ? (
					<PlacesInCityList
						placesType="park"
						placesForList={parks}
						place={place}
					/>
				) : null}
				{churches.length > 0 ? (
					<PlacesInCityList
						placesType="church"
						placesForList={churches}
						place={place}
					/>
				) : null}
			</Grid>
			{type === "short" ? null : (
				<>
					<Typography variant="h6">
						Где остановиться в городе {place?.placeName}?
					</Typography>
					<Grid container spacing={2}>
						<PlacesInCityList
							placesType="hotel"
							placesForList={hotels}
							place={place}
						/>
					</Grid>
				</>
			)}
		</>
	);
};

export default PlaceCityToDo;
