import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import {
	Paper,
	Typography,
	CircularProgress,
	Button,
	Grid,
	Box,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { batch } from "react-redux";
import {
	getPost,
	getPostsBySearch,
	likePost,
	deletePost,
} from "../../actions/posts";
import { getReportUserInfo } from "../../actions/users.js";
import useStyles from "./styles";
import PostCreate from "../PostReport/PostCreate/PostCreate";
import PostProfile from "../PostContent/PostProfile/PostProfile";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import ReportUpdate from "./ReportUpdate/ReportUpdate";
import MapReport from "../Maps/MapReport/MapReport.jsx";
import { checkPlacesByPostsId } from "../../actions/places.js";
import Comment from "../Comment/Comment.jsx";
import PostProfileSkeleton from "../PostContent/PostProfile/PostProfileSkeleton.jsx";
import ReportTitle from "./ReportTitle/ReportTitle.jsx";
import ReportPostsList from "./ReportPostsList/ReportPostsList.jsx";
import Post from "../Posts/Post/Post.js";
import ReportActions from "./ReportActions/ReportActions.jsx";
import ReportSkeleton from "./ReportSkeleton/ReportSkeleton.jsx";
import ReportRecommendedReports from "./ReportRecommendedReports/ReportRecommendedReports.jsx";

const ReportDetails = ({ user }) => {
	const { post, posts, isLoading } = useSelector((state) => state.posts);
	const profileInfo = useSelector((state) => {
		return state.users.result;
	});
	const dispatch = useDispatch();
	const history = useHistory();
	const classes = useStyles();
	const [createPost, setCreatePost] = useState(false);
	const [deleteAsk, setDeleteAsk] = useState(false);
	const [isUpdateReport, setIsUpdateReport] = useState(false);
	const [geoShow, setGeoShow] = useState(false);

	const [likes, setLikes] = useState(post?.likes);
	useEffect(() => {
		setLikes(post?.likes);
	}, [post]);
	const { id } = useParams();

	const postsArray = useSelector((state) => {
		return state?.posts?.initialPosts;
	});

	const postsArrayIds = postsArray?.map((post) => post._id);
	const queryParams = postsArrayIds?.join(",");

	const isPlacesExist = useSelector((state) => {
		return state?.place?.placesExist;
	});

	useEffect(() => {
		appendYaMetrix2();
	}, []);

	useEffect(() => {
		postsArrayIds?.length > 0
			? dispatch(checkPlacesByPostsId(queryParams))
			: console.log("Постов нет");
	}, [queryParams]);

	useEffect(() => {
		async function fetchData() {
			await batch(() => {
				dispatch(getPost(id));
				dispatch(getReportUserInfo(id));
			});
		}
		fetchData();
	}, [id]);

	useEffect(() => {
		if (post) {
			dispatch(
				getPostsBySearch({ search: "none", tags: post?.tags.join(",") })
			);
		}
	}, [post]);

	const recommendedPosts = posts?.filter(({ _id }) => _id !== post?._id);

	const openPost = (_id) => history.push(`/posts/${_id}`);

	const handleLike = async () => {
		dispatch(likePost(post._id));
		if (post.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				post.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...post.likes, user?.result?._id]);
		}
	};

	const handleDeleteReport = async () => {
		await dispatch(deletePost(post?._id));
		history.push(`/posts`);
	};

	const Likes = () => {
		if (likes?.length > 0) {
			return likes.find((like) => like === user?.result?._id) ? (
				<>
					<FavoriteIcon />
					&nbsp;
					{likes.length}
				</>
			) : (
				<>
					<FavoriteBorderIcon />
					&nbsp;{likes.length}
				</>
			);
		}
		return (
			<>
				<FavoriteBorderIcon />
				&nbsp;
			</>
		);
	};

	const handleCreatePost = () => {
		setCreatePost(!createPost);
	};

	const handleButtonGeoClick = async () => {
		try {
			setGeoShow(!geoShow);
		} catch (error) {
			console.error("Error fetching place:", error);
		}
	};

	if (geoShow) {
		return <MapReport setGeoShow={setGeoShow} geoShow={geoShow} />;
	}

	const appendYaMetrix2 = () => {
		const yaScript2 = document.createElement("script");
		yaScript2.setAttribute("type", "text/javascript");
		yaScript2.innerHTML = `window.yaContextCb.push(()=>{
			Ya.Context.AdvManager.render({
				"blockId": "R-A-6633212-2",
				"renderTo": "yandex_rtb_R-A-6633212-2"
			})
		})`;

		document.head.appendChild(yaScript2);
	};

	return (
		<>
			<Helmet>
				<title>
					{`${post?.title ? post?.title : ""}`} - отчет о поездке в страну{" "}
					{`${post?.tags[0]}`}, ToTravelRu
				</title>
				<meta
					name="description"
					content={`${
						post?.title ? post?.title : ""
					}, отчет о поездке в страну ${post?.tags[0]}- ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${post?.title}, ${post?.tags[0]}, отчет, отчеты, отчет о путешествии, отчёт, отчёт о путешествии, путешествия, travel, totravel, ToTravelRu`}
				/>
			</Helmet>
			<Grid
				container
				justifyContent="space-between"
				alignItems="stretch"
				spacing={2}
				className={classes.gridContainer}
			>
				{profileInfo?.login && post ? (
					<Grid item xs={12} sm={12} md={2}>
						<PostProfile profile={profileInfo} />
						<ReportActions
							post={post}
							user={user}
							classes={classes}
							handleLike={handleLike}
							deleteAsk={deleteAsk}
							setDeleteAsk={setDeleteAsk}
							isUpdateReport={isUpdateReport}
							setIsUpdateReport={setIsUpdateReport}
							handleDeleteReport={handleDeleteReport}
							handleButtonGeoClick={handleButtonGeoClick}
							isPlacesExist={isPlacesExist}
							Likes={Likes}
						/>
					</Grid>
				) : (
					<Grid item xs={12} sm={12} md={2}>
						<PostProfileSkeleton />
					</Grid>
				)}
				<Grid item xs={12} sm={12} md={10}>
					{post ? (
						isUpdateReport ? (
							<ReportUpdate post={post} />
						) : createPost ? (
							<Paper className={classes.paperPost}>
								<PostCreate
									post={post}
									user={user}
									createPost={createPost}
									setCreatePost={setCreatePost}
									handleCreatePost={handleCreatePost}
								/>
							</Paper>
						) : (
							<>
								<ReportTitle post={post} classes={classes} user={user} />
								<ReportPostsList
									post={post}
									user={user}
									classes={classes}
									setCreatePost={setCreatePost}
									createPost={createPost}
								/>
								<div id="yandex_rtb_R-A-6633212-2"></div>
								<Comment user={user} postContentFull={post} type="report" />
								<ReportRecommendedReports
									recommendedPosts={recommendedPosts}
									classes={classes}
								/>
							</>
						)
					) : (
						<ReportSkeleton />
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default ReportDetails;
