// src/components/Comment/CommentSkeleton.jsx

import React from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, Typography } from "@material-ui/core";
import useStyles from "./styles.js";

const CommentSkeleton = () => {
	const classes = useStyles();

	return (
		<div className={classes.commentsInnerContainer}>
			{[1, 2, 3].map((index) => (
				<Box key={index} className={classes.commentSkeletonContainer}>
					{/* Skeleton for User Avatar */}
					<Box display="flex" alignItems="center" mb={2}>
						<Skeleton variant="circle" width={40} height={40} />
						<Box ml={2}>
							<Skeleton width={100} height={20} />
							<Skeleton width={150} height={15} />
						</Box>
					</Box>

					{/* Skeleton for Comment Text */}
					<Skeleton variant="rect" height={60} width="100%" />

					{/* Skeleton for like/comment actions */}
					<Box display="flex" justifyContent="space-between" mt={1}>
						<Skeleton width={60} height={20} />
						<Skeleton width={60} height={20} />
					</Box>

					{/* Skeleton for nested responses */}
					<Box mt={3} pl={4}>
						{[1, 2].map((subIndex) => (
							<Box key={subIndex} mb={2}>
								<Box display="flex" alignItems="center" mb={1}>
									<Skeleton variant="circle" width={30} height={30} />
									<Box ml={2}>
										<Skeleton width={80} height={15} />
										<Skeleton width={120} height={10} />
									</Box>
								</Box>
								<Skeleton variant="rect" height={40} width="90%" />
							</Box>
						))}
					</Box>
				</Box>
			))}
		</div>
	);
};

export default CommentSkeleton;
