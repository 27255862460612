import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useRef } from "react";
import {
	Typography,
	CircularProgress,
	Dialog,
	DialogContent,
	IconButton,
	Grid,
} from "@material-ui/core";
import { addCityToPlace, getPlace } from "../../../actions/place.js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CloseIcon from "@material-ui/icons/Close";
import useStyles from "./styles.js";
import PostInPlace from "./PostInPlace/PostInPlace.jsx";
import PlaceReviewList from "./PlaceReviewList/PlaceReviewList.jsx";
import {
	translatePlaceType,
	translatePlaceTypePlural,
} from "../../../utils/placeUtils";
import PlaceCityToDo from "./PlaceCityToDo/PlaceCityToDo.jsx";
import SearchGeo from "../../Maps/SelectGeo/SearchGeo/SearchGeo.jsx";
import PlaceCard from "./PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard.jsx";
import { findNearestPlaces } from "../../../actions/places.js";
import PlacePhotos from "./PlacePhotos/PlacePhotos.jsx";
import HowToGet from "./HowToGet/HowToGet.jsx";
import Amenities from "./Amenities/Amenities.jsx";
import { getPhotoByUrl } from "../../../actions/photo.js";
import { fetchPlaceSelections } from "../../../actions/placeSelections.js";
import LocationHeader from "./LocationHeader.jsx";
import StickyButtonRow from "./StickyButtonRow.jsx";
import PlaceMap from "./PlaceMap.jsx";
import PlaceInformationBox from "./PlaceInformationBox.jsx";
import NearestPlaces from "./NearestPlaces.jsx";

const LocationDetails = ({ user }) => {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();
	const history = useHistory();
	const place = useSelector((state) => state.place.place);
	const amenities = place?.amenities;
	const nearestPlaces = useSelector((state) => state.place?.nearestPlaces);
	const isLoaadingNearestPlaces = useSelector(
		(state) => state.place?.isLoaadingNearestPlaces
	);
	const titlePhotoObject = useSelector(
		(state) => state?.photo?.selectedPhotos["titlePhoto"]
	);
	const selections = useSelector(
		(state) => state?.placeSelections?.placeSelections
	);

	const [isSuggestedText, setIsSuggestedText] = useState(false);
	const [isAddCity, setIsAddCity] = useState(false);
	const [selectedCity, setSelectedCity] = useState(null);
	const [isInfoFormOpen, setIsInfoFormOpen] = useState(false);
	const [openImageDialog, setOpenImageDialog] = useState(false);
	const [selectedImage, setSelectedImage] = useState(null);
	const [visibleImages, setVisibleImages] = useState(10);
	const [hasFetched, setHasFetched] = useState(false);
	user = user?.result;
	let userId = user?._id;
	const dispatchResult = useSelector((state) => state.place.boolean);
	const isLoading = useSelector((state) => state.place.isLoading);
	const [isAddImage, setIsAddImage] = useState(false);
	// Add these refs at the top of the component
	const mapSectionRef = React.createRef();
	const infoSectionRef = React.createRef();
	const photosSectionRef = React.createRef();
	const cityTodoSectionRef = React.createRef();
	const howToGetSectionRef = React.createRef();
	const reviewsSectionRef = React.createRef();
	const postsSectionRef = React.createRef();
	const postsContainerRef = useRef(null);

	const scrollToSection = (section) => {
		let offset = -47; // Offset by -47px

		let sectionRef;
		switch (section) {
			case "mapSection":
				sectionRef = mapSectionRef;
				break;
			case "infoSection":
				sectionRef = infoSectionRef;
				break;
			case "photosSection":
				sectionRef = photosSectionRef;
				break;
			case "cityTodoSection":
				sectionRef = cityTodoSectionRef;
				break;
			case "howToGetSection":
				sectionRef = howToGetSectionRef;
				break;
			case "reviewsSection":
				sectionRef = reviewsSectionRef;
				break;
			case "postsSection":
				sectionRef = postsSectionRef;
				break;
			default:
				return;
		}

		const elementPosition =
			sectionRef.current.getBoundingClientRect().top + window.pageYOffset;
		const offsetPosition = elementPosition + offset;

		window.scrollTo({
			top: offsetPosition,
			behavior: "smooth",
		});
	};

	const switchAddImage = () => {
		setIsAddImage((prevIs) => !prevIs);
	};

	const handleImageClick = (image) => {
		setSelectedImage(image);
		setOpenImageDialog(true);
	};

	const handleShowMoreImages = () => {
		setVisibleImages((prev) => prev + 10);
	};

	const switchSuggestText = () => {
		setIsSuggestedText((prevIs) => !prevIs);
	};

	const handleSelectedCity = async (selectedPlace) => {
		setSelectedCity(() => selectedPlace);
		await dispatch(addCityToPlace(place._id, selectedPlace._id));
		setIsAddCity((prev) => !prev);
	};

	const handleOpenMap = () => {
		history.push("/map");
	};

	const areAllAmenitiesFalse = (amenities) => {
		return Object.keys(amenities).every((category) => {
			return Object.keys(amenities[category]).every(
				(subAmenity) => !amenities[category][subAmenity]
			);
		});
	};

	useEffect(() => {
		dispatch(getPlace(id));
	}, [id, dispatch]);

	useEffect(() => {
		place?.images &&
			place?.images.length > 0 &&
			dispatch(getPhotoByUrl(place?.images[0].image, "titlePhoto"));
	}, [place]);

	useEffect(() => {
		const handleScroll = () => {
			const postsContainer = postsContainerRef.current;
			if (postsContainer && !hasFetched) {
				const rect = postsContainer.getBoundingClientRect();
				if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
					dispatch(
						findNearestPlaces(
							"1",
							place?.placeType,
							"byDistance",
							place?.geoposition[0],
							place?.geoposition[1]
						)
					);
					setHasFetched(true);
				}
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [hasFetched, dispatch, place?.geoposition]);

	useEffect(() => {
		const fetchSelections = async () => {
			if (place?.placeType === "city") {
				await dispatch(fetchPlaceSelections(1, 10, place?._id));
			}
		};

		fetchSelections();
	}, [place, dispatch]);

	const averageRate = place
		? place.placeRate.reduce((total, rate) => total + rate.rate, 0) /
				place.placeRate.length || "N/A"
		: null;

	const averageHotelRate = place ? place?.averageRate : null;
	const averageCheck = place ? place?.averageCheck : null;
	const averageTicketAdult = place ? place?.averageTicketAdult : null;
	const averageTicketChild = place ? place?.averageTicketChild : null;

	const openCity = () => (window.location.href = `/place/${place?.city}`);

	const placeTypeMeta = {
		city: `, что посетить в городе ${place?.placeName}, как добраться до города`,
		restaurant: ", меню ресторана, фотографии ресторана",
		hotel: ", номера отеля, фотографии отеля",
		museum: ", часы работы музея, информация о музее",
		site: ", информация о месте",
	};

	const placeDescription = placeTypeMeta[place?.placeType] || "";

	return (
		<>
			<Helmet>
				<title>{`${place?.placeName} - ${translatePlaceType(
					place?.placeType
				)?.toLowerCase()} на карте${placeDescription}, отзывы`}</title>
				<meta
					name="description"
					content={`${place?.placeName}, место на карте, ${translatePlaceType(
						place?.placeType
					).toLowerCase()}, координаты, как добраться до ${translatePlaceType(
						place?.placeType
					).toLowerCase()} ${place?.placeName}${
						place?.placeType === "city"
							? `, что посетить в городе ${place?.placeName}`
							: ""
					}${
						place?.placeType === "city"
							? `, путеводитель по городу ${place?.placeName}`
							: ""
					}${
						place?.placeType === "restaurant"
							? `, меню ресторана ${place?.placeName}`
							: ""
					}${
						place?.placeType === "hotel"
							? `, номера отеля ${place?.placeName}`
							: ""
					}${
						place?.placeType === "museum"
							? `, часы работы музея ${place?.placeName}`
							: ""
					} - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${place?.placeName}, карта, место на карте, ${
						place?.placeType === "city" ? "путеводитель" : ""
					} отчет, отчеты, путешествия, travel, totravel, ToTravelRu, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия`}
				/>
				<meta property="og:title" content={place?.placeName} />
				<meta property="og:description" content={place?.description} />
				<meta property="og:image" content={place?.images?.[0]?.image} />
				<script type="application/ld+json">
					{JSON.stringify({
						"@context": "https://schema.org",
						"@type": "Place",
						name: place?.placeName,
						image: place?.images?.[0]?.image,
						geo: {
							"@type": "GeoCoordinates",
							latitude: place?.geoposition?.[1],
							longitude: place?.geoposition?.[0],
						},
						aggregateRating: place?.placeRate?.length
							? {
									"@type": "AggregateRating",
									ratingValue: (
										place.placeRate.reduce(
											(total, rate) => total + rate.rate,
											0
										) / place.placeRate.length
									).toFixed(1),
									reviewCount: place?.placeRate.length,
							  }
							: undefined,
					})}
				</script>
			</Helmet>
			<Dialog
				open={openImageDialog}
				onClose={() => setOpenImageDialog(false)}
				maxWidth="md"
				fullWidth
			>
				<DialogContent style={{ position: "relative", padding: 0 }}>
					<img
						src={selectedImage}
						alt="Enlarged"
						className={classes.dialogImage}
					/>

					<IconButton
						edge="end"
						color="inherit"
						onClick={() => setOpenImageDialog(false)}
						aria-label="close"
						style={{ position: "absolute", top: "10px", right: "10px" }}
					>
						<CloseIcon style={{ color: "#fff" }} />
					</IconButton>
				</DialogContent>
			</Dialog>

			<div className={classes.root}>
				<div
					className={
						place?.images && place?.images.length > 0
							? classes.mainImageContainer
							: classes.noImageScenario
					}
				>
					{place?.images && place?.images.length > 0 ? (
						<>
							<img
								src={place?.images[0].image}
								alt="Main Photo"
								className={classes.mainImage}
							/>
						</>
					) : null}
					{isLoading ? (
						<CircularProgress
							style={{ color: "white", margin: "0 auto" }}
							size="1rem"
						/>
					) : (
						<LocationHeader
							place={place}
							averageRate={averageRate}
							openCity={openCity}
							isAddCity={isAddCity}
							setIsAddCity={setIsAddCity}
							titlePhotoObject={titlePhotoObject}
							user={user}
						/>
					)}
				</div>

				{isAddCity ? (
					<div className={classes.postsContainer}>
						<Typography variant="h6">Поиск города</Typography>
						<SearchGeo onSelectedPlace={handleSelectedCity} filter="city" />
					</div>
				) : null}

				<StickyButtonRow place={place} scrollToSection={scrollToSection} />

				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} ref={mapSectionRef}>
						<PlaceMap place={place} handleOpenMap={handleOpenMap} />
					</Grid>
					<Grid item xs={12} sm={6} ref={infoSectionRef}>
						<PlaceInformationBox
							place={place}
							isLoading={isLoading}
							dispatchResult={dispatchResult}
							isSuggestedText={isSuggestedText}
							switchSuggestText={switchSuggestText}
							averageHotelRate={averageHotelRate}
							averageCheck={averageCheck}
							averageTicketAdult={averageTicketAdult}
							averageTicketChild={averageTicketChild}
							userId={userId}
							setIsSuggestedText={setIsSuggestedText}
							isInfoFormOpen={isInfoFormOpen}
							setIsInfoFormOpen={setIsInfoFormOpen}
							user={user}
						/>
					</Grid>
				</Grid>

				<div className={classes.postsContainer} ref={photosSectionRef}>
					<PlacePhotos
						place={place}
						handleShowMoreImages={handleShowMoreImages}
						handleImageClick={handleImageClick}
						user={user}
						isAddImage={isAddImage}
						setIsAddImage={setIsAddImage}
						visibleImages={visibleImages}
						switchAddImage={switchAddImage}
					/>
				</div>

				{place?.placeType === "city" ? (
					<div className={classes.postsContainer} ref={cityTodoSectionRef}>
						<PlaceCityToDo place={place} />
					</div>
				) : null}

				{place?.placeType === "city" && place?.howToGet ? (
					<div className={classes.postsContainer} ref={howToGetSectionRef}>
						<HowToGet place={place} userId={userId} />
					</div>
				) : null}

				<div className={classes.postsContainer} ref={reviewsSectionRef}>
					<PlaceReviewList place={place} user={user} />
				</div>

				{place?.postsId[0] !== null && (
					<div className={classes.postsContainer} ref={postsSectionRef}>
						{" "}
						<Typography variant="h6" gutterBottom>
							Подробные посты о месте
						</Typography>
						<Grid container spacing={2}>
							{place?.postsId.map((postId, index) => (
								<Grid key={index} item xs={12} sm={6} md={4} lg={4}>
									<PostInPlace
										user={user}
										postId={postId}
										className={classes.postItem}
									/>
								</Grid>
							))}
						</Grid>
					</div>
				)}

				{place?.placeSelectionsId &&
					place?.placeSelectionsId.length > 0 &&
					place?.placeSelectionsId[0] !== null && (
						<div className={classes.postsContainer}>
							<Typography variant="h6" gutterBottom>
								Подборки мест
							</Typography>
							<Grid container spacing={2}>
								{selections?.length > 0 &&
									selections.map((selection, index) => (
										<Grid item xs={12} sm={6} md={6} key={index}>
											<PlaceCard
												i={index + 1}
												p={selection}
												cardType={"selection"}
											/>
										</Grid>
									))}
							</Grid>
						</div>
					)}

				{amenities && !areAllAmenitiesFalse(amenities) ? (
					<div className={classes.postsContainer}>
						<Amenities amenities={amenities} />
					</div>
				) : null}

				<div className={classes.postsContainerNearest} ref={postsContainerRef}>
					<NearestPlaces
						place={place}
						nearestPlaces={nearestPlaces}
						isLoaadingNearestPlaces={isLoaadingNearestPlaces}
					/>
				</div>
			</div>
		</>
	);
};

export default LocationDetails;
