import React from "react";
import { Card, Box, Typography, Avatar } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "../styles";

const ProfileHeaderSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.card}>
			<Box className={classes.photoName}>
				<Skeleton
					variant="circle"
					width={80}
					height={80}
					style={{ marginTop: 15 }}
				/>
				<Skeleton
					variant="text"
					width={150}
					height={30}
					style={{ marginTop: 10 }}
				/>
				<Skeleton
					variant="text"
					width={100}
					height={20}
					style={{ marginTop: 5 }}
				/>
				<Skeleton
					variant="rect"
					width={160}
					height={36}
					style={{ marginTop: 15, borderRadius: 4 }}
				/>
			</Box>

			<hr className={classes.divider} />

			<Box className={classes.infoProfile}>
				<Box className={classes.descWrap}>
					<Skeleton variant="circle" width={24} height={24} />
					<Skeleton
						variant="text"
						width={60}
						height={20}
						style={{ marginLeft: 10 }}
					/>
					<Skeleton
						variant="text"
						width={30}
						height={20}
						style={{ marginLeft: 10 }}
					/>
				</Box>

				<Box className={classes.descWrap}>
					<Skeleton variant="circle" width={24} height={24} />
					<Skeleton
						variant="text"
						width={60}
						height={20}
						style={{ marginLeft: 10 }}
					/>
					<Skeleton
						variant="text"
						width={30}
						height={20}
						style={{ marginLeft: 10 }}
					/>
				</Box>

				<Box className={classes.descWrap}>
					<Skeleton variant="circle" width={24} height={24} />
					<Skeleton
						variant="text"
						width={60}
						height={20}
						style={{ marginLeft: 10 }}
					/>
					<Skeleton
						variant="text"
						width={30}
						height={20}
						style={{ marginLeft: 10 }}
					/>
				</Box>
			</Box>
		</Card>
	);
};

export default ProfileHeaderSkeleton;
