import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	card: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "space-between",
		borderRadius: "15px",
		position: "relative",
		borderColor: "rgba(236,98,98,1)",
		border: "solid 1px",
		gap: theme.spacing(1),
		padding: theme.spacing(1),
	},
	tripsContainer: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1),
		padding: theme.spacing(1),
	},
	statisticsContainer: {
		padding: theme.spacing(1),
	},
	tripCard: {
		width: "100%",
	},
}));
