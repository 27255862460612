import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import PostLegend from "./PostLegend/PostLegend";
import PostUpdate from "./PostUpdate/PostUpdate";
import { useLocation, useHistory } from "react-router-dom";
import { Grid } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
//import { batch } from "react-redux";
import { Link } from "react-router-dom";
import Comment from "../Comment/Comment.jsx";
import SelectGeo from "../Maps/SelectGeo/SelectGeo";
import { getPlace } from "../../actions/place.js";

import useStyles from "./styles";
import {
	getPostContent,
	likePostReport,
	deletePostReport,
} from "../../actions/posts";
import { getPostUserInfo, getPostUserImg } from "../../actions/users";
import PostProfile from "./PostProfile/PostProfile";
import ShowGeo from "../Maps/ShowGeo/ShowGeo.jsx";
import PostProfileButtons from "./PostProfileButtons/PostProfileButtons.jsx";
import PostButtons from "./PostButtons/PostButtons.jsx";
import PostProfileSkeleton from "./PostProfile/PostProfileSkeleton.jsx";
import PostContentContainer from "./PostContentContainer/PostContentContainer.jsx";
import PostContentContainerSkeleton from "./PostContentContainer/PostContentContainerSkeleton.jsx";

const PostContent = ({ user }) => {
	const classes = useStyles();
	const location = useLocation().pathname.split("/")[2];
	const history = useHistory();
	const dispatch = useDispatch();

	{
		/*useEffect(() => {
    async function fetchData() {
      await batch(() => {
        dispatch(getPostContent(location));
        dispatch(getPostUserInfo(location));
        dispatch(getPostUserImg(location));
      });
    }
    fetchData();
  }, []);*/
	}
	useEffect(() => {
		dispatch(getPostContent(location));
		dispatch(getPostUserInfo(location));
		dispatch(getPostUserImg(location));
		appendYaMetrix();
	}, []);

	const appendYaMetrix = () => {
		const yaScript = document.createElement("script");
		yaScript.setAttribute("type", "text/javascript");
		yaScript.innerHTML = `window.yaContextCb.push(()=>{
			Ya.Context.AdvManager.render({
				"blockId": "R-A-6633212-1",
				"renderTo": "yandex_rtb_R-A-6633212-1"
			})
		})`;

		document.head.appendChild(yaScript);
	};

	const postContentFull = useSelector((state) => {
		return state.posts.postContent;
	});
	const postsInfo = useSelector((state) => {
		return state.posts.postsInfo;
	});
	const numberOfPosts = postsInfo !== undefined ? postsInfo.length : null;
	const positionPost = postsInfo?.findIndex((object) => {
		return object._id === postContentFull[0]._id;
	});
	const postContent = postContentFull ? postContentFull[0] : 0;
	const [likes, setLikes] = useState(postContent?.likes);
	useEffect(() => {
		setLikes(postContent?.likes);
	}, [postContent?.likes]);
	const postsInfoId = postsInfo ? postsInfo.map((c) => c._id) : 0;
	const indexPost = postsInfoId ? postsInfoId.indexOf(postContent?._id) : 0;
	const [deleteAsk, setDeleteAsk] = useState(false);
	const [isUpdatePost, setIsUpdatePost] = useState(false);
	const [openImage, setOpenImage] = useState("");
	const [geoChoose, setGeoChoose] = useState(false);
	const [geoShow, setGeoShow] = useState(false);

	let place = useSelector((state) => state?.place?.place);

	const profileInfo = useSelector((state) => {
		return state.users.result;
	});
	const profileImg = useSelector((state) => {
		return state.users.userImg;
	});
	profileInfo.picture = profileImg;

	const legendProps = {
		postsInfo: postsInfo,
		postContentFull: postContentFull,
	};

	const reportLink = `/posts/${postContent?.reportId}`;
	const [legend, setLegend] = useState(0);
	const previousPost = () => {
		const prevPostLoc = postsInfoId[indexPost - 1]
			? postsInfoId[indexPost - 1]
			: postsInfoId[postsInfoId.length - 1];
		window.location.href = `/post/${prevPostLoc}`;
	};
	const nextPost = () => {
		const nextPostLoc = postsInfoId[indexPost + 1]
			? postsInfoId[indexPost + 1]
			: postsInfoId[0];
		window.location.href = `/post/${nextPostLoc}`;
	};

	const toggleLegend = () => {
		setLegend((legend) => !legend);
	};
	const closeLegend = () => setLegend(false);

	const handleLike = async () => {
		dispatch(likePostReport(postContent._id));

		if (postContent?.likes.find((like) => like === user?.result?._id)) {
			setLikes(
				postContent?.likes.filter(
					(id) => id !== (user?.result?.googleId || user?.result?._id)
				)
			);
		} else {
			setLikes([...postContent?.likes, user?.result?._id]);
		}
	};

	const handleDeletePost = async () => {
		await dispatch(deletePostReport(postContent?._id));
		history.push(`/posts`);
	};

	const handleUpdatePost = async () => {
		setIsUpdatePost(!isUpdatePost);
	};

	const handleOpenImage = (a) => {
		setOpenImage(a);
	};

	const handleCloseImage = () => {
		setOpenImage("");
	};

	const handleButtonGeoClick = async () => {
		try {
			await dispatch(getPlace(postContent.place[0]));
			setGeoShow(!geoShow);
		} catch (error) {
			console.error("Error fetching place:", error);
		}
	};

	/*const modifyInitialPosts = async () => {
    const dispatchPosts = await dispatch(
      getInitialPosts(post?.post?._id)
    );
    setInitialPosts(dispatchPosts);
  };*/

	if (geoShow) {
		return (
			<ShowGeo
				place={place}
				postId={postContent?._id}
				setGeoShow={setGeoShow}
				geoShow={geoShow}
			/>
		);
	}

	return (
		<div>
			<Helmet>
				<title>{`${postContent?.title}`} - пост, ToTravelRu</title>
				<meta
					name="description"
					content={`${postContent?.title}, пост в рамках отчета о путешествии - ToTravelRu`}
				/>
				<meta
					name="keywords"
					content={`${postContent?.title}, пост, посты, отчет, отчеты, путешествия, travel, totravel, ToTravelRu, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия`}
				/>
			</Helmet>
			{geoChoose ? (
				<SelectGeo
					postContent={postContent}
					geoChoose={geoChoose}
					setGeoChoose={setGeoChoose}
				/>
			) : (
				<>
					<div className={classes.postsOuterContainer}>
						<Grid
							container
							justifyContent="space-between"
							alignItems="stretch"
							spacing={2}
							className={classes.gridContainer}
						>
							{postContent ? (
								<Grid item xs={12} sm={3} md={2}>
									<PostProfile profile={profileInfo} />
									<PostProfileButtons
										postContent={postContent}
										user={user}
										classes={classes}
										handleLike={handleLike}
										handleDeletePost={handleDeletePost}
										handleUpdatePost={handleUpdatePost}
										setGeoChoose={setGeoChoose}
										geoChoose={geoChoose}
										handleButtonGeoClick={handleButtonGeoClick}
										deleteAsk={deleteAsk}
										setDeleteAsk={setDeleteAsk}
										isUpdatePost={isUpdatePost}
									/>
								</Grid>
							) : (
								<Grid item xs={12} sm={3} md={2}>
									<PostProfileSkeleton />
								</Grid>
							)}
							<Grid item xs={12} sm={9} md={10}>
								{isUpdatePost ? (
									<div className={classes.postCard}>
										<PostUpdate user={user} post={postContent} />
									</div>
								) : postContent ? (
									<>
										<PostContentContainer
											postContent={postContent}
											toggleLegend={toggleLegend}
											closeLegend={closeLegend}
											legend={legend}
											legendProps={legendProps}
											handleOpenImage={handleOpenImage}
											handleCloseImage={handleCloseImage}
											openImage={openImage}
											classes={classes}
											user={user}
										/>
									</>
								) : (
									<PostContentContainerSkeleton classes={classes} />
								)}
								<PostButtons
									previousPost={previousPost}
									nextPost={nextPost}
									positionPost={positionPost}
									numberOfPosts={numberOfPosts}
									reportLink={reportLink}
									classes={classes}
								/>
								<div id="yandex_rtb_R-A-6633212-1"></div>
								<div>
									<Comment
										user={user}
										postContentFull={postContentFull}
										type="post"
									/>
								</div>
							</Grid>
						</Grid>
					</div>
				</>
			)}
		</div>
	);
};

export default PostContent;
