export const validateInput = (name, value, limits, setErrors) => {
	let errorMsg = "";

	if (
		limits[name] &&
		typeof limits[name] === "number" &&
		value.length > limits[name]
	) {
		errorMsg = `Максимальная длина ${limits[name]} символов`;
	}

	if (limits[name] && typeof limits[name] === "object") {
		const numValue = Number(value);
		if (numValue < limits[name].min || numValue > limits[name].max) {
			errorMsg = `Значение должно быть от ${limits[name].min} до ${limits[name].max}`;
		}
	}

	setErrors((prev) => ({ ...prev, [name]: errorMsg }));
};
