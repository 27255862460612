import React, { useState, useRef, useEffect } from "react";
import { Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import {
	commentPost,
	commentPostReport,
	likeCommentPost,
	likeCommentReport,
	sendResponseCommentPost,
	sendResponseCommentReport,
} from "../../actions/posts.js";
import CircularProgress from "@material-ui/core/CircularProgress";
import useStyles from "./styles.js";
import CommentBox from "./CommentBox/CommentBox.jsx";
import ResponseBox from "./ResponseBox/ResponseBox.jsx";
import ResponseWrite from "./ResponseWrite/ResponseWrite.jsx";
import CommentWrite from "./CommentWrite/CommentWrite.jsx";
import CommentSort from "./CommentSort/CommentSort.jsx";
import CommentSkeleton from "./CommentSkeleton.jsx";

const Comment = ({ user, postContentFull, type }) => {
	console.log(user);
	const classes = useStyles();
	const [comment, setComment] = useState("");
	const [sortType, setSortType] = useState("oldest");
	const initialComments = postContentFull
		? type === "post"
			? postContentFull[0]?.comments
			: postContentFull?.comments
		: "";
	const [initialCommentsOrder, setInitialCommentsOrder] = useState([]);
	const dispatch = useDispatch();
	const commentsRef = useRef();
	let [comments, setComments] = useState(
		initialComments === null ? null : initialComments
	);
	const [response, setResponse] = useState("");
	const [isResponseToResponse, setIsResponseToResponse] = useState(false);
	const [numberResponsePost, setNumberResponsePost] = useState("");
	const [numberResponseResponse, setNumberResponseResponse] = useState("");
	const id = postContentFull
		? type === "post"
			? postContentFull[0]?._id
			: postContentFull?._id
		: null;

	useEffect(() => {
		setComments(initialComments || []);
		setInitialCommentsOrder((initialComments || []).map((_, index) => index));
	}, [initialComments]);

	const handleClick = async () => {
		const finalComment = {
			userId: user.result._id,
			userLogin: user.result.login,
			userName: user.result.name,
			userType: user?.result?.userType,
			text: comment,
			createdAt: new Date().toISOString(),
			likes: [],
			subcomments: [],
		};

		const newComments =
			type === "post"
				? await dispatch(commentPostReport(finalComment, id))
				: await dispatch(commentPost(finalComment, id));
		setComments(newComments);
		setComment("");

		//commentsRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const handleLike = async (value, number) => {
		const initialNumber = initialCommentsOrder.indexOf(number);
		const likeInfo =
			type === "post"
				? {
						postId: id,
						commentNumber: initialNumber,
						user: user?.result?._id,
				  }
				: {
						reportId: id,
						commentNumber: initialNumber,
						user: user?.result?._id,
				  };
		type === "post"
			? await dispatch(likeCommentPost(likeInfo))
			: await dispatch(likeCommentReport(likeInfo));
		if (value?.likes.find((like) => like === user?.result?._id)) {
			const commentsArrayFilter = comments;
			commentsArrayFilter[initialNumber].likes.filter(
				(id) => id !== (user?.result?.googleId || user?.result?._id)
			);
			setComments(commentsArrayFilter);
		} else {
			const commentsArrayPush = comments;
			commentsArrayPush[initialNumber].likes.push(likeInfo.user);
			setComments(commentsArrayPush);
		}
	};

	const toggleCommentResponse = async (value, number, numberResponse) => {
		setNumberResponsePost(number);
		value.addresseeId
			? setIsResponseToResponse(true)
			: setIsResponseToResponse(false);
		value.addresseeId
			? setNumberResponseResponse(numberResponse)
			: setNumberResponseResponse("");
		setResponse(`${value?.userName.split(" ")[0]}, `);
	};

	const handleResponse = async (value, number) => {
		const initialNumber = initialCommentsOrder.indexOf(number);
		const finalResponse = {
			userId: user.result._id,
			userLogin: user.result.login,
			userName: user.result.name,
			userType: user?.result?.userType,
			text: response,
			addresseeId: value?.userId,
			addresseeLogin: value?.userLogin,
			createdAt: new Date().toISOString(),
			likes: [],
			initialCommentNumber: initialNumber,
		};

		const newComments =
			type === "post"
				? await dispatch(sendResponseCommentPost(finalResponse, id))
				: await dispatch(sendResponseCommentReport(finalResponse, id));
		setComments(newComments);
		setResponse("");
		setNumberResponsePost("");

		//commentsRef.current.scrollIntoView({ behavior: "smooth" });
	};

	const sortComments = (comments) => {
		if (!comments) return [];
		let newOrder = [];
		switch (sortType) {
			case "latest":
				newOrder = comments.map((_, index) => index).reverse(); // Reverse the default order
				break;
			case "oldest":
				newOrder = [...comments.map((_, index) => index)];
				break;
			case "mostLikes":
				newOrder = [...comments]
					.map((comment, index) => ({ index, likes: comment.likes.length }))
					.sort((a, b) => b.likes - a.likes)
					.map((obj) => obj.index);
				break;
			default:
				newOrder = comments.map((_, index) => index);
		}

		// Create a new sorted array based on newOrder
		const sorted = newOrder.map((index) => comments[index]);

		// Only update state if the order actually changes
		if (JSON.stringify(newOrder) !== JSON.stringify(initialCommentsOrder)) {
			setInitialCommentsOrder(newOrder);
		}
		return sorted;
	};

	let sortedComments = sortComments(comments);

	return (
		<div className={classes.commentsOuterContainer}>
			<div className={classes.commentsInnerContainer}>
				<Typography gutterBottom variant="h6">
					Комментарии
				</Typography>
				{comments && sortedComments.length !== 0 && (
					<CommentSort sortType={sortType} setSortType={setSortType} />
				)}
				{comments ? (
					sortedComments.length === 0 ? (
						<Typography>Комментариев пока нет</Typography>
					) : (
						sortedComments.map((c, i) => (
							<div key={i}>
								<CommentBox
									classes={classes}
									c={c}
									i={i}
									user={user}
									handleLike={handleLike}
									toggleCommentResponse={toggleCommentResponse}
									type={type}
									id={id}
									setComments={setComments}
									initialCommentsOrder={initialCommentsOrder}
								/>
								{sortedComments[i].subcomments
									? sortedComments[i].subcomments.map((c, a) => (
											<>
												<ResponseBox
													classes={classes}
													c={c}
													i={i}
													a={a}
													toggleCommentResponse={toggleCommentResponse}
													user={user}
												/>
												{numberResponsePost === i &&
												numberResponseResponse === a &&
												isResponseToResponse === true ? (
													<ResponseWrite
														classes={classes}
														c={c}
														response={response}
														setResponse={setResponse}
														handleResponse={handleResponse}
														i={i}
													/>
												) : null}
											</>
									  ))
									: null}
								{numberResponsePost === i && isResponseToResponse === false ? (
									<ResponseWrite
										classes={classes}
										c={c}
										response={response}
										setResponse={setResponse}
										handleResponse={handleResponse}
										i={i}
									/>
								) : null}
							</div>
						))
					)
				) : (
					<CommentSkeleton />
				)}
				<div ref={commentsRef} />
			</div>
			{user?.result?.name && (
				<CommentWrite
					comment={comment}
					setComment={setComment}
					handleClick={handleClick}
					classes={classes}
				/>
			)}
		</div>
	);
};

export default Comment;
