import React, { useState } from "react";
import {
	Typography,
	IconButton,
	Collapse,
	Button,
	Divider,
} from "@material-ui/core";
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import moment from "moment";
import TransportIcon from "./TransportIcon";
import useStyles from "./styles";
import SettingsIcon from "@material-ui/icons/Settings";

const ArrivalDetails = ({
	arrival,
	trip,
	index,
	handleOpenModal,
	checkUser,
}) => {
	const classes = useStyles();
	const [expanded, setExpanded] = useState(false);

	if (!arrival) return null;

	const formattedDepartureTime = arrival?.departureTime
		? moment(arrival.departureTime).format("DD MMMM YYYY, HH:mm")
		: "Не указано";

	const formattedArrivalTime = arrival?.arrivalTime
		? moment(arrival?.arrivalTime).format("DD MMMM YYYY, HH:mm")
		: "Не указано";

	return (
		<>
			<div className={classes.arrivalBlock}>
				<div>
					<div
						className={classes.stayHeader} // Reusing style for a similar look
						onClick={() => setExpanded(!expanded)}
					>
						<div className={classes.stayFlex}>
							<Typography className={classes.arrivalBlockIcon}>
								<TransportIcon type={arrival?.type} />
							</Typography>
							<Typography variant="subtitle1">
								<strong>Отправление:</strong> {formattedDepartureTime}
							</Typography>
							<Typography variant="subtitle1">
								<strong>Прибытие:</strong> {formattedArrivalTime}
							</Typography>
							<IconButton size="small">
								{expanded ? <ExpandLess /> : <ExpandMore />}
							</IconButton>
							{checkUser && (
								<Button
									onClick={() => handleOpenModal(index)}
									className={classes.buttonClear}
								>
									<SettingsIcon />
								</Button>
							)}
						</div>
					</div>

					<Collapse in={expanded} timeout="auto" unmountOnExit>
						<div className={classes.stayBlockText}>
							<Typography variant="body2">
								{arrival?.type === "plane" && arrival?.flightNumber && (
									<>
										✈️ <strong>Номер рейса:</strong> {arrival.flightNumber}
										<br />
									</>
								)}
								{arrival?.type === "train" && arrival?.trainNumber && (
									<>
										🚂 <strong>Номер поезда:</strong> {arrival.trainNumber}
										<br />
									</>
								)}
								{arrival?.type === "plane" && arrival?.departureAirport && (
									<>
										🛫 <strong>Аэропорт вылета:</strong>{" "}
										{arrival.departureAirport}
										<br />
									</>
								)}
								{arrival?.type === "train" && arrival?.departureStation && (
									<>
										🚉 <strong>Станция отправления:</strong>{" "}
										{arrival.departureStation}
										<br />
									</>
								)}
								{arrival?.type === "plane" && arrival?.arrivalAirport && (
									<>
										🛬 <strong>Аэропорт прилета:</strong>{" "}
										{arrival.arrivalAirport}
										<br />
									</>
								)}
								{arrival?.type === "train" && arrival?.arrivalStation && (
									<>
										도착 <strong>Станция прибытия:</strong>{" "}
										{arrival.arrivalStation}
										<br />
									</>
								)}
							</Typography>
						</div>
					</Collapse>
				</div>
				<div>
					<Typography>
						💰 Стоимость одного билета:{" "}
						{arrival?.totalCost && arrival?.numberOfPeople
							? (arrival.totalCost / arrival.numberOfPeople).toLocaleString(
									"ru-RU"
							  )
							: ""}{" "}
						{arrival?.currency}
					</Typography>
					<Typography className={classes.totalCost}>
						💰 Стоимость всех билетов:{" "}
						{arrival?.totalCost.toLocaleString("ru-RU")} {arrival?.currency}
					</Typography>
				</div>
			</div>
			{index !== trip.cities.length && <Divider className={classes.divider} />}
		</>
	);
};

export default ArrivalDetails;
