import React from "react";
import { MenuItem, Select, Typography } from "@material-ui/core";
import useStyles from "./styles.js";


const CommentSort = ({ sortType, setSortType }) => {
	const classes = useStyles();

	return (
		<div className={classes.container}>
			<Typography className={classes.label}>Сортировать:</Typography>
			<Select
				value={sortType}
				onChange={(e) => setSortType(e.target.value)}
				className={classes.select}
				disableUnderline
			>
				<MenuItem value="latest">Сначала новые</MenuItem>
				<MenuItem value="oldest">Сначала старые</MenuItem>
				<MenuItem value="mostLikes">Сначала популярные</MenuItem>
			</Select>
		</div>
	);
};

export default CommentSort;
