import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Card, Button, Typography, CircularProgress } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import Post from "../Posts/Post/Post";
import useStyles from "./styles";
import { getRandomPost } from "../../actions/main";
import ReportCardSkeleton from "../Posts/Post/ReportCardSkeleton";

const MainPage = ({ setCurrentId }) => {
	const post = useSelector((state) => state.main.randomPost);
	const classes = useStyles();
	const dispatch = useDispatch();
	const isLoading = useSelector((state) => state.main.isLoading);

	useEffect(() => {
		dispatch(getRandomPost());
	}, []);

	const [betaClose, setBetaClose] = useState(false);

	return (
		<div>
			<Helmet>
				<title>Сайт о путешествиях - ToTravelRu</title>
				<meta
					name="description"
					content="Сайт с отчетами о путешествиях, где любой желающий может поделиться своим опытом поездок по всему миру. Отчеты о поездках в такие страны, как Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия - ToTravelRu"
				/>
				<meta
					name="keywords"
					content="отчёты, отчеты, путешествия, отчёты о путешествиях, travel, totravel, ToTravelRu, посты, Турция, Китай, Чили, Аргентина, Бразилия, Уругвай, Индия"
				/>
			</Helmet>
			{betaClose ? null : (
				<div className={classes.betaTest}>
					<Typography>
						Сайт находится в режиме тестирования. Если у вас есть предложения по
						улучшению сайта или вы нашли ошибку, напишите нам в Telegram
						<a
							className={classes.telegramBotLink}
							href="https://t.me/totravelrubot"
							target="_blank"
						>
							{" "}
							@totravelrubot
						</a>
					</Typography>

					<Button
						className={classes.betaTestButton}
						onClick={() => setBetaClose(!betaClose)}
					>
						<CloseIcon />
					</Button>
				</div>
			)}
			<Card
				className={`${classes.card} ${classes.cardFirst}`}
				raised
				elevation={6}
			>
				<div className={classes.welcome}>
					<Typography className={classes.welcomeTitle}>
						Добро пожаловать на сайт с отчетами о путешествиях
					</Typography>
					<Typography className={classes.welcomeText}>
						Здесь вы можете прочитать и написать отчеты о путешествиях в разные
						страны и регионы мира
					</Typography>
					<Button
						className={`${classes.card} ${classes.cardThird}`}
						raised
						elevation={6}
						href="/posts"
					>
						<Typography className={classes.titleSupport}>
							Посмотреть отчеты
						</Typography>
					</Button>
				</div>
				<div className={classes.samplePost}>
					{isLoading ? (
						<ReportCardSkeleton />
					) : (
						<Post post={post} setCurrentId={setCurrentId}></Post>
					)}
				</div>
			</Card>
			{/**<Grid
        container
        justifyContent="space-between"
        alignItems="stretch"
        spacing={2}
        className={classes.gridContainer}
      >
        <Grid item xs={12} sm={6} md={6}>
          <Button
            className={`${classes.card} ${classes.cardSecond}`}
            raised
            elevation={6}
            href="/support"
          >
            <Typography className={classes.titleSupport}>
              Поддержать авторов проекта
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <Button
            className={`${classes.card} ${classes.cardThird}`}
            raised
            elevation={6}
            href="/posts"
          >
            <Typography className={classes.titleSupport}>
              Посмотреть отчеты
            </Typography>
          </Button>
      </Grid>
      </Grid>**/}
		</div>
	);
};

export default MainPage;
