// src/components/ImageMenu/ImageMenu.jsx
import React, { useState } from "react";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import { MoreVert } from "@material-ui/icons";
import AddClaim from "../AddClaim/AddClaim";
import useStyles from "./styles";

const ImageMenu = ({
	imageUrl,
	placeId,
	userId,
	reportId,
	postId,
	reportTitle,
}) => {
	const classes = useStyles();

	const [anchorEl, setAnchorEl] = useState(null);
	const [reportOpen, setReportOpen] = useState(false);

	const handleMenuClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleCopyUrl = () => {
		navigator.clipboard.writeText(imageUrl).then(() => {
			alert("URL скопирован");
		});
		setAnchorEl(null);
	};

	const handleReport = () => {
		setReportOpen(true);
		setAnchorEl(null);
	};

	return (
		<>
			<IconButton className={classes.menuButton} onClick={handleMenuClick}>
				<MoreVert />
			</IconButton>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleMenuClose}
			>
				<MenuItem onClick={handleCopyUrl}>Скопировать URL фото</MenuItem>
				<MenuItem onClick={handleReport}>
					{reportTitle ? "Пожаловаться на изображение" : "Пожаловаться"}
				</MenuItem>
			</Menu>

			{reportOpen && (
				<AddClaim
					setReportOpen={setReportOpen}
					photoUrl={imageUrl}
					placeId={placeId}
					userId={userId}
					reportId={reportId}
					postId={postId}
				/>
			)}
		</>
	);
};

export default ImageMenu;
