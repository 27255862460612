import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useStyles from "./styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";

const ProfilePeopleSkeleton = () => {
	const classes = useStyles();

	return (
		<div className={classes.root}>
			<div className={classes.titleDiv}>
				<Typography variant="h6" className={classes.title}>
					<Skeleton width="30%" />
				</Typography>
			</div>
			<Grid container spacing={1}>
				{[...Array(6)].map((_, index) => (
					<Grid item xs={4} key={index}>
						<div className={classes.subscriberLink}>
							<Skeleton
								variant="circle"
								width={40}
								height={40}
								className={classes.avatar}
							/>
							<Typography className={classes.subscriberName}>
								<Skeleton width="60%" style={{ margin: "0 auto" }} />
							</Typography>
						</div>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default ProfilePeopleSkeleton;
