import React, { useState } from "react";
import { Typography, Select, MenuItem, TextField } from "@material-ui/core";
import useStyles from "./styles";
import { Autocomplete } from "@material-ui/lab";

const TripTotalCost = ({
	trip,
	selectedCurrency,
	setSelectedCurrency,
	currencyOptions,
	exchangeRates,
	checkUser,
}) => {
	const classes = useStyles();

	const calculateTotalCost = () => {
		const totalCostByCurrency = {};

		trip.cities.forEach((city) => {
			if (city.arrival?.totalCost && city.arrival?.currency) {
				const { totalCost, currency } = city.arrival;
				totalCostByCurrency[currency] =
					(totalCostByCurrency[currency] || 0) + parseFloat(totalCost);
			}

			if (city.stay?.totalCost && city.stay?.currency) {
				const { totalCost, currency } = city.stay;
				totalCostByCurrency[currency] =
					(totalCostByCurrency[currency] || 0) + parseFloat(totalCost);
			}

			if (city.activities) {
				city.activities.forEach((activity) => {
					if (activity.totalCost && activity.currency) {
						const { totalCost, currency } = activity;
						totalCostByCurrency[currency] =
							(totalCostByCurrency[currency] || 0) + parseFloat(totalCost);
					}
				});
			}
		});

		if (
			trip?.endPlace?.arrival?.totalCost &&
			trip?.endPlace?.arrival?.currency
		) {
			const { totalCost, currency } = trip?.endPlace?.arrival;
			totalCostByCurrency[currency] =
				(totalCostByCurrency[currency] || 0) + parseFloat(totalCost);
		}

		return totalCostByCurrency;
	};

	const calculateTotalCostByCurrency = () => {
		if (!trip || !exchangeRates[selectedCurrency]) return "Загрузка...";

		let totalCostInBase = 0;

		trip.cities.forEach((city) => {
			if (city.arrival?.totalCost && city.arrival?.currency) {
				const { totalCost, currency } = city.arrival;
				totalCostInBase += totalCost / (exchangeRates[currency] || 1);
			}
			if (city.stay?.totalCost && city.stay?.currency) {
				const { totalCost, currency } = city.stay;
				totalCostInBase += totalCost / (exchangeRates[currency] || 1);
			}
			if (city?.activities) {
				city.activities.forEach((activity) => {
					if (activity.totalCost && activity.currency) {
						const { totalCost, currency } = activity;
						totalCostInBase += totalCost / (exchangeRates[currency] || 1);
					}
				});
			}
		});

		if (
			trip?.endPlace?.arrival?.totalCost &&
			trip?.endPlace?.arrival?.currency
		) {
			const { totalCost, currency } = trip?.endPlace?.arrival;
			totalCostInBase += totalCost / (exchangeRates[currency] || 1);
		}

		return (totalCostInBase * exchangeRates[selectedCurrency]).toLocaleString(
			"ru-RU",
			{ minimumFractionDigits: 2, maximumFractionDigits: 2 }
		);
	};

	const totalCost = calculateTotalCost();

	return (
		<>
			{totalCost && Object.values(totalCost).some((amount) => amount > 0) ? (
				<Typography>
					Стоимость поездки в разных валютах:
					{Object.entries(totalCost).map(([currency, amount]) => (
						<span key={currency}>{` ${amount.toLocaleString(
							"ru-RU"
						)} ${currency}`}</span>
					))}
				</Typography>
			) : (
				<Typography>
					Затраты не указаны
					{checkUser &&
						`. Добавьте перелеты, проживание или активности, чтобы увидеть статистику`}
				</Typography>
			)}
			{totalCost && Object.values(totalCost).some((amount) => amount > 0) && (
				<div className={classes.totalCostCurrencyBlock}>
					<Typography>Общая стоимость поездки в</Typography>
					<Autocomplete
						freeSolo // Allows manual input
						options={currencyOptions} // List of available currencies
						value={selectedCurrency}
						onChange={(event, newValue) => setSelectedCurrency(newValue)}
						renderInput={(params) => (
							<TextField className={classes.currencyInputText} {...params} />
						)}
					/>
					<Typography>
						по состоянию на {new Date().toLocaleDateString()}:
					</Typography>
					<Typography>
						{calculateTotalCostByCurrency()} {selectedCurrency}
					</Typography>
				</div>
			)}
		</>
	);
};

export default TripTotalCost;
