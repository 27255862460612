import React, { useState, useEffect, useMemo } from "react";
import useStyles from "./styles.js";
import {
	TextField,
	Button,
	Typography,
	Paper,
	Link,
	CircularProgress,
} from "@material-ui/core";
import { useSelector } from "react-redux";
import FileBase from "react-file-base64";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { createPost, updatePost } from "../../actions/posts.js";
import { addYandexImageReport } from "../../actions/posts";
import Select from "react-select";
import countryList from "react-select-country-list";
import PublishIcon from "@material-ui/icons/Publish";
import Resizer from "react-image-file-resizer";

const Form = ({ user, currentId, setCurrentId }) => {
	const post = useSelector((state) =>
		currentId ? state.posts.posts.find((p) => p._id === currentId) : null
	);
	const isLoadingPhoto = useSelector((state) => state.posts.isLoadingPhoto);
	const [postData, setPostData] = useState({
		title: "",
		message: "",
		tags: "",
		selectedFile: "",
	});
	const classes = useStyles();
	const dispatch = useDispatch();
	const history = useHistory();
	const options = useMemo(() => countryList().getData(), []);
	const [country, setCountry] = useState("");
	const [fileName, setFileName] = useState("");
	const addCountries = (country) => {
		setCountry(country);
		let countryArr = country.map((c) => (c = c.label));
		setPostData({ ...postData, tags: countryArr });
	};

	const resizeFile = (file) =>
		new Promise((resolve) => {
			Resizer.imageFileResizer(
				file,
				1000,
				1000,
				"JPEG",
				80,
				0,
				(uri) => {
					resolve(uri);
				},
				"base64"
			);
		});

	useEffect(() => {
		if (post) setPostData(post);
	}, [post]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (currentId) {
			dispatch(
				updatePost(currentId, { ...postData, name: user?.result?.name })
			);
		} else {
			dispatch(createPost({ ...postData, name: user?.result?.name }, history));
		}
		clear();
	};

	if (!user?.result?.name) {
		return (
			<Paper className={classes.paper} elevation={0}>
				<Typography variant="h6" align="center">
					Войдите, чтобы написать отчет
				</Typography>
			</Paper>
		);
	}

	const clear = () => {
		setCurrentId(null);
		setPostData({
			title: "",
			message: "",
			tags: "",
			selectedFile: "",
		});
	};

	return (
		<Paper className={classes.paper} elevation={0}>
			<form
				autoComplete="off"
				noValidate
				className={`${classes.root} ${classes.form}`}
				onSubmit={handleSubmit}
			>
				<Typography variant="h6">
					{currentId ? "Изменить" : "Написать"} отчет
				</Typography>
				<TextField
					name="title"
					variant="outlined"
					label="Заголовок*"
					fullWidth
					value={postData.title}
					className={classes.formType}
					inputProps={{
						maxLength: 100,
					}}
					onChange={(e) => setPostData({ ...postData, title: e.target.value })}
				/>
				<TextField
					name="message"
					variant="outlined"
					label="Пару слов об отчёте"
					fullWidth
					multiline
					maxRows={5}
					value={postData.message}
					className={classes.formType}
					inputProps={{
						maxLength: 1000,
					}}
					onChange={(e) =>
						setPostData({ ...postData, message: e.target.value })
					}
				/>
				<Select
					options={options}
					value={country}
					onChange={addCountries}
					styles={{
						container: (styles) => ({
							...styles,
							width: "100%",
							minHeight: "56px",
							marginTop: "8px",
						}),
						control: (styles) => ({
							...styles,
							fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							minHeight: "56px",
						}),
						option: (styles) => {
							return {
								...styles,
								fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
							};
						},
					}}
					isMulti
					placeholder="Выберите страну или страны*"
				/>
				<div className={classes.fileInput}>
					<label className={classes.labelInput}>
						<input
							type="file"
							className={classes.imageInput}
							onChange={async (event) => {
								try {
									const file = event.target.files[0];
									const fileNameInput = event.target.files[0].name;
									const image = await resizeFile(file);
									let yandexImage = await dispatch(addYandexImageReport(image));

									setPostData({ ...postData, selectedFile: yandexImage });
									setFileName(fileNameInput);
								} catch (err) {
									console.log(err);
								}
							}}
						/>
						{isLoadingPhoto ? null : <PublishIcon />}
						{isLoadingPhoto ? (
							<CircularProgress
								style={{ color: "white", margin: "0 auto" }}
								size="1rem"
							/>
						) : (
							<Typography className={classes.uploadText}>
								ЗАГРУЗИТЬ ФОТО
							</Typography>
						)}
					</label>
					{fileName ? (
						<div className={classes.fileNameBox}>
							<Typography className={classes.fileText}>
								{fileName ? fileName : null}
							</Typography>
						</div>
					) : null}
				</div>
				<Button
					className={classes.buttonPrimary}
					variant="contained"
					disabled={isLoadingPhoto || !postData?.title || !postData?.tags}
					size="large"
					type="submit"
					fullWidth
				>
					{currentId ? "Изменить" : "Написать"} отчет
				</Button>
				<Button
					className={classes.buttonSecondary}
					variant="contained"
					size="small"
					onClick={clear}
					fullWidth
				>
					Очистить поля
				</Button>
			</form>
		</Paper>
	);
};

export default Form;
