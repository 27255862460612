import React from "react";
import { Avatar, Button, Card, Typography, Box } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuBookIcon from "@material-ui/icons/MenuBook";
import KeyboardIcon from "@material-ui/icons/Keyboard";
import ThumbUpAltOutlined from "@material-ui/icons/ThumbUpAltOutlined";

import useStyles from "../styles";
import ProfileHeaderSkeleton from "./ProfileHeaderSkeleton";

const ProfileHeader = ({ user, userCurrent, isLoadingUser, changeProfile }) => {
	const classes = useStyles();

	if (isLoadingUser) {
		return <ProfileHeaderSkeleton />;
	}

	if (!user) {
		return (
			<Card className={classes.card}>
				<Typography variant="h6" align="center">
					Пользователь не найден
				</Typography>
			</Card>
		);
	}

	const userTypeLabels = {
		admin: "Администратор",
		sponsor: "Спонсор",
		traveler: "Путешественник",
		author: "Автор",
	};

	return (
		<Card className={classes.card}>
			<div className={classes.photoName}>
				<Avatar
					className={classes.profilePicture}
					alt={user.name}
					src={user.picture}
				>
					{user.name?.charAt(0)}
				</Avatar>
				<Typography variant="h5">{user.name}</Typography>
				{userTypeLabels[user.userType] && (
					<Typography className={classes.adminSponsorLabel}>
						{userTypeLabels[user.userType]}
					</Typography>
				)}
				{user?._id === userCurrent?.result?._id && (
					<Button
						variant="contained"
						className={classes.buttonPrimary}
						onClick={changeProfile}
					>
						Изменить профиль
					</Button>
				)}
			</div>
			<hr className={classes.divider} />
			<div className={classes.infoProfile}>
				<div className={classes.descWrap}>
					<MenuBookIcon className={classes.logo} />
					<Typography className={classes.descText}>Отчеты</Typography>
					<Box className={classes.userStatWrap}>
						<Typography>{user ? user?.reportsId.length : null}</Typography>
					</Box>
				</div>
				<div className={classes.descWrap}>
					<KeyboardIcon className={classes.logo} />
					<Typography className={classes.descText}>Посты</Typography>
					<Box className={classes.userStatWrap}>
						<Typography>{user?.postsNumber}</Typography>
					</Box>
				</div>
				<div className={classes.descWrap}>
					<ThumbUpAltOutlined fontSize="small" className={classes.logo} />
					<Typography className={classes.descText}>Лайки</Typography>
					<Box className={classes.userStatWrap}>
						<Typography>{user?.likesNumber}</Typography>
					</Box>
				</div>
			</div>
		</Card>
	);
};

export default ProfileHeader;
