// styles.js
import { makeStyles } from "@material-ui/core/styles";

const primaryColor = "rgb(236, 98, 98)";
const primaryLight = "rgb(255, 153, 153)";
const primaryDark = "rgb(204, 41, 41)";
const secondaryColor = "rgb(255, 245, 230)";
const textColorPrimary = "rgb(48, 48, 48)";
const textColorSecondary = "rgb(117, 117, 117)";
const backgroundColorDefault = "rgb(255, 255, 255)";
const dividerColor = "rgb(224, 224, 224)";
const successColor = "rgb(76, 175, 80)";
const errorColor = "rgb(244, 67, 54)";

const useStyles = makeStyles((theme) => ({
	landingContainer: {
		padding: theme.spacing(4),
		margin: theme.spacing(3),
		[theme.breakpoints.down("sm")]: {
			padding: theme.spacing(1.5),
			margin: 0,
		},
	},
	sectionTitle: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(2),
	},
	featureSection: {
		marginBottom: theme.spacing(3),
		padding: theme.spacing(2),
		border: `1px solid ${dividerColor}`,
		borderRadius: theme.shape.borderRadius,
		backgroundColor: backgroundColorDefault,
		"& button": {
			marginTop: theme.spacing(1),
			backgroundColor: primaryColor,
			color: "#fff",
			"&:hover": {
				backgroundColor: primaryLight,
			},
		},
	},
	benefitsSection: {
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
	},
	benefit: {
		display: "flex",
		alignItems: "center",
		marginBottom: theme.spacing(2),
		"& svg": {
			marginRight: theme.spacing(2),
		},
	},
	screenshotPlaceholder: {
		//margin: theme.spacing(1, 0, 2, 4),
		padding: theme.spacing(0.5),
		margin: "0 auto",
		//border: `1px dashed ${textColorSecondary}`,
		width: "fit-content",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: "rgba(236, 98, 98, 0.05)",
		"& > p": {
			textAlign: "center",
			color: textColorSecondary,
		},
	},
	profileButton: {
		marginTop: theme.spacing(2),
		color: primaryColor,
		borderColor: primaryColor,
		"&:hover": {
			backgroundColor: "rgba(236, 98, 98, 0.1)",
			borderColor: primaryDark,
		},
	},
	note: {
		marginTop: theme.spacing(4),
		display: "block",
		textAlign: "center",
		color: textColorSecondary,
	},
	screenshot: {
		margin: "0 auto",
		maxWidth: "100%",
	},
}));

export default useStyles;
