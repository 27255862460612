import React, { useRef, useEffect } from "react";
import { Typography, Button, Card, Link } from "@material-ui/core";

import useStyles from "./styles";

const PostLegend = ({ legendProps, closeLegend }) => {
	const classes = useStyles();
	const legendRef = useRef();

	useEffect(() => {
		const handleClickOutside = (event) => {
			if (legendRef.current && !legendRef.current.contains(event.target)) {
				closeLegend();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [closeLegend]);

	return (
		<Card ref={legendRef} className={classes.legend}>
			<Button
				className={classes.numberLinkTitle}
				href={`/posts/${legendProps?.postContentFull[0].reportId}`}
			>
				<div className={classes.legendTitle}>Вернуться в отчет</div>
			</Button>
			{legendProps?.postsInfo.map((c, i) => (
				<Card className={classes.postsLinkContainer}>
					<Button className={classes.numberLink} href={`/post/${c._id}`}>
						<div className={classes.numberContainer}>
							<Typography className={classes.numberText}>{i + 1}</Typography>
						</div>
						<div className={classes.linkList}>{c.title}</div>
					</Button>
				</Card>
			))}
		</Card>
	);
};

export default PostLegend;
