import React from "react";
import { Paper, Typography, Grid } from "@material-ui/core";
import Post from "../../Posts/Post/Post.js";

const ReportRecommendedReports = ({ recommendedPosts, classes }) => {
	if (!recommendedPosts.length) return null;

	return (
		<Paper className={classes.paperPost}>
			<div className={classes.section}>
				<Typography gutterBottom variant="h5">
					Вам также могут понравиться другие отчеты
				</Typography>
				<Grid
					className={classes.container}
					container
					alignItems="stretch"
					spacing={3}
				>
					{recommendedPosts.map((post) => (
						<Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
							<Post post={post} />
						</Grid>
					))}
				</Grid>
			</div>
		</Paper>
	);
};

export default ReportRecommendedReports;
