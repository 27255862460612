import React from "react";
import { Pagination } from "@material-ui/lab";
import { Skeleton } from "@material-ui/lab";
import useStyles from "./styles";

const PaginationProfileSkeleton = () => {
	const classes = useStyles();

	return (
		<Pagination
			classes={{ ul: classes.ul }}
			count={3}
			variant="outlined"
			color="secondary"
			renderItem={(item) => (
				<Skeleton
					variant="rect"
					width={40}
					height={40}
					style={{ margin: "0 4px" }}
				/>
			)}
		/>
	);
};

export default PaginationProfileSkeleton;
