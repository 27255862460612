import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "./styles.js";

const PostReportCardLoading = () => {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardContent className={classes.cardContent}>
				<div className={classes.postContent}>
					<div className={classes.postContentBox}>
						<Skeleton
							variant="rect"
							width="100%"
							height={220}
							className={classes.previewImage}
						/>
						<Skeleton
							variant="text"
							width="50%"
							className={classes.titleText}
						/>
						<Skeleton
							variant="text"
							width="60%"
							className={classes.creatorText}
						/>
						<Skeleton
							variant="text"
							width="40%"
							className={classes.usernameText}
						/>
						<div className={classes.infoBox}>
							<Skeleton
								variant="text"
								width="30%"
								className={classes.timeText}
							/>
							<div className={classes.likesVisitedBox}>
								<div className={classes.likesBox}>
									<Skeleton
										variant="text"
										width="50px"
										className={classes.likesNumber}
									/>
									<Skeleton
										variant="circle"
										width={24}
										height={24}
										className={classes.icon}
									/>
								</div>
								<div className={classes.visibility}>
									<Skeleton
										variant="text"
										width="50px"
										className={classes.visibilityCount}
									/>
									<Skeleton
										variant="circle"
										width={24}
										height={24}
										className={classes.icon}
									/>
								</div>
							</div>
						</div>
					</div>
				</div>
			</CardContent>
		</Card>
	);
};

export default PostReportCardLoading;
