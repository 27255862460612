import React from "react";
import { Avatar, Box, Button, Grid, Typography } from "@material-ui/core";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { useHistory } from "react-router-dom";
import useStyles from "./styles";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProfilePeople = ({ list, type, user, isLoadingUser }) => {
	const classes = useStyles();
	const history = useHistory();

	const handleClick = () => {
		const path =
			type === "subscribers"
				? `/user/${user?.login}/subscribers`
				: `/user/${user?.login}/friends`;
		history.push(path);
	};

	if (isLoadingUser) {
		return (
			<div className={classes.circularOther}>
				<CircularProgress color="secondary" />
			</div>
		);
	}

	if (list?.length === 0) {
		return (
			<div className={classes.rootNoFriends}>
				<Typography variant="h6" className={classes.titleNoFriends}>
					{type === "subscribers" ? "Подписчиков пока нет" : "Друзей пока нет"}
				</Typography>
			</div>
		);
	}

	return (
		<div className={classes.root}>
			<div className={classes.titleDiv}>
				<Typography variant="h6" className={classes.title}>
					{type === "subscribers" ? "Подписчики" : "Друзья"}
				</Typography>
				<Box className={classes.boxKeyboardIcon}>
					<Button onClick={handleClick}>
						<KeyboardArrowRightIcon className={classes.keyboardIcon} />
					</Button>
				</Box>
			</div>
			<Grid container spacing={1}>
				{list?.slice(0, 6).map((person, index) => (
					<Grid item xs={4} key={index}>
						<Link
							className={classes.subscriberLink}
							to={`/user/${person?.login}`}
						>
							<Avatar
								alt={person?.name}
								src={person?.picture}
								className={classes?.avatar}
							/>
							<Typography className={classes.subscriberName}>
								{person?.name?.split(" ")[0]}
							</Typography>
						</Link>
					</Grid>
				))}
			</Grid>
		</div>
	);
};

export default ProfilePeople;
