import { useDispatch } from "react-redux";
import { addCollaborator, removeCollaborator } from "../../../actions/trips";
import React, { useState, useEffect } from "react";
import {
	Typography,
	Button,
	TextField,
	IconButton,
	Avatar,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import { RemoveCircle } from "@material-ui/icons";

const TripCollaborators = ({ trip, collaborators, user, checkUser }) => {
	const dispatch = useDispatch();
	const classes = useStyles();

	const [friendLogin, setFriendLogin] = useState("");
	const [isAddCollaborator, setIsAddCollaborator] = useState(false);

	const handleAddCollaborator = () => {
		dispatch(addCollaborator(trip._id, friendLogin));
		setFriendLogin("");
	};

	const handleRemoveCollaborator = (userId) => {
		dispatch(removeCollaborator(trip._id, userId));
	};

	return (
		<div>
			<Typography variant="h6">Попутчики</Typography>

			<div className={classes.arrivalBlockText}>
				{collaborators.length === 0 && (
					<Typography>Попутчиков пока нет</Typography>
				)}
				{collaborators.map((collab) => (
					<>
						<Link className={classes.profileLink} to={`/user/${collab?.login}`}>
							<Avatar
								className={
									collab?.userType === undefined
										? classes.purple
										: classes.purpleSpecial
								}
								alt={collab?.name}
								src={collab?.picture}
							>
								{collab?.name.charAt(0)}
							</Avatar>
						</Link>
						{user?.result?._id === trip?.userId?._id && checkUser && (
							<IconButton onClick={() => handleRemoveCollaborator(collab)}>
								<RemoveCircle />
							</IconButton>
						)}
					</>
				))}

				{user?.result?._id === trip?.userId?._id && checkUser && (
					<Button onClick={() => setIsAddCollaborator((prev) => !prev)}>
						<Avatar>+</Avatar>
					</Button>
				)}
			</div>

			{isAddCollaborator && (
				<div className={classes.arrivalBlockText}>
					<TextField
						label="Логин попутчика"
						value={friendLogin}
						onChange={(e) => setFriendLogin(e.target.value)}
					/>
					<Button
						onClick={handleAddCollaborator}
						className={classes.buttonPrimary}
					>
						Добавить попутчика
					</Button>
				</div>
			)}
		</div>
	);
};

export default TripCollaborators;
