import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
	container: {
		maxWidth: 500,
		margin: "auto",
		padding: theme.spacing(3),
		backgroundColor: "#fff",
		borderRadius: "15px",
		borderColor: "rgba(236,98,98,1)",
		border: "solid 1px",
	},
	createTripTitle: {
		marginBottom: theme.spacing(1),
	},
	form: {
		display: "flex",
		flexDirection: "column",
		gap: theme.spacing(1),
	},
	error: {
		color: "red",
	},
	button: {
		marginTop: theme.spacing(2),
	},
	cityList: {
		marginTop: theme.spacing(2),
	},
	cityListItem: {
		padding: theme.spacing(1),
		width: "100%",
	},
	buttonPrimary: {
		marginTop: theme.spacing(2),
		borderRadius: "20px",
		backgroundColor: "rgba(236,98,98,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(213,72,72,1)",
		},
	},
	buttonSecondary: {
		marginTop: theme.spacing(2),
		borderRadius: "20px",
		backgroundColor: "rgba(70,70,70,1)",
		color: "rgba(255,255,255,1)",
		"&:hover, &:focus": {
			backgroundColor: "rgba(100,100,100,1)",
		},
	},
	createTripButtons: {
		display: "flex",
		gap: theme.spacing(0.5),
	},
	stepLabel: {
		"& .MuiStepIcon-active": {
			color: "rgb(236,98,98)", // Default color for step labels
		},
	},
	placeCard: {
		display: "flex",
		width: "inherit",
		alignItems: "center",
		justifyContent: "space-between",
		padding: "10px",
		marginTop: "10px",
		borderRadius: "10px",
		backgroundColor: "#f5f5f5",
		gap: theme.spacing(0.5),
	},
	placeCardCity: {
		display: "flex",
		width: "inherit",
		alignItems: "center",
		padding: "10px",
		marginTop: "10px",
		borderRadius: "10px",
		backgroundColor: "#f5f5f5",
		gap: theme.spacing(0.5),
	},
	flex: {
		display: "flex",
		gap: theme.spacing(0.5),
		alignItems: "center",
	},
}));

export default useStyles;
