import React from "react";
import {
	CircularProgress,
	Card,
	CardContent,
	Typography,
	Avatar,
	Box,
	Grid,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from "@material-ui/core";
import useStyles from "./styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const PlannedTripsStatistics = ({ stats, handleToggle, expanded }) => {
	const classes = useStyles();

	return (
		<Box className={classes.statisticsContainer}>
			<Grid container spacing={1}>
				<Grid item xs={6} sm={3}>
					<Card className={classes.tripCard}>
						<CardContent>
							<Typography variant="h6">Всего поездок</Typography>
							<Typography variant="h4">{stats.totalTrips}</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Card
						className={classes.tripCard}
						onClick={handleToggle}
						style={{ cursor: "pointer" }}
					>
						<CardContent
							style={{
								display: "flex",
								alignItems: "center",
								justifyContent: "space-between",
							}}
						>
							<div>
								<Typography variant="h6">Страны</Typography>
								<Typography variant="h4">{stats.totalCountries}</Typography>
							</div>
							<ExpandMoreIcon
								style={{
									transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
									transition: "transform 0.3s",
								}}
							/>
						</CardContent>
					</Card>
					<ExpansionPanel
						expanded={expanded}
						onChange={handleToggle}
						style={{ boxShadow: "none" }}
					>
						<ExpansionPanelSummary
							expandIcon={<ExpandMoreIcon />}
							style={{ display: "none" }}
						/>
						<Card className={classes.tripCard}>
							<CardContent>
								{stats?.uniqueCountriesArr.map((country, index) => (
									<Typography key={index}>{country}</Typography>
								))}
							</CardContent>
						</Card>
					</ExpansionPanel>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Card className={classes.tripCard}>
						<CardContent>
							<Typography variant="h6">Города</Typography>
							<Typography variant="h4">{stats.totalCities}</Typography>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6} sm={3}>
					<Card className={classes.tripCard}>
						<CardContent>
							<Typography variant="h6">Дней в поездках</Typography>
							<Typography variant="h4">{stats.totalDays}</Typography>
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Box>
	);
};

export default PlannedTripsStatistics;
