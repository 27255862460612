import React from "react";
import { Typography, Divider, Button } from "@material-ui/core";
import moment from "moment";
import useStyles from "./styles";
import SettingsIcon from "@material-ui/icons/Settings";

const Activity = ({
	activities,
	selectedDate,
	period,
	checkUser,
	handleOpenDialog,
}) => {
	const classes = useStyles();

	const filteredActivities = activities.filter(
		(activity) =>
			moment(activity.date).format("YYYY-MM-DD") === selectedDate &&
			activity.timePeriod === period
	);

	const initialIndexes = filteredActivities.map((activity) =>
		activities.findIndex((originalActivity) => originalActivity === activity)
	);

	return (
		<div className={classes.activitiesContainer}>
			{filteredActivities.length > 0 ? (
				filteredActivities.map((activity, index) => (
					<>
						<div className={classes.activityFlex}>
							<Typography key={index} variant="body2">
								📌 {activity.name} ({activity.startTime} - {activity.endTime}){" "}
								{activity.location && (
									<>
										<br />
										📍{" "}
										<a
											href={activity.location}
											target="_blank"
											rel="noopener noreferrer"
										>
											Ссылка на карту
										</a>
									</>
								)}
								{activity.website && (
									<>
										<br /> 🔗{" "}
										<a
											href={activity.website}
											target="_blank"
											rel="noopener noreferrer"
										>
											Сайт
										</a>
									</>
								)}
								{activity.totalCost > 0 && (
									<>
										<br />
										💰 {activity.totalCost} {activity.currency}
									</>
								)}{" "}
								{activity.notes && (
									<>
										<br />
										📝 {activity.notes}
									</>
								)}
							</Typography>
							{checkUser && (
								<Button
									onClick={() =>
										handleOpenDialog(
											period,
											"edit",
											initialIndexes[index],
											activity
										)
									}
									className={classes.buttonClear}
								>
									<SettingsIcon />
								</Button>
							)}
						</div>
						{index !== filteredActivities.length - 1 && (
							<Divider className={classes.divider} />
						)}
					</>
				))
			) : (
				<Typography variant="body2">Нет запланированных активностей</Typography>
			)}
		</div>
	);
};

export default Activity;
