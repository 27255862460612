import React from "react";
import { Typography, CircularProgress, Grid } from "@material-ui/core";
import useStyles from "./styles.js";
import { translatePlaceTypePlural } from "../../../utils/placeUtils";
import PlaceCard from "./PlaceCityToDo/PlacesInCityList/PlaceCard/PlaceCard.jsx";

const NearestPlaces = ({ place, nearestPlaces, isLoaadingNearestPlaces }) => {
	const classes = useStyles();

	return (
		<>
			<Typography variant="h6" gutterBottom>
				Ближайшие {translatePlaceTypePlural(place?.placeType).toLowerCase()}
			</Typography>
			{nearestPlaces && nearestPlaces.length > 1 ? (
				isLoaadingNearestPlaces === true ? (
					<CircularProgress size="5rem" color="secondary" />
				) : (
					<Grid container spacing={2}>
						{nearestPlaces.slice(1, 7).map(
							(
								p,
								index // Exclude the first element and limit to 6 elements
							) => (
								<Grid key={index} item xs={12} sm={12} md={6} lg={6}>
									<PlaceCard p={p} />
								</Grid>
							)
						)}
					</Grid>
				)
			) : (
				<Typography>Места не найдены</Typography>
			)}
		</>
	);
};

export default NearestPlaces;
