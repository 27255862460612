// src/components/Profile/ProfileReportsSkeleton.jsx

import React from "react";
import { Card, Typography, Grid } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import useStyles from "../styles";

const ProfileReportsSkeleton = () => {
	const classes = useStyles();

	return (
		<Card className={classes.cardPost}>
			<Typography variant="h5" className={classes.reportsTitle}>
				<Skeleton width="50%" />
			</Typography>
			<div className={classes.reportsBox}>
				<div className={classes.circularProfile}>
					<Grid
						container
						justifyContent="space-evenly"
						alignItems="stretch"
						spacing={3}
						className={classes.gridContainerPosts}
					>
						{[...Array(3)].map((_, index) => (
							<Grid key={index} item xs={12} sm={12} md={6} lg={4}>
								<Skeleton variant="rect" height={150} />
								<Skeleton width="80%" style={{ marginTop: 8 }} />
								<Skeleton width="60%" style={{ marginTop: 4 }} />
							</Grid>
						))}
					</Grid>
				</div>
			</div>
		</Card>
	);
};

export default ProfileReportsSkeleton;
