import React, { useState } from "react";
import { TextField, Button, Grid, Typography } from "@material-ui/core";
import { useDispatch } from "react-redux";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import Quill styles
import SearchGeo from "../../../Maps/SelectGeo/SearchGeo/SearchGeo";
import { addHowToGet } from "../../../../actions/placeDescription";
import HowToGet from "./HowToGet";

const SuggestHowToGet = ({ place, userId, setShowDetails }) => {
	const dispatch = useDispatch();
	const [descriptionHowToGet, setDescriptionHowToGet] = useState(
		place?.howToGet?.descriptionHowToGet
			? place?.howToGet?.descriptionHowToGet
			: ""
	);
	const [avia, setAvia] = useState(
		place?.howToGet?.avia ? place?.howToGet?.avia : ""
	);
	const [train, setTrain] = useState(
		place?.howToGet?.train ? place?.howToGet?.train : ""
	);
	const [sea, setSea] = useState(
		place?.howToGet?.sea ? place?.howToGet?.sea : ""
	);
	const [bus, setBus] = useState(
		place?.howToGet?.bus ? place?.howToGet?.bus : ""
	);
	const [road, setRoad] = useState(
		place?.howToGet?.road ? place?.howToGet?.road : ""
	);
	const [nearAvia, setNearAvia] = useState(
		place?.howToGet?.nearAvia ? place?.howToGet?.nearAvia : []
	);
	const [nearTrain, setNearTrain] = useState(
		place?.howToGet?.nearTrain ? place?.howToGet?.nearTrain : []
	);

	const [airportsToShow, setAirportsToShow] = useState([]);
	const [railwayToShow, setRailwayToShow] = useState([]);

	const maxLength = 5000;

	const handleSelectedAvia = (selectedPlace) => {
		if (selectedPlace?._id) {
			if (!nearAvia.includes(selectedPlace._id)) {
				setNearAvia([...nearAvia, selectedPlace._id]);
				setAirportsToShow([...airportsToShow, selectedPlace]);
			}
		}
	};

	const handleSelectedRailway = (selectedPlace) => {
		if (selectedPlace?._id) {
			if (!nearAvia.includes(selectedPlace._id)) {
				setNearTrain([...nearTrain, selectedPlace._id]);
				setRailwayToShow([...railwayToShow, selectedPlace]);
			}
		}
	};

	const handleDescriptionChange = (value) => {
		if (value.length <= maxLength) {
			setDescriptionHowToGet(value);
		}
	};

	const handleAviaChange = (value) => {
		if (value.length <= maxLength) {
			setAvia(value);
		}
	};

	const handleTrainChange = (value) => {
		if (value.length <= maxLength) {
			setTrain(value);
		}
	};

	const handleSeaChange = (value) => {
		if (value.length <= maxLength) {
			setSea(value);
		}
	};

	const handleBusChange = (value) => {
		if (value.length <= maxLength) {
			setBus(value);
		}
	};

	const handleRoadChange = (value) => {
		if (value.length <= maxLength) {
			setRoad(value);
		}
	};

	const handleSubmit = async () => {
		const description = {
			placeId: place?._id,
			userId,
			howToGet: {
				descriptionHowToGet,
				avia,
				train,
				sea,
				bus,
				road,
				nearAvia,
				nearTrain,
			},
		};

		try {
			await dispatch(addHowToGet(description));
			setShowDetails(false);
		} catch (error) {
			console.error("Error while submitting place info:", error);
		}
	};

	return (
		<div>
			<Typography variant="h6">Как добраться до места</Typography>

			<Grid container spacing={2}>
				<Grid item xs={12}>
					<Typography>Описание (макс. 5000 символов)</Typography>
					<ReactQuill
						value={descriptionHowToGet}
						onChange={handleDescriptionChange}
					/>
				</Grid>

				<Grid item xs={12}>
					<Typography>На самолете (макс. 5000 символов)</Typography>
					<ReactQuill value={avia} onChange={handleAviaChange} />
				</Grid>
				<Grid item xs={12}>
					<Typography>Укажите ближайшие аэропорты</Typography>
					<SearchGeo onSelectedPlace={handleSelectedAvia} filter="airport" />
				</Grid>

				<Grid item xs={12}>
					<Typography>На поезде (макс. 5000 символов)</Typography>
					<ReactQuill value={train} onChange={handleTrainChange} />
				</Grid>
				<Grid item xs={12}>
					<Typography>Укажите ближайшие вокзалы</Typography>
					<SearchGeo onSelectedPlace={handleSelectedRailway} filter="railway" />
				</Grid>

				<Grid item xs={12}>
					<Typography>По морю (макс. 5000 символов)</Typography>
					<ReactQuill value={sea} onChange={handleSeaChange} />
				</Grid>

				<Grid item xs={12}>
					<Typography>На автобусе (макс. 5000 символов)</Typography>
					<ReactQuill value={bus} onChange={handleBusChange} />
				</Grid>

				<Grid item xs={12}>
					<Typography>На машине (макс. 5000 символов)</Typography>
					<ReactQuill value={road} onChange={handleRoadChange} />
				</Grid>

				<Grid item xs={12}>
					<Button variant="contained" color="primary" onClick={handleSubmit}>
						Отправить
					</Button>
				</Grid>
			</Grid>
		</div>
	);
};

export default SuggestHowToGet;
