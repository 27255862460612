// File: src/components/ReportTitle/ReportTitle.jsx
import React from "react";
import { Paper, Typography, Box } from "@material-ui/core";
import ImageMenu from "../../ImageMenu/ImageMenu"; // Import the new component

const ReportTitle = ({ post, classes, user }) => {
	return (
		<Paper className={classes.paperPost}>
			<div className={classes.card}>
				<div className={classes.section}>
					<Typography
						variant="h4"
						component="h2"
						className={classes.sectionTitle}
					>
						{post.title}
					</Typography>
					<Box className={classes.flexTagBox}>
						{post.tags.map((tag) => (
							<Box className={classes.tagBox} key={tag}>
								<Typography variant="subtitle2" component="h2">
									{tag}
								</Typography>
							</Box>
						))}
					</Box>
					<Typography
						gutterBottom
						variant="body1"
						component="p"
						className={classes.sectionMessage}
					>
						{post.message}
					</Typography>
				</div>
				<div className={classes.imageSection}>
					<Box position="relative" display="inline-block" width="100%">
						<img
							className={classes.media}
							src={
								post.selectedFile ||
								"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
							}
							alt={post.title}
							style={{ display: "block", width: "100%" }} // Make image take full width of container
						/>
						{post.selectedFile && (
							<ImageMenu
								imageUrl={post.selectedFile}
								// You might not have placeId and userId here,
								// so consider how you want to handle claims in this context.
								// You could pass them as props to ReportTitle if needed.
								reportId={post?._id}
								placeId={null}
								userId={user?.result?._id}
								reportTitle="Пожаловаться на изображение"
							/>
						)}
					</Box>
				</div>
			</div>
		</Paper>
	);
};

export default ReportTitle;
