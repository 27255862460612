import React, { useState, useEffect, useRef } from "react";
import { Helmet } from "react-helmet";
import { useSelector, useDispatch } from "react-redux";
import { YMaps, Map, Placemark, Clusterer } from "@pbe/react-yandex-maps";
import {
	getPlaces,
	getPlacesByBorders,
	getPlacesByIds,
} from "../../../actions/places.js";
import { PortalGeneral } from "../PortalGeneral/PortalGeneral";
import PlaceBox from "../ShowGeo/PlaceBox/PlaceBox.jsx";
import marker from "../../../images/marker.png";
import { Typography } from "@material-ui/core";
import PlacemarkMemo from "./PlacemarkMemo"; // Import PlacemarkMemo component
import { debounce } from "lodash";
import useStyles from "./styles.js";

const UserMap = ({ uniqueCitiesIdArr }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	let places = useSelector((state) => state.place?.placesByIds);

	const [place, setPlace] = useState("");

	useEffect(() => {
		dispatch(getPlacesByIds(uniqueCitiesIdArr.filter(Boolean).join(",")));
	}, []);

	const mapRef = React.useRef(null);
	const placeBoxRef = React.useRef(null); // Ref for PlaceBox
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const [activePortal, setActivePortal] = useState(false);
	const [numberPlace, setNumberPlace] = useState("");
	const [iconOffset, setIconOffset] = useState([0, 0]); // Initial offset
	const [parentWrapBoxHeight, setParentWrapBoxHeight] = useState(0);
	const [isSearchedPlace, setIsSearchedPlace] = useState(false);
	const [center, setCenter] = useState([55.75, 37.57]);
	const [mapBounds, setMapBounds] = useState(null);

	const debouncedHandleBoundsChange = debounce((event) => {
		setMapBounds(event.get("newBounds"));
	}, 500); // Adjust debounce delay as needed

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.events.add("boundschange", debouncedHandleBoundsChange);
		}
		return () => {
			if (mapRef.current) {
				mapRef.current.events.remove(
					"boundschange",
					debouncedHandleBoundsChange
				);
			}
		};
	}, [debouncedHandleBoundsChange]);

	useEffect(() => {
		if (mapRef.current) {
			mapRef.current.setCenter(center);
		}
	}, [center]);

	const ClusterLayout = ymaps?.templateLayoutFactory?.createClass(
		'<div style="color: #FFFFFF; font-weight: bold;">{{ properties.geoObjects.length }}</div>',
		{}
	);

	const placemarkLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-place" class="driver-card"></div>`,
		{}
	);

	useEffect(() => {
		if (placeBoxRef.current) {
			const placeBoxHeight = placeBoxRef.current.clientHeight;
			setIconOffset([0, -placeBoxHeight]);
		}
	}, [placeBoxRef.current]);

	const handleWrapBoxHeightChange = (height) => {
		setParentWrapBoxHeight(height);
	};

	return (
		<>
			<div className={classes.mapTitleButton}>
				<Typography variant="h6">Города на карте</Typography>
			</div>
			<>
				<YMaps
					query={{
						load: "package.full",
						apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
					}}
				>
					<Map
						state={{
							center: center,
							zoom: 2,
							controls: ["zoomControl"],
						}}
						width="100%"
						height="50vh"
						onClick={(event) => {
							const coords = event.get("coords");
							const bounds = event.get("bounds");
						}}
						instanceRef={mapRef}
						onLoad={(ymaps) => setYmaps(ymaps)}
					>
						{isSearchedPlace ? (
							<Placemark
								geometry={place?.geoposition}
								options={{
									iconLayout: activePortal ? placemarkLayout : "default#image",
									iconOffset: activePortal ? iconOffset : [-4, 11], // Use dynamic iconOffset
									iconImageSize: [30, 30],
									iconImageHref: marker,
									iconColor: "rgb(236,98,98)",
									interactivityModel: "default#opaque",
									pane: "balloon",
								}}
								properties={{
									hintContent: place?.placeName,
								}}
								onClick={() => {
									setActivePortal(true);
								}}
							/>
						) : (
							<Clusterer
								options={{
									groupByCoordinates: false,
									clusterIcons: [
										{
											href: "https://img.icons8.com/windows/32/EC6262/filled-circle.png",
											size: [40, 40],
											offset: [-20, -20],
										},
									],
									clusterIconContentLayout: ClusterLayout,
								}}
							>
								{places.map((n, i) => (
									<PlacemarkMemo
										key={i}
										geometry={n.geoposition}
										options={{
											iconLayout:
												activePortal && numberPlace === i
													? placemarkLayout
													: "default#image",
											iconOffset:
												activePortal && numberPlace === i
													? iconOffset
													: [-4, 11], // Use dynamic iconOffset
											iconImageSize: [30, 30],
											iconImageHref: marker,
											iconColor: "rgb(236,98,98)",
											interactivityModel: "default#opaque",
											pane: "balloon",
										}}
										properties={{
											hintContent: n?.placeName,
										}}
										onClick={() => {
											setNumberPlace(i);
											setPlace(places ? places[i] : "");
											setActivePortal(true);
										}}
									/>
								))}
							</Clusterer>
						)}
					</Map>
				</YMaps>
				{activePortal && (
					<PortalGeneral
						getHTMLElementId={"placemark-place"}
						placemarkLayout={placemarkLayout}
					>
						<div ref={placeBoxRef}>
							<PlaceBox
								place={place}
								onWrapBoxHeightChange={handleWrapBoxHeightChange}
							/>
						</div>
					</PortalGeneral>
				)}
			</>
		</>
	);
};

export default UserMap;
