import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Link } from "react-router-dom";
import moment from "moment";
import { getTripById, updateTrip } from "../../../actions/trips";
import {
	Container,
	Typography,
	List,
	ListItem,
	Divider,
	Button,
	Dialog,
	FormControlLabel,
	Switch,
} from "@material-ui/core";
import { FlightLand, Hotel, LocationCity } from "@material-ui/icons";
import useStyles from "./styles";
import ArrivalDetails from "./ArrivalDetails";
import StayDialog from "./StayDialog";
import StayDetails from "./StayDetails";
import ArrivalDialog from "./ArrivalDialog";
import CityTimeline from "./CityTimeLine.jsx";
import { getExchangeRates } from "../../../actions/external.js";
import TripProfile from "./TripProfile.jsx";
import TripTotalCost from "./TripTotalCost.jsx";
import TripStatistics from "./TripStatistics.jsx";
import SettingsIcon from "@material-ui/icons/Settings";
import PackingList from "./PackingList.jsx";
import PlaceCityToDo from "../../Places/Place/PlaceCityToDo/PlaceCityToDo.jsx";
import TripCollaborators from "./TripCollaborators.jsx";
import { Helmet } from "react-helmet";

const currencyOptions = [
	"USD",
	"AED",
	"AFN",
	"ALL",
	"AMD",
	"ANG",
	"AOA",
	"ARS",
	"AUD",
	"AWG",
	"AZN",
	"BAM",
	"BBD",
	"BDT",
	"BGN",
	"BHD",
	"BIF",
	"BMD",
	"BND",
	"BOB",
	"BRL",
	"BSD",
	"BTN",
	"BWP",
	"BYN",
	"BZD",
	"CAD",
	"CDF",
	"CHF",
	"CLP",
	"CNY",
	"COP",
	"CRC",
	"CUP",
	"CVE",
	"CZK",
	"DJF",
	"DKK",
	"DOP",
	"DZD",
	"EGP",
	"ERN",
	"ETB",
	"EUR",
	"FJD",
	"FKP",
	"FOK",
	"GBP",
	"GEL",
	"GGP",
	"GHS",
	"GIP",
	"GMD",
	"GNF",
	"GTQ",
	"GYD",
	"HKD",
	"HNL",
	"HRK",
	"HTG",
	"HUF",
	"IDR",
	"ILS",
	"IMP",
	"INR",
	"IQD",
	"IRR",
	"ISK",
	"JEP",
	"JMD",
	"JOD",
	"JPY",
	"KES",
	"KGS",
	"KHR",
	"KID",
	"KMF",
	"KRW",
	"KWD",
	"KYD",
	"KZT",
	"LAK",
	"LBP",
	"LKR",
	"LRD",
	"LSL",
	"LYD",
	"MAD",
	"MDL",
	"MGA",
	"MKD",
	"MMK",
	"MNT",
	"MOP",
	"MRU",
	"MUR",
	"MVR",
	"MWK",
	"MXN",
	"MYR",
	"MZN",
	"NAD",
	"NGN",
	"NIO",
	"NOK",
	"NPR",
	"NZD",
	"OMR",
	"PAB",
	"PEN",
	"PGK",
	"PHP",
	"PKR",
	"PLN",
	"PYG",
	"QAR",
	"RON",
	"RSD",
	"RUB",
	"RWF",
	"SAR",
	"SBD",
	"SCR",
	"SDG",
	"SEK",
	"SGD",
	"SHP",
	"SLE",
	"SLL",
	"SOS",
	"SRD",
	"SSP",
	"STN",
	"SYP",
	"SZL",
	"THB",
	"TJS",
	"TMT",
	"TND",
	"TOP",
	"TRY",
	"TTD",
	"TVD",
	"TWD",
	"TZS",
	"UAH",
	"UGX",
	"UYU",
	"UZS",
	"VES",
	"VND",
	"VUV",
	"WST",
	"XAF",
	"XCD",
	"XDR",
	"XOF",
	"XPF",
	"YER",
	"ZAR",
	"ZMW",
	"ZWL",
];

const TripDetails = ({ user }) => {
	const classes = useStyles();
	const { id } = useParams();
	const dispatch = useDispatch();
	let { trip } = useSelector((state) => state.trips);
	const { exchangeRates } = useSelector((state) => state.external);
	let { collaborators } = useSelector((state) => state.trips);
	const [checkUser, setCheckUser] = useState(false);

	const [arrival, setArrival] = useState({
		type: "plane",
		totalCost: "",
		currency: "RUB",
		numberOfPeople: "",
		departureTime: "",
		arrivalTime: "",
	});

	const [stay, setStay] = useState(null);

	const transportOptions = [
		{ value: "plane", label: "Самолётом" },
		{ value: "train", label: "Поездом" },
		{ value: "bus", label: "Автобусом" },
		{ value: "car", label: "На машине" },
		{ value: "cruise", label: "На корабле" },
	];

	const [openCityModal, setOpenCityModal] = useState(null);
	const [openStayModal, setOpenStayModal] = useState(null);
	const [openActivitiesModal, setOpenActivitiesModal] = useState(null);
	const [isEditMode, setIsEditMode] = useState(true);
	const [selectedCurrency, setSelectedCurrency] = useState("USD");
	const [isOpenCityToDo, setIsOpenCityToDo] = useState(false);
	const [isPublic, setIsPublic] = useState(trip?.isPublic || false);

	useEffect(() => {
		dispatch(getTripById(id));
		dispatch(getExchangeRates());
	}, [dispatch, id]);

	useEffect(() => {
		setCheckUser(user?.result?._id === trip?.userId?._id);
		setIsPublic(trip?.isPublic);
	}, [trip]);

	if (!trip) return <Typography>Загрузка...</Typography>;

	const createUpdatedTripData = (currentTrip, updatedDetails) => {
		return {
			...updatedDetails,
			userId:
				typeof currentTrip.userId === "object" && currentTrip.userId._id
					? currentTrip.userId._id
					: currentTrip.userId,
			cities: updatedDetails.cities.map((city) => ({
				...city,
				cityId:
					typeof city.cityId === "object" && city.cityId._id
						? city.cityId._id
						: city.cityId,
			})),
		};
	};

	const handleOpenModal = (cityIndex) => {
		setOpenCityModal(cityIndex);
		if (cityIndex === "endPlace") {
			const lastCity = trip?.cities[trip?.cities.length - 1];
			const initialDepartureTime = lastCity?.visitDates?.endDate;
			setArrival({
				type: "plane",
				totalCost: "",
				currency: "RUB",
				numberOfPeople: "",
				departureTime: initialDepartureTime
					? moment(initialDepartureTime).format("YYYY-MM-DD HH:mm")
					: "",
				arrivalTime: initialDepartureTime
					? moment(initialDepartureTime)
							.add(1, "hour")
							.format("YYYY-MM-DD HH:mm")
					: "",
				flightNumber: "", // Initialize new fields
				departureAirport: "",
				arrivalAirport: "",
				trainNumber: "",
				departureStation: "",
				arrivalStation: "",
			});
		} else {
			setArrival(
				trip.cities[cityIndex]?.arrival || {
					type: "plane",
					totalCost: "",
					currency: "RUB",
					numberOfPeople: "",
					departureTime:
						trip.cities[cityIndex].visitDates.startDate.split(":00.")[0] || "",
					arrivalTime:
						moment(trip.cities[cityIndex].visitDates.startDate)
							.add(1, "hour")
							.format("YYYY-MM-DD HH:mm") || "",
					flightNumber: "", // Initialize new fields
					departureAirport: "",
					arrivalAirport: "",
					trainNumber: "",
					departureStation: "",
					arrivalStation: "",
				}
			);
		}
	};

	const handleAddStay = (city, index) => {
		setStay(
			city?.stay || {
				type: "",
				arrivalDate: city.visitDates.startDate.split("T")[0] || "",
				departureDate: city.visitDates.endDate.split("T")[0] || "",
				name: "",
				address: "",
				location: "",
				website: "",
				totalCost: "",
				currency: "",
				numberOfPeople: "",
			}
		);
		setOpenStayModal(index);
	};

	const handleCloseModal = () => {
		setOpenCityModal(null);
	};

	const handleEditMode = () => {
		setIsEditMode((prev) => !prev);
		setCheckUser((prev) => !prev);
	};

	const handleSaveArrival = () => {
		let updatedCities = trip.cities.map((city, index) =>
			index === openCityModal ? { ...city, arrival } : city
		);
		let endPlace = openCityModal === "endPlace" ? trip.endPlace : null;
		let updatedEndPlace =
			openCityModal === "endPlace" ? { ...endPlace, arrival: arrival } : null;
		const updatedTrip =
			openCityModal === "endPlace"
				? { ...trip, endPlace: updatedEndPlace }
				: { ...trip, cities: updatedCities };
		console.log(updatedTrip);
		const updatedTripData = createUpdatedTripData(trip, updatedTrip);
		console.log(updatedTripData);

		dispatch(updateTrip(id, updatedTripData));
		setOpenCityModal(null);
	};

	const handleSaveStay = () => {
		let updatedCities = trip.cities.map((city, index) =>
			index === openStayModal ? { ...city, stay } : city
		);
		const updatedTrip = { ...trip, cities: updatedCities };
		const updatedTripData = createUpdatedTripData(trip, updatedTrip);

		dispatch(updateTrip(id, updatedTripData));
		setOpenStayModal(null);
	};

	const handleSaveActivities = (activities) => {
		let updatedCities = trip.cities.map((city, index) =>
			index === openActivitiesModal ? { ...city, activities } : city
		);
		const updatedTrip = { ...trip, cities: updatedCities };
		const updatedTripData = createUpdatedTripData(trip, updatedTrip);

		dispatch(updateTrip(id, updatedTripData));
		setOpenActivitiesModal(null);
	};

	const handleVisibilityChange = () => {
		const updatedTrip = { ...trip, isPublic: !isPublic };
		const updatedTripData = createUpdatedTripData(trip, updatedTrip);
		dispatch(updateTrip(id, updatedTripData));
		setIsPublic(!isPublic);
	};

	if (!isPublic && user?.result?._id !== trip?.userId?._id) {
		return (
			<Typography variant="h6">Автор поездки сделал ее частной</Typography>
		);
	}

	return (
		<>
			<Helmet>
				<title>
					{trip?.tripName && `${trip?.tripName} - поездка, ToTravelRu`}
				</title>
				<meta name="description" content={`${trip.tripName}`} />
			</Helmet>
			<Container className={classes.container}>
				<div className={classes.arrivalBlock}>
					<Typography variant="h4" className={classes.title}>
						{trip.tripName}
					</Typography>
					{user?.result?._id === trip?.userId?._id && (
						<FormControlLabel
							control={
								<Switch
									checked={isEditMode}
									onChange={handleEditMode}
									name="editMode"
									color="secondary"
								/>
							}
							label="Режим редактирования"
						/>
					)}
				</div>

				<TripProfile trip={trip} />
				<TripCollaborators
					trip={trip}
					collaborators={collaborators}
					user={user}
					checkUser={checkUser}
				/>

				{checkUser && (
					<>
						<Typography variant="h6" className={classes.timelineTitle}>
							Настройки поездки:
						</Typography>
						<FormControlLabel
							control={
								<Switch
									checked={isPublic}
									onChange={handleVisibilityChange}
									name="tripVisibility"
									color="secondary"
								/>
							}
							label={
								isPublic
									? "Поездка видна всем"
									: "Поездка видна только вам и попутчикам"
							}
						/>
						<Typography className={classes.noteText}>
							Пожалуйста, не указывайте персональную информацию даже если
							делаете поездку непубличной, чтобы ее никто не смог украсть
						</Typography>
					</>
				)}

				<Typography variant="h6" className={classes.timelineTitle}>
					Страны:
				</Typography>
				<Typography>{trip.countries.join(", ")}</Typography>

				<Typography variant="h6" className={classes.timelineTitle}>
					Города:
				</Typography>

				<List className={classes.cityList}>
					{trip?.startPlace?.name && (
						<ListItem className={classes.cityItem}>
							<Typography variant="h6">
								{trip?.startPlace?.cityId ? (
									<Link
										to={`/place/${String(trip?.startPlace?.cityId)}`}
										className={classes.cityLink}
									>
										{trip?.startPlace?.name}
									</Link>
								) : (
									trip?.startPlace?.name
								)}
							</Typography>
							<Divider className={classes.divider} />
						</ListItem>
					)}

					{trip.cities.map((city, index) => (
						<ListItem key={index} className={classes.cityItem}>
							<div className={classes.cityInfo}>
								{city.arrival && (
									<>
										<ArrivalDetails
											arrival={city.arrival}
											trip={trip}
											index={index}
											handleOpenModal={handleOpenModal}
											checkUser={checkUser}
										/>
									</>
								)}
								<div className={classes.arrivalBlockText}>
									<Typography variant="h6">
										{city?.cityId?._id ? (
											<Link
												to={`/place/${String(city?.cityId?._id)}`}
												className={classes.cityLink}
											>
												{city?.name}
											</Link>
										) : (
											city?.name
										)}
									</Typography>
									{checkUser && (
										<Link
											to={`/trip/${trip._id}/edit`}
											style={{ textDecoration: "none" }}
										>
											<Button className={classes.buttonClear}>
												<SettingsIcon />
											</Button>
										</Link>
									)}
								</div>
								<Typography>
									{moment(city.visitDates.startDate).format("DD MMMM YYYY")} –{" "}
									{moment(city.visitDates.endDate).format("DD MMMM YYYY")}
								</Typography>
								{city.stay && (
									<>
										<StayDetails
											stay={city.stay}
											trip={trip}
											index={index}
											checkUser={checkUser}
											handleAddStay={handleAddStay}
											city={city}
										/>
									</>
								)}
								<CityTimeline
									visitDates={city?.visitDates}
									currencyOptions={currencyOptions}
									setOpenActivitiesModal={setOpenActivitiesModal}
									city={city}
									index={index}
									handleSaveActivities={handleSaveActivities}
									checkUser={checkUser}
								/>
								<Button onClick={() => setIsOpenCityToDo(index)}>
									Что посетить в городе {city?.name}?
								</Button>
								{isOpenCityToDo === index && (
									<PlaceCityToDo
										place={{ placeName: city?.name, _id: city?.cityId?._id }}
										type="short"
									/>
								)}
							</div>
							<div className={classes.buttonsFlex}>
								{checkUser && !city.arrival && (
									<Button
										variant="contained"
										startIcon={<FlightLand />}
										onClick={() => handleOpenModal(index)}
										className={classes.buttonSecondary}
									>
										Добавить прибытие
									</Button>
								)}
								{checkUser && !city.stay && (
									<Button
										variant="contained"
										startIcon={<Hotel />}
										onClick={() => handleAddStay(city, index)}
										className={classes.buttonSecondary}
									>
										Добавить проживание
									</Button>
								)}
							</div>
							{index !== trip.cities.length - 1 && (
								<Divider className={classes.divider} />
							)}
							{trip?.endPlace?.name && index === trip.cities.length - 1 && (
								<Divider className={classes.divider} />
							)}
						</ListItem>
					))}
				</List>

				{trip?.endPlace?.name && (
					<ListItem className={classes.cityItem}>
						<div className={classes.cityInfo}>
							{trip?.endPlace?.arrival && (
								<>
									<ArrivalDetails
										arrival={trip?.endPlace?.arrival}
										trip={trip}
										index={"endPlace"}
										handleOpenModal={handleOpenModal}
										checkUser={checkUser}
									/>
								</>
							)}
							<Typography variant="h6">
								{trip?.endPlace?.cityId ? (
									<Link
										to={`/place/${String(trip?.endPlace?.cityId)}`}
										className={classes.cityLink}
									>
										{trip?.endPlace?.name}
									</Link>
								) : (
									trip?.endPlace?.name
								)}
							</Typography>
							{checkUser && !trip.endPlace.arrival && (
								<div className={classes.buttonsFlex}>
									<Button
										variant="contained"
										startIcon={<FlightLand />}
										onClick={() => handleOpenModal("endPlace")}
										className={classes.buttonSecondary}
									>
										Добавить прибытие
									</Button>
								</div>
							)}
						</div>
					</ListItem>
				)}

				{checkUser && (
					<Link
						to={`/trip/${trip._id}/edit`}
						style={{ textDecoration: "none" }}
					>
						<Button
							variant="outlined"
							color="secondary"
							className={classes.buttonPrimary}
							startIcon={<LocationCity />}
						>
							Изменить города
						</Button>
					</Link>
				)}

				<ArrivalDialog
					openCityModal={openCityModal}
					arrival={arrival}
					setArrival={setArrival}
					handleCloseModal={handleCloseModal}
					handleSaveArrival={handleSaveArrival}
					currencyOptions={currencyOptions}
					transportOptions={transportOptions}
				/>

				<Dialog
					open={openStayModal !== null}
					onClose={() => setOpenStayModal(null)}
					fullWidth
					maxWidth="sm"
				>
					<StayDialog
						stay={stay}
						setStay={setStay}
						onClose={() => setOpenStayModal(null)}
						handleSaveStay={handleSaveStay}
						currencyOptions={currencyOptions}
						openStayModal={openStayModal}
					/>
				</Dialog>

				<Typography variant="h6" className={classes.timelineTitle}>
					Статистика поездки:
				</Typography>

				<TripTotalCost
					trip={trip}
					currencyOptions={currencyOptions}
					exchangeRates={exchangeRates}
					selectedCurrency={selectedCurrency}
					setSelectedCurrency={setSelectedCurrency}
					checkUser={checkUser}
				/>

				<TripStatistics
					trip={trip}
					selectedCurrency={selectedCurrency}
					exchangeRates={exchangeRates}
				/>

				<PackingList trip={trip} tripId={id} checkUser={checkUser} />
			</Container>
		</>
	);
};

export default TripDetails;
