import React, { useState, useEffect, useCallback } from "react";
import { Typography, Button, TextField } from "@material-ui/core";
import useStyles from "./styles.js";
import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Portal } from "./Portal";
import ConfirmBox from "./ConfirmBox/ConfirmBox";
import SearchGeo from "./SearchGeo/SearchGeo";
import { useDispatch } from "react-redux";
import { addPlace, addPostToPlace } from "../../../actions/place.js";
import ConfirmBoxSmall from "./ConfirmBoxSmall/ConfirmBoxSmall.jsx";
import SelectGeoMap from "./SelectGeoMap/SelectGeoMap.jsx";

const SelectGeo = ({ postContent, geoChoose, setGeoChoose }) => {
	const classes = useStyles();
	const dispatch = useDispatch();
	const [ymaps, setYmaps] = useState(React.useRef(null));
	const [activePortal, setActivePortal] = useState(false);

	const iconContentLayout = ymaps?.templateLayoutFactory?.createClass(
		`<div id="placemark-content" class="driver-card"></div>`,
		{}
	);

	const handleConfirm = async (place) => {
		const addedPlace = await dispatch(addPlace(place));
		window.location.reload();
	};

	const handleConfirmExisting = async (placeId, rate) => {
		const addedPlace = await dispatch(
			addPostToPlace(placeId, rate, postContent?._id)
		);
		window.location.reload();
	};

	const [selectedPlaceMap, setSelectedPlaceMap] = useState(null);

	const handleSelectedPlace = (selectedPlace) => {
		setSelectedPlaceMap(selectedPlace);
		setActivePortal(true);
	};

	return (
		<div>
			<div className={classes.textMapBox}>
				<Typography variant="h6">Добавить место к посту</Typography>
				<Button
					className={classes.betaTest}
					onClick={() => setGeoChoose(!geoChoose)}
				>
					Вернуться в пост
				</Button>
			</div>
			<Typography className={classes.textChoose}>
				Можно добавить пост к уже существующему месту:
			</Typography>
			<SelectGeoMap postContent={postContent} mapInPost={true} />
			{/*confirmChosenCoords && (
				<div
					className="box"
					style={{
						top: confirmChosenCoords?.top,
						left: confirmChosenCoords?.left,
					}}
				>
					Подтверждено
				</div>
			)*/}
			{/*<YMaps
				query={{
					load: "package.full",
					apikey: "d0f3f269-aba5-4a51-af7b-f83ab9500f92",
				}}
			>
				<Map
					defaultState={{
						center: selectedPlaceMap
							? selectedPlaceMap?.geoposition
							: [55.75, 37.57],
						zoom: 2,
						controls: ["zoomControl", "searchControl"],
					}}
					width="100%"
					height="83vh"
					onClick={(event) => {
						const coords = event.get("coords");
						setChosenCoords(() => coords);
						if (iconContentLayout) {
							setActivePortal(true);
						}
					}}
					instanceRef={mapRef}
					onLoad={(ymaps) => setYmaps(ymaps)}
					modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
				>
					<Placemark
						geometry={
							selectedPlaceMap ? selectedPlaceMap?.geoposition : chosenCoords
						}
						options={{
							iconLayout: iconContentLayout,
							iconOffset: selectedPlaceMap ? [0, -109.5] : [0, -285.5],
							iconImageHref:
								"https://img.icons8.com/ios-filled/50/EC6262/point-of-interest.png",
							iconColor: "rgb(236,98,98)",
							balloonCloseButton: "false",
							pane: "outerBalloon",
							cursor: "balloon",
							interactivityModel: "default#opaque",
						}}
						properties={{
							iconContent: "Геолокация",
						}}
					/>
				</Map>
					</YMaps>*/}
			{/*activePortal && selectedPlaceMap === null && (
				<Portal
					getHTMLElementId={"placemark-content"}
					chosenCoords={chosenCoords}
				>
					<ConfirmBox
						handleConfirm={handleConfirm}
						chosenCoords={chosenCoords}
						postContent={postContent}
					/>
				</Portal>
			)}
			{activePortal && selectedPlaceMap !== null && (
				<Portal
					getHTMLElementId={"placemark-content"}
					selectedPlaceMap={selectedPlaceMap}
				>
					<ConfirmBoxSmall
						handleConfirmExisting={handleConfirmExisting}
						place={selectedPlaceMap}
					/>
				</Portal>
			)*/}
		</div>
	);
};

export default SelectGeo;
