import React, { useState } from "react";
import {
	TextField,
	Button,
	Grid,
	Typography,
	MenuItem,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core";
import { addPlaceDescription } from "../../../../actions/placeDescription";
import { useDispatch } from "react-redux";
import { translateDayToRussian } from "../../../../utils/placeUtils";
import HotelForm from "./HotelForm/HotelForm";

const SuggestPlaceInfo = ({ place, userId, classes, setIsInfoFormOpen }) => {
	const dispatch = useDispatch();
	const [hotelFormData, setHotelFormData] = useState({});
	const [address, setAddress] = useState(place?.address ? place?.address : "");
	const [phone, setPhone] = useState(place?.phone ? place?.phone : "");
	const [website, setWebsite] = useState(place?.website ? place?.website : "");
	const [workingHours, setWorkingHours] = useState({
		monday: ["", ""],
		tuesday: ["", ""],
		wednesday: ["", ""],
		thursday: ["", ""],
		friday: ["", ""],
		saturday: ["", ""],
		sunday: ["", ""],
	});
	const [dayOff, setDayOff] = useState({
		monday: false,
		tuesday: false,
		wednesday: false,
		thursday: false,
		friday: false,
		saturday: false,
		sunday: false,
	});
	const [paymentMethod, setPaymentMethod] = useState(
		place?.paymentMethod ? place?.paymentMethod : ""
	);
	const [cuisine, setCuisine] = useState(place?.cuisine ? place?.cuisine : "");

	// City-specific states
	const [population, setPopulation] = useState(
		place?.population ? place?.population : ""
	);
	const [firstMentioned, setFirstMentioned] = useState(
		place?.firstMentioned ? place?.firstMentioned : ""
	);
	const [timeZone, setTimeZone] = useState(
		place?.timeZone ? place?.timeZone : ""
	);
	const [timeZoneSummer, setTimeZoneSummer] = useState(
		place?.timeZoneSummer ? place?.timeZoneSummer : ""
	);
	const [language, setLanguage] = useState(
		place?.language ? place?.language : ""
	);
	const [climateDescription, setClimateDescription] = useState(
		place?.climateDescription ? place?.climateDescription : ""
	);
	const [averageTempDay, setAverageTempDay] = useState(
		place?.averageTempDay
			? place?.averageTempDay
			: {
					jan: "",
					feb: "",
					mar: "",
					apr: "",
					may: "",
					jun: "",
					jul: "",
					aug: "",
					sep: "",
					oct: "",
					nov: "",
					dec: "",
			  }
	);
	const [averageTempNight, setAverageTempNight] = useState(
		place?.averageTempNight
			? place?.averageTempNight
			: {
					jan: "",
					feb: "",
					mar: "",
					apr: "",
					may: "",
					jun: "",
					jul: "",
					aug: "",
					sep: "",
					oct: "",
					nov: "",
					dec: "",
			  }
	);
	const [averageTempWater, setAverageTempWater] = useState(
		place?.averageTempWater
			? place?.averageTempWater
			: {
					jan: "",
					feb: "",
					mar: "",
					apr: "",
					may: "",
					jun: "",
					jul: "",
					aug: "",
					sep: "",
					oct: "",
					nov: "",
					dec: "",
			  }
	);
	const [averagePrecipitation, setAveragePrecipitation] = useState(
		place?.averagePrecipitation
			? place?.averagePrecipitation
			: {
					jan: "",
					feb: "",
					mar: "",
					apr: "",
					may: "",
					jun: "",
					jul: "",
					aug: "",
					sep: "",
					oct: "",
					nov: "",
					dec: "",
			  }
	);

	// Validation states for phone, website, working hours
	const [phoneError, setPhoneError] = useState("");
	const [websiteError, setWebsiteError] = useState("");
	const [workingHoursErrors, setWorkingHoursErrors] = useState({
		monday: ["", ""],
		tuesday: ["", ""],
		wednesday: ["", ""],
		thursday: ["", ""],
		friday: ["", ""],
		saturday: ["", ""],
		sunday: ["", ""],
	});

	const handleHotelFormChange = (data) => {
		setHotelFormData(data); // Update hotel-specific form data
	};

	// Validate phone number format in real-time
	const handlePhoneChange = (e) => {
		const phoneValue = e.target.value;
		const phoneFormat = /^\+\d{1,15}$/;
		setPhone(phoneValue);

		if (phoneValue && !phoneFormat.test(phoneValue)) {
			setPhoneError(
				"Пожалуйста, введите телефон в формате +11111111111 (максимум 15 цифр)."
			);
		} else {
			setPhoneError(""); // Clear error if valid
		}
	};

	// Validate website length in real-time
	const handleWebsiteChange = (e) => {
		const websiteValue = e.target.value;
		setWebsite(websiteValue);

		if (websiteValue.length > 150) {
			setWebsiteError("Длина веб-сайта не должна превышать 150 символов.");
		} else {
			setWebsiteError(""); // Clear error if valid
		}
	};

	// Handle day off changes
	const handleDayOffChange = (day) => {
		setDayOff((prevDayOff) => ({
			...prevDayOff,
			[day]: !prevDayOff[day],
		}));
		setWorkingHours((prevHours) => ({
			...prevHours,
			[day]: !dayOff[day] ? ["dayoff", "dayoff"] : ["", ""],
		}));
		setWorkingHoursErrors((prevErrors) => ({
			...prevErrors,
			[day]: ["", ""],
		}));
	};

	// Handle working hours changes
	const handleWorkingHoursChange = (day, index, value) => {
		const timeFormat = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/;
		if (dayOff[day]) return;
		setWorkingHours((prevHours) => ({
			...prevHours,
			[day]: prevHours[day].map((time, i) => (i === index ? value : time)),
		}));
		if (value && !timeFormat.test(value)) {
			setWorkingHoursErrors((prevErrors) => ({
				...prevErrors,
				[day]: prevErrors[day].map((err, i) =>
					i === index
						? "Пожалуйста, введите время в формате ЧЧ:ММ или Ч:ММ."
						: err
				),
			}));
		} else {
			setWorkingHoursErrors((prevErrors) => ({
				...prevErrors,
				[day]: prevErrors[day].map((err, i) => (i === index ? "" : err)),
			}));
		}
	};

	// Handle average temperature changes (day and night)
	const handleAverageTempChange = (type, month, value) => {
		if (type === "precipitation") {
			setAveragePrecipitation((prevTemps) => ({
				...prevTemps,
				[month]: value,
			}));
		} else if (type === "water") {
			setAverageTempWater((prevTemps) => ({
				...prevTemps,
				[month]: value,
			}));
		} else if (type === "day") {
			setAverageTempDay((prevTemps) => ({
				...prevTemps,
				[month]: value,
			}));
		} else {
			setAverageTempNight((prevTemps) => ({
				...prevTemps,
				[month]: value,
			}));
		}
	};

	// Handle form submission
	const handleSubmit = async () => {
		const description = {
			placeId: place?._id,
			userId,
			address,
			phone,
			website,
			workingHours,
			paymentMethod,
			cuisine,
			// Include city information if the placeType is city
			...(place?.placeType === "city" && {
				population,
				firstMentioned,
				timeZone,
				averageTempDay,
				averageTempNight,
				timeZoneSummer,
				language,
				climateDescription,
				averageTempWater,
				averagePrecipitation,
			}),
			...(place?.placeType === "hotel" && hotelFormData),
		};
		try {
			await dispatch(addPlaceDescription(description));
			setIsInfoFormOpen(false);
		} catch (error) {
			console.error("Error while submitting place info:", error);
		}
	};

	return (
		<div>
			<Typography variant="h6">Добавить информацию о месте</Typography>

			{/* Address */}
			<TextField
				label="Адрес"
				variant="outlined"
				value={address}
				onChange={(e) => setAddress(e.target.value)}
				fullWidth
				margin="normal"
			/>

			{/* Phone */}
			<TextField
				label="Телефон (+11111111111)"
				variant="outlined"
				value={phone}
				onChange={handlePhoneChange}
				fullWidth
				margin="normal"
				error={!!phoneError}
				helperText={phoneError}
			/>

			{/* Website */}
			<TextField
				label="Веб-сайт (не более 150 символов)"
				variant="outlined"
				value={website}
				onChange={handleWebsiteChange}
				fullWidth
				margin="normal"
				error={!!websiteError}
				helperText={websiteError}
			/>

			{/* Working Hours */}
			{place?.placeType !== "city" && (
				<>
					<Typography variant="h6">Часы работы (ЧЧ:ММ)</Typography>
					{[
						"monday",
						"tuesday",
						"wednesday",
						"thursday",
						"friday",
						"saturday",
						"sunday",
					].map((day) => (
						<Grid container spacing={2} key={day} alignItems="center">
							<Typography>{translateDayToRussian(day)}</Typography>
							<Grid item xs={12}>
								<FormControlLabel
									control={
										<Checkbox
											checked={dayOff[day]}
											onChange={() => handleDayOffChange(day)}
											color="primary"
										/>
									}
									label="Выходной"
								/>
							</Grid>
							{!dayOff[day] && (
								<>
									<Grid item xs={6}>
										<TextField
											label={`${translateDayToRussian(day)}, начало (ЧЧ:ММ)`}
											variant="outlined"
											value={workingHours[day][0]}
											onChange={(e) =>
												handleWorkingHoursChange(day, 0, e.target.value)
											}
											fullWidth
											error={!!workingHoursErrors[day][0]}
											helperText={workingHoursErrors[day][0]}
										/>
									</Grid>
									<Grid item xs={6}>
										<TextField
											label={`${translateDayToRussian(day)}, конец (ЧЧ:ММ)`}
											variant="outlined"
											value={workingHours[day][1]}
											onChange={(e) =>
												handleWorkingHoursChange(day, 1, e.target.value)
											}
											fullWidth
											error={!!workingHoursErrors[day][1]}
											helperText={workingHoursErrors[day][1]}
										/>
									</Grid>
								</>
							)}
						</Grid>
					))}
				</>
			)}

			{/* Payment Method */}
			{place?.placeType === "restaurant" && (
				<TextField
					label="Способ оплаты"
					variant="outlined"
					select
					value={paymentMethod}
					onChange={(e) => setPaymentMethod(e.target.value)}
					fullWidth
					margin="normal"
				>
					<MenuItem value="Только наличные">Только наличные</MenuItem>
					<MenuItem value="Наличные и карты">Наличные и карты</MenuItem>
					<MenuItem value="Наличные, карты и QR-код">
						Наличные, карты и QR-код
					</MenuItem>
				</TextField>
			)}

			{/* Cuisine */}
			{place?.placeType === "restaurant" && (
				<TextField
					label="Кухня (через запятую)"
					variant="outlined"
					value={cuisine}
					onChange={(e) => setCuisine(e.target.value.split(","))}
					fullWidth
					margin="normal"
					inputProps={{ maxLength: 100 }} // Set the max length here
				/>
			)}

			{/* City Information */}
			{place?.placeType === "city" && (
				<>
					<Typography variant="h6">Особенности города</Typography>

					{/* Population */}
					<TextField
						label="Сколько человек живет в городе? (в чел.)"
						variant="outlined"
						value={population}
						onChange={(e) => setPopulation(e.target.value)}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 20 }}
					/>

					{/* First Mentioned */}
					<TextField
						label="В каком году город был основан?"
						variant="outlined"
						value={firstMentioned}
						onChange={(e) => setFirstMentioned(e.target.value)}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 5 }}
					/>

					{/* Time Zone */}
					<TextField
						label="Укажите часовой пояс"
						variant="outlined"
						value={timeZone}
						onChange={(e) => setTimeZone(e.target.value)}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 6 }}
						helperText="Пример: +1:00, -2:30"
					/>

					{/* Time Zone Summer */}
					<TextField
						label="Укажите летний часовой пояс"
						variant="outlined"
						value={timeZoneSummer}
						onChange={(e) => setTimeZoneSummer(e.target.value)}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 6 }}
						helperText="Пример: +1:00, -2:30"
					/>

					{/* Language */}
					<TextField
						label="Укажите язык общения (через запятую)"
						variant="outlined"
						value={language}
						onChange={(e) => setLanguage(e.target.value.split(","))}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 100 }} // Set the max length here
						helperText="Пример: русский,англиский"
					/>

					<Typography variant="h6">Особенности климата</Typography>

					{/* Climate Description */}
					<TextField
						label="Напишите описание климата"
						variant="outlined"
						value={climateDescription}
						onChange={(e) => setClimateDescription(e.target.value)}
						fullWidth
						margin="normal"
						inputProps={{ maxLength: 1500 }}
					/>

					{/* Average Daytime Temperature */}
					<Grid container spacing={2}>
						{Object.keys(averageTempDay).map((month) => (
							<Grid item xs={6} key={month}>
								<TextField
									label={`Укажите дневную температуру (${month})`}
									variant="outlined"
									value={averageTempDay[month]}
									onChange={(e) =>
										handleAverageTempChange("day", month, e.target.value)
									}
									fullWidth
									inputProps={{ maxLength: 4 }}
								/>
							</Grid>
						))}
					</Grid>

					{/* Average Nighttime Temperature */}
					<Grid container spacing={2}>
						{Object.keys(averageTempNight).map((month) => (
							<Grid item xs={6} key={month}>
								<TextField
									label={`Укажите ночную температуру (${month})`}
									variant="outlined"
									value={averageTempNight[month]}
									onChange={(e) =>
										handleAverageTempChange("night", month, e.target.value)
									}
									fullWidth
									inputProps={{ maxLength: 4 }}
								/>
							</Grid>
						))}
					</Grid>

					{/* Average Water Temperature */}
					<Grid container spacing={2}>
						{Object.keys(averageTempWater).map((month) => (
							<Grid item xs={6} key={month}>
								<TextField
									label={`Укажите температуру воды (${month})`}
									variant="outlined"
									value={averageTempWater[month]}
									onChange={(e) =>
										handleAverageTempChange("water", month, e.target.value)
									}
									fullWidth
									inputProps={{ maxLength: 4 }}
								/>
							</Grid>
						))}
					</Grid>

					{/* Average Precipitation */}
					<Grid container spacing={2}>
						{Object.keys(averagePrecipitation).map((month) => (
							<Grid item xs={6} key={month}>
								<TextField
									label={`Укажите осадки, мм (${month})`}
									variant="outlined"
									value={averagePrecipitation[month]}
									onChange={(e) =>
										handleAverageTempChange(
											"precipitation",
											month,
											e.target.value
										)
									}
									fullWidth
									inputProps={{ maxLength: 4 }}
								/>
							</Grid>
						))}
					</Grid>
				</>
			)}
			{/* Hotel Form */}
			{place?.placeType === "hotel" && (
				<HotelForm
					handleHotelFormChange={handleHotelFormChange}
					place={place}
				/>
			)}
			{/* Submit Button */}
			<Button
				className={classes.buttonPrimary}
				onClick={handleSubmit}
				style={{ marginTop: "20px" }}
			>
				Отправить
			</Button>
		</div>
	);
};

export default SuggestPlaceInfo;
