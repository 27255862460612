import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserTrips } from "../../../actions/trips";
import {
	CircularProgress,
	Card,
	CardContent,
	Typography,
	Avatar,
	Box,
	Grid,
	ExpansionPanel,
	ExpansionPanelSummary,
	ExpansionPanelDetails,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import useStyles from "./styles";
import moment from "moment";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import UserMap from "../../Maps/Map/UserMap";
import PlannedTripsStatistics from "./PlannedTripsStatistics";

const PlannedTrips = ({ user, userCurrent }) => {
	const dispatch = useDispatch();
	const { trips, isLoading } = useSelector((state) => state?.trips);
	const classes = useStyles();
	const now = moment();
	const [expanded, setExpanded] = useState(false);

	const handleToggle = () => {
		setExpanded((prev) => !prev);
	};

	useEffect(() => {
		if (user?._id) {
			dispatch(getUserTrips(user._id));
		}
	}, [dispatch, user?._id]);

	const stats = useMemo(() => {
		const uniqueCountries = new Set();
		const uniqueCities = new Set();
		const uniqueCitiesId = new Set();
		let totalDays = 0;
		const countryCount = {};

		trips.forEach((trip) => {
			// Count unique countries
			trip.countries.forEach((country) => {
				uniqueCountries.add(country);
				countryCount[country] = (countryCount[country] || 0) + 1;
			});

			// Count unique cities and days spent
			trip.cities.forEach((city) => {
				uniqueCities.add(city.name);
				uniqueCitiesId.add(city?.cityId);
				const startDate = moment(city.visitDates.startDate);
				const endDate = moment(city.visitDates.endDate);
				totalDays += endDate.diff(startDate, "days") + 1;
			});
		});

		// Find most visited country
		const mostVisitedCountry =
			Object.keys(countryCount).length > 0
				? Object.keys(countryCount).reduce((a, b) =>
						countryCount[a] > countryCount[b] ? a : b
				  )
				: null; // or provide a default value

		const uniqueCountriesArr = Array.from(uniqueCountries).filter(
			(id) => id !== undefined
		);
		const uniqueCitiesIdArr = Array.from(uniqueCitiesId).filter(
			(id) => id !== undefined
		);

		return {
			totalTrips: trips.length,
			uniqueCities,
			uniqueCitiesIdArr,
			uniqueCountriesArr,
			totalCountries: uniqueCountries.size,
			totalCities: uniqueCities.size,
			totalDays,
			mostVisitedCountry,
		};
	}, [trips]);

	const categorizeTrips = (trips) => {
		const pastTrips = [];
		const currentTrips = [];
		const futureTrips = [];

		trips.forEach((trip) => {
			const startDate = moment(trip.cities[0]?.visitDates?.startDate);
			const endDate = moment(
				trip.cities[trip.cities.length - 1]?.visitDates?.endDate
			);

			if (endDate.isBefore(now, "day")) {
				pastTrips.push(trip);
			} else if (
				startDate.isSameOrBefore(now, "day") &&
				endDate.isSameOrAfter(now, "day")
			) {
				currentTrips.push(trip);
			} else {
				futureTrips.push(trip);
			}
		});

		return { pastTrips, currentTrips, futureTrips };
	};

	// Categorize trips
	const { pastTrips, currentTrips, futureTrips } = categorizeTrips(trips);

	// Function to render trip cards
	const renderTripCards = (trips, title) => {
		if (trips.length === 0) return null;

		return (
			<div className={classes.tripBlock}>
				<Typography variant="h5" className={classes.tripTitle}>
					{title}
				</Typography>
				<div className={classes.tripsContainer}>
					{trips.map((trip) => (
						<Link
							to={`/trip/${trip._id}`}
							key={trip._id}
							style={{ textDecoration: "none", color: "inherit" }}
						>
							<Card className={classes.tripCard}>
								<CardContent>
									{!trip?.isPublic && user?._id !== userCurrent?.result?._id ? (
										<Typography variant="h5">Частная поездка</Typography>
									) : (
										<>
											<Typography variant="h5">{trip.tripName}</Typography>
											<Typography variant="body1">
												{trip.countries.join(", ")} - Города:{" "}
												{trip.cities.map((c) => c.name).join(", ")} (
												{moment(trip.cities[0]?.visitDates?.startDate).format(
													"DD MMMM YYYY"
												)}{" "}
												-{" "}
												{moment(
													trip.cities[trip.cities.length - 1]?.visitDates
														?.endDate
												).format("DD MMMM YYYY")}
												)
											</Typography>
											{title === "Будущие поездки" && (
												<Typography variant="h6">
													До поездки остал
													{moment(trip.cities[0]?.visitDates?.startDate).diff(
														now,
														"days"
													) === 1
														? "ся"
														: "ось"}{" "}
													{moment(trip.cities[0]?.visitDates?.startDate).diff(
														now,
														"days"
													)}{" "}
													{moment(trip.cities[0]?.visitDates?.startDate).diff(
														now,
														"days"
													) === 1
														? "день"
														: moment(
																trip.cities[0]?.visitDates?.startDate
														  ).diff(now, "days") < 5
														? "дня"
														: "дней"}
												</Typography>
											)}
										</>
									)}
								</CardContent>
							</Card>
						</Link>
					))}
				</div>
			</div>
		);
	};

	if (isLoading) return <CircularProgress />;

	return (
		<Card className={classes.card}>
			<Typography variant="h5">Поездки пользователя</Typography>
			{trips.length === 0 ? null : (
				<>
					<Typography variant="h5">Статистика поездок</Typography>
					<PlannedTripsStatistics
						stats={stats}
						handleToggle={handleToggle}
						expanded={expanded}
					/>
					<UserMap uniqueCitiesIdArr={stats?.uniqueCitiesIdArr} />
				</>
			)}

			{trips.length === 0 ? (
				<Typography>Пользователь пока не указал поездки</Typography>
			) : (
				<>
					{renderTripCards(pastTrips, "Прошедшие поездки")}
					{renderTripCards(currentTrips, "Текущие поездки")}
					{renderTripCards(futureTrips, "Будущие поездки")}
				</>
			)}
		</Card>
	);
};

export default PlannedTrips;
