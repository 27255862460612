// components/FeedSort.jsx
import React from "react";
import { Button, CardContent, Typography } from "@material-ui/core";
import SortIcon from "@material-ui/icons/Sort";
import useStyles from "./styles.js";

const FeedSort = ({
	isSortedTime,
	isSortedVisited,
	isSortedLikes,
	handleSortTime,
	handleSortVisited,
	handleSortLikes,
}) => {
	const classes = useStyles();

	return (
		<CardContent className={classes.cardContent}>
			<Typography variant="h6" className={classes.headerText}>
				Сортировать по:
			</Typography>
			<div className={classes.sortingBox}>
				<Button
					className={classes.sortButton}
					onClick={handleSortTime}
					variant="outlined"
				>
					<div className={classes.sortingItemBox}>
						<SortIcon
							className={
								isSortedTime ? classes.sortIconTrue : classes.sortIconFalse
							}
						/>
						<Typography
							className={
								isSortedTime ? classes.sortIconTrue : classes.sortIconFalse
							}
							variant="body1"
						>
							Времени
						</Typography>
					</div>
				</Button>
				<Button
					className={classes.sortButton}
					onClick={handleSortVisited}
					variant="outlined"
				>
					<div className={classes.sortingItemBox}>
						<SortIcon
							className={
								isSortedVisited ? classes.sortIconTrue : classes.sortIconFalse
							}
						/>
						<Typography
							className={
								isSortedVisited ? classes.sortIconTrue : classes.sortIconFalse
							}
							variant="body1"
						>
							Просмотрам
						</Typography>
					</div>
				</Button>
				<Button
					className={classes.sortButton}
					onClick={handleSortLikes}
					variant="outlined"
				>
					<div className={classes.sortingItemBox}>
						<SortIcon
							className={
								isSortedLikes ? classes.sortIconTrue : classes.sortIconFalse
							}
						/>
						<Typography
							className={
								isSortedLikes ? classes.sortIconTrue : classes.sortIconFalse
							}
							variant="body1"
						>
							Лайкам
						</Typography>
					</div>
				</Button>
			</div>
		</CardContent>
	);
};

export default FeedSort;
