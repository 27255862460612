import React, { useState, useEffect } from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	TextField,
	MenuItem,
	FormControlLabel,
	Checkbox,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { validateInput } from "../../../utils/validationUtils";
import useStyles from "./styles";

const categories = [
	"Достопримечательности",
	"Еда и напитки",
	"Приключения",
	"Шопинг",
	"Отдых",
	"Другое",
];

const AddActivityDialog = ({
	open,
	onClose,
	onSave,
	selectedPeriod,
	selectedDate,
	currencyOptions,
	type,
	selectedActivity,
}) => {
	const classes = useStyles();
	const checkType = type && type === "edit";

	const [activity, setActivity] = useState({
		name: "",
		description: "",
		date: selectedDate,
		timePeriod: selectedPeriod,
		startTime: "",
		endTime: "",
		website: "",
		location: "",
		totalCost: 0,
		currency: "USD",
		numberOfPeople: 1,
		type: "",
		notes: "",
	});

	useEffect(() => {
		if (checkType && selectedActivity) {
			setActivity(selectedActivity);
		} else {
			setActivity({
				name: "",
				description: "",
				date: selectedDate,
				timePeriod: selectedPeriod,
				startTime: "",
				endTime: "",
				website: "",
				location: "",
				totalCost: 0,
				currency: "USD",
				numberOfPeople: 1,
				type: "",
				notes: "",
			});
		}
	}, [selectedActivity, checkType]); // Update when selectedActivity changes

	const [errors, setErrors] = useState({});
	const [isPaid, setIsPaid] = useState(true);

	useEffect(() => {
		if (selectedPeriod && selectedDate) {
			setActivity((prev) => ({
				...prev,
				date: selectedDate,
				timePeriod: selectedPeriod,
			}));
		}
	}, [selectedPeriod, selectedDate]);

	const limits = {
		name: 100,
		description: 500,
		website: 1000,
		location: 1000,
		notes: 1000,
		totalCost: { min: 0, max: 10000000 },
		numberOfPeople: { min: 1, max: 200 },
	};

	const handlePaidChange = () => {
		setIsPaid((prev) => !prev);
		if (isPaid) {
			setActivity((prev) => ({ ...prev, totalCost: 0, currency: "USD" }));
		}
	};

	const handleChange = (e) => {
		const { name, value } = e.target;
		validateInput(name, value, limits, setErrors);
		setActivity({ ...activity, [name]: value });
	};

	const handleChangeCurrency = (event, newValue) => {
		setActivity((prev) => ({
			...prev,
			currency: newValue || "", // Handle clearing the selection
		}));
	};

	const handleSubmit = () => {
		const hasErrors = Object.values(errors).some((err) => err);
		if (hasErrors) return; // Prevent saving if errors exist
		onSave(activity);
		onClose();
	};

	return (
		<Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
			<DialogTitle>
				{checkType ? "Изменить активность" : "Добавить активность"}
			</DialogTitle>
			<DialogContent>
				<TextField
					label="Название активности"
					name="name"
					value={activity.name}
					onChange={handleChange}
					fullWidth
					required
					margin="dense"
					error={!!errors.name}
					helperText={errors.name}
				/>
				<TextField
					select
					label="Категория"
					name="type"
					value={activity.type}
					onChange={handleChange}
					fullWidth
					required
					margin="dense"
				>
					{categories.map((cat) => (
						<MenuItem key={cat} value={cat}>
							{cat}
						</MenuItem>
					))}
				</TextField>
				<TextField
					label="Время начала"
					name="startTime"
					type="time"
					value={activity.startTime}
					onChange={handleChange}
					fullWidth
					margin="dense"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					label="Время окончания"
					name="endTime"
					type="time"
					value={activity.endTime}
					onChange={handleChange}
					fullWidth
					margin="dense"
					InputLabelProps={{ shrink: true }}
				/>
				<TextField
					label="Веб-сайт"
					name="website"
					value={activity.website}
					onChange={handleChange}
					fullWidth
					margin="dense"
					error={!!errors.website}
					helperText={errors.website}
				/>
				<TextField
					label="Ссылка на карту"
					name="location"
					value={activity.location}
					onChange={handleChange}
					fullWidth
					margin="dense"
					error={!!errors.location}
					helperText={errors.location}
				/>
				<FormControlLabel
					control={<Checkbox checked={isPaid} onChange={handlePaidChange} />}
					label="Платная активность"
				/>
				{isPaid && (
					<>
						<TextField
							label="Общая стоимость"
							name="totalCost"
							type="number"
							value={activity.totalCost}
							onChange={handleChange}
							fullWidth
							margin="dense"
							error={!!errors.totalCost}
							helperText={errors.totalCost}
						/>
						<Autocomplete
							name="currency"
							freeSolo // Allows manual input
							options={currencyOptions} // List of available currencies
							value={activity.currency}
							onChange={handleChangeCurrency}
							renderInput={(params) => (
								<TextField
									label="Валюта"
									className={classes.currencyInputText}
									{...params}
								/>
							)}
						/>
					</>
				)}

				<TextField
					label="Количество участников"
					name="numberOfPeople"
					type="number"
					value={activity.numberOfPeople}
					onChange={handleChange}
					fullWidth
					margin="dense"
					error={!!errors.numberOfPeople}
					helperText={errors.numberOfPeople}
				/>
				<TextField
					label="Заметки"
					name="notes"
					value={activity.notes}
					onChange={handleChange}
					fullWidth
					multiline
					rows={2}
					margin="dense"
					error={!!errors.notes}
					helperText={errors.notes}
					inputProps={{ maxLength: 1000 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="secondary">
					Отмена
				</Button>
				<Button
					onClick={handleSubmit}
					color="primary"
					variant="contained"
					disabled={Object.values(errors).some((err) => err)}
				>
					Сохранить
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default AddActivityDialog;
