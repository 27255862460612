import React, { useState } from "react";
import {
	Typography,
	TextField,
	List,
	ListItem,
	ListItemText,
	Button,
} from "@material-ui/core";
import useStyles from "./styles.js";
import { useSelector, useDispatch } from "react-redux";
import { searchPosts } from "../../../../actions/places";
import { translatePlaceType } from "../../../../utils/placeUtils.js";

const SearchGeo = ({ onSelectedPlace, filter }) => {
	const dispatch = useDispatch();
	const classes = useStyles();
	const [search, setSearch] = useState("");
	const [selectedPlace, setSelectedPlace] = useState(null);
	const [isTextFieldFocused, setIsTextFieldFocused] = useState(false); // Track if TextField is focused
	const filterText = filter ? filter : "";

	const searchResults = useSelector((state) => state.place?.placesSearch);

	const handleSearch = async (query) => {
		await dispatch(searchPosts(query, filterText));
	};

	const handleSelectPlace = (place) => {
		setSelectedPlace(place);
		// Call the function passed from the parent component
		onSelectedPlace(place);
		setSearch("");
	};

	return (
		<div className={classes.searchResultsContainer}>
			<TextField
				size="small"
				name="search"
				variant="outlined"
				label="Поиск"
				value={search}
				onChange={(e) => {
					const query = e.target.value;
					setSearch(query);
					handleSearch(query);
				}}
				onFocus={() => setIsTextFieldFocused(true)} // Set isTextFieldFocused to true when focused
				onBlur={() => setIsTextFieldFocused(false)} // Set isTextFieldFocused to false when blurred
				fullWidth
				className={classes.searchContainerText}
			/>
			{isTextFieldFocused && ( // Conditionally render based on isTextFieldFocused state
				<List className={classes.searchResults}>
					{searchResults
						? searchResults.map((result, index) => (
								<ListItem key={index}>
									<div className={classes.listText}>
										<ListItemText primary={result?.placeName} />
										<Typography className={classes.placeTypeText}>
											{translatePlaceType(result?.placeType)}
										</Typography>
									</div>
									<Button
										variant="contained"
										color="primary"
										onMouseDown={(e) => {
											e.preventDefault(); // Prevent default focus behavior
											handleSelectPlace(result);
											setIsTextFieldFocused(false);
										}}
										className={classes.buttonPrimary}
									>
										Выбрать
									</Button>
								</ListItem>
						  ))
						: null}
				</List>
			)}
		</div>
	);
};

export default SearchGeo;
