import {
	START_LOADING_RATES,
	FETCH_EXCHANGE_RATES,
	END_LOADING_RATES,
} from "../constants/actionTypes.js";

const initialState = {
	exchangeRates: {},
	isLoading: true,
};

const externalReducer = (state = initialState, action) => {
	switch (action.type) {
		case START_LOADING_RATES:
			return { ...state, isLoading: true };
		case FETCH_EXCHANGE_RATES:
			return { ...state, exchangeRates: action.payload };
		case END_LOADING_RATES:
			return { ...state, isLoading: false };
		default:
			return state;
	}
};

export default externalReducer;
