import { combineReducers } from "redux";

import posts from "./posts.js";
import auth from "./auth.js";
import users from "./users.js";
import main from "./main.js";
import errorRed from "./errorRed.js";
import notifications from "./notifications.js";
import admin from "./admin.js";
import place from "./place.js";
import feed from "./feed.js";
import placeReview from "./placeReview.js";
import placeSelections from "./placeSelections.js";
import photo from "./photo.js";
import trips from "./trips.js";
import external from "./external.js";

export default combineReducers({
	posts,
	auth,
	users,
	main,
	errorRed,
	notifications,
	admin,
	place,
	feed,
	placeReview,
	placeSelections,
	photo,
	trips,
	external,
});
