import React from "react";
import { Typography, Button } from "@material-ui/core";
import useStyles from "./styles.js";
import ShowGeo from "../../Maps/ShowGeo/ShowGeo";

const PlaceMap = ({ place, handleOpenMap }) => {
	const classes = useStyles();

	return (
		<div className={classes.gridItem}>
			<div className={classes.mapContainer}>
				<div className={classes.mapButtonFlex}>
					<Typography variant="h5" gutterBottom>
						Карта
					</Typography>
					<Button className={classes.buttonPrimary} onClick={handleOpenMap}>
						Открыть карту
					</Button>
				</div>
				<ShowGeo place={place} placeComponent={true} />
			</div>
		</div>
	);
};

export default PlaceMap;
