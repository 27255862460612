import React from "react";
import { Hidden, Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import moment from "moment";

const CommentProfile = ({ classes, c, i, type }) => {
	return (
		<div className={classes.profileTimeBox}>
			<Link
				className={
					c.userType === undefined ? classes.nameBox : classes.nameBoxSpecial
				}
				to={`/user/${c.userLogin}`}
			>
				<Typography key={i} variant="subtitle1" className={classes.userName}>
					{c.userName}
				</Typography>
				<Hidden smDown>
					<Typography key={i} variant="subtitle1" className={classes.userLogin}>
						@{c.userLogin}
					</Typography>
				</Hidden>
			</Link>
			<Typography className={classes.momentComment}>
				{moment(c.createdAt).fromNow()}
			</Typography>
		</div>
	);
};

export default CommentProfile;
