import React from "react";
import { Grid, Paper, Typography, makeStyles } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import useStyles from "../styles";

const ReportSkeleton = () => {
	const classes = useStyles();

	return (
		<>
			<div>
				{[...Array(3)].map((_, index) => (
					<Paper key={index} className={classes.paperPost}>
						<Skeleton variant="text" width="80%" />
						<Skeleton
							variant="rect"
							width="100%"
							height={118}
							style={{ marginTop: 8 }}
						/>
					</Paper>
				))}
			</div>

			<Paper className={classes.paperPost}>
				<div className={classes.section}>
					<Skeleton variant="text" width={250} height={30} />
					<Grid container spacing={3} className={classes.container}>
						{[...Array(3)].map((_, index) => (
							<Grid item xs={12} sm={12} md={6} lg={4} key={index}>
								<Skeleton variant="rect" width="100%" height={180} />
							</Grid>
						))}
					</Grid>
				</div>
			</Paper>
		</>
	);
};

export default ReportSkeleton;
