// File: PostContentContainer.js
import React from "react";
import { Typography, Button, CardMedia, Modal, Box } from "@material-ui/core";
import parse from "html-react-parser";
import PostLegend from "../PostLegend/PostLegend";
import ImageMenu from "../../ImageMenu/ImageMenu";

const PostContentContainer = ({
	postContent,
	toggleLegend,
	closeLegend,
	legend,
	legendProps,
	handleOpenImage,
	handleCloseImage,
	openImage,
	classes,
	user,
}) => {
	console.log(postContent);
	return (
		<div className={classes.postCard}>
			<div className={classes.upperContainer}>
				<Typography gutterBottom variant="h4" className={classes.postTitlePost}>
					{postContent.title}
				</Typography>
				<Button
					variant="outlined"
					color="secondary"
					onClick={toggleLegend}
					className={classes.legendButtonOpen}
				>
					Оглавление
				</Button>
				{legend ? (
					<div className={classes.legend}>
						<Button className={classes.legendButton} onClick={toggleLegend}>
							Х
						</Button>
						<PostLegend closeLegend={closeLegend} legendProps={legendProps} />
					</div>
				) : (
					<div></div>
				)}
			</div>
			{postContent.textImage.map((o, a) => (
				<div>
					{o.image ? (
						o.image.toString().split(":")[0] === "data" ||
						o.image.toString().split("storage.yandexcloud")[0] ===
							"https://totravelru." ? (
							<>
								<div className={classes.imageBox}>
									<Box position="relative" display="inline-block" width="100%">
										<CardMedia
											onClick={() => {
												handleOpenImage(a);
											}}
											className={classes.media}
											image={o.image}
										/>
										<Typography>{o.description}</Typography>
										<ImageMenu
											imageUrl={o.image}
											postId={postContent?._id}
											userId={user?.result?._id}
											reportTitle="Пожаловаться на изображение"
										/>
									</Box>
								</div>
								<Modal
									className={classes.modal}
									open={openImage === a}
									onClose={handleCloseImage}
									aria-labelledby="enlarged-image-modal"
									aria-describedby="enlarged-image-description"
								>
									<div className={classes.modalContent}>
										<Typography
											className={classes.closeButton}
											onClick={handleCloseImage}
										>
											X
										</Typography>
										<img
											src={o.image}
											alt="Enlarged"
											className={classes.enlargedImage}
										/>
									</div>
								</Modal>
							</>
						) : (
							<Typography gutterBottom variant="subtitle1">
								{o.image}
							</Typography>
						)
					) : (
						<div className={classes.textWrap}>{parse(o)}</div>
					)}
				</div>
			))}
		</div>
	);
};

export default PostContentContainer;
