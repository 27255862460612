// Updated PostCard.tsx
import React from "react";
import {
	Card,
	CardContent,
	CardMedia,
	Typography,
	Button,
} from "@material-ui/core";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import VisibilityIcon from "@material-ui/icons/Visibility";
import moment from "moment";
import useStyles from "./styles.js";

const PostReportCard = ({ post, handlePostClick, user }) => {
	const classes = useStyles();

	return (
		<Card key={post?._id} className={classes.root}>
			<CardContent className={classes.cardContent}>
				<Button
					onClick={() => handlePostClick(post?._id)}
					className={classes.postContent}
				>
					<div className={classes.postContentBox}>
						<CardMedia
							className={classes.previewImage}
							image={
								post?.previewImage ||
								"https://user-images.githubusercontent.com/194400/49531010-48dad180-f8b1-11e8-8d89-1e61320e1d82.png"
							}
							title="Preview Image"
						/>
						<Typography
							variant="h5"
							component="h2"
							className={classes.titleText}
						>
							{post?.title}
						</Typography>
						<Typography color="textSecondary" className={classes.creatorText}>
							{post?.creatorInfo}
						</Typography>
						<Typography color="textSecondary" className={classes.usernameText}>
							@{post?.creatorLogin}
						</Typography>
						<div className={classes.infoBox}>
							<Typography className={classes.timeText}>
								{moment(post?.createdAt).fromNow()}{" "}
							</Typography>
							<div className={classes.likesVisitedBox}>
								<div className={classes.likesBox}>
									<Typography className={classes.likesNumber}>
										{post.likes.length}
									</Typography>
									{post.likes.find((like) => like === user?.result?._id) ? (
										<FavoriteIcon className={classes.iconActive} />
									) : (
										<FavoriteBorderIcon className={classes.icon} />
									)}
								</div>
								<div className={classes.visibility}>
									<Typography className={classes.visibilityCount}>
										{post?.visited}
									</Typography>
									<VisibilityIcon className={classes.icon} />
								</div>
							</div>
						</div>
					</div>
				</Button>
			</CardContent>
		</Card>
	);
};

export default PostReportCard;
