import { makeStyles } from "@material-ui/core/styles";

export default makeStyles((theme) => ({
	root: {
		marginBottom: theme.spacing(3),
		marginTop: theme.spacing(3),
		marginLeft: "auto",
		marginRight: "auto",
		maxWidth: 600,
		borderRadius: "20px",
		border: "none",
		boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
		transition: "transform 0.2s ease, box-shadow 0.3s ease",
		"&:hover": {
			transform: "scale(1.02)",
			boxShadow: "0 8px 24px rgba(0, 0, 0, 0.15)",
		},
		"@media (max-width: 1100px)": {
			width: 600,
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
		"@media (max-width: 650px)": {
			width: "100%",
			marginBottom: theme.spacing(1),
			marginTop: theme.spacing(1),
		},
	},
	cardContent: {
		padding: "0px",
		"&:last-child": {
			paddingBottom: "0",
		},
	},
	postContent: {
		padding: "12px",
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	postContentBox: {
		width: "100%",
	},
	previewImage: {
		height: 220,
		margin: "0 auto",
		objectFit: "cover",
		borderRadius: "10px",
		"@media (max-width: 600px)": {
			height: 180,
		},
	},
	titleText: {
		margin: "3px auto",
		color: "#333",
		fontSize: "1.2rem",
		lineHeight: "1.4rem",
	},
	creatorText: {
		margin: "0 auto",
		color: "#777",
		fontSize: "0.9rem",
	},
	usernameText: {
		margin: "0 auto",
		color: "rgba(236,98,98,1)",
		fontSize: "0.8rem",
	},
	likesVisitedBox: {
		display: "flex",
		alignItems: "center",
	},
	likesBox: {
		display: "flex",
		color: "rgba(236,98,98,1)",
	},
	likesNumber: {
		marginRight: "5px",
		fontSize: "1rem",
	},
	visibility: {
		display: "flex",
		alignItems: "center",
		color: "rgba(236,98,98,1)",
		marginLeft: "8px",
	},
	visibilityCount: {
		fontSize: "1rem",
		marginRight: "4px",
	},
	infoBox: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
	},
	timeText: {
		color: "rgba(236,98,98,1)",
		fontSize: "0.9rem",
	},
	icon: {
		color: "rgba(236,98,98,1)",
	},
}));
