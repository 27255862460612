import React from "react";
import { Button, Paper, Typography } from "@material-ui/core";
import Calendar from "../../Calendar/Calendar";
import DeleteIcon from "@material-ui/icons/Delete";
import UpdateIcon from "@material-ui/icons/Update";
import RoomIcon from "@material-ui/icons/Room";
import FavoriteIcon from "@material-ui/icons/Favorite";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";

const ReportActions = ({
	post,
	user,
	classes,
	handleLike,
	deleteAsk,
	setDeleteAsk,
	isUpdateReport,
	setIsUpdateReport,
	handleDeleteReport,
	handleButtonGeoClick,
	isPlacesExist,
	Likes,
}) => {
	return (
		<>
			<div className={classes.calendarLike}>
				<div className={classes.calendarOuterContainer}>
					<Calendar createdAt={post?.createdAt} />
				</div>
				{user?.result ? (
					<Button
						className={
							post?.likes.find((like) => like === user?.result?._id)
								? classes.likeButtonClicked
								: classes.likeButtonNotClicked
						}
						onClick={handleLike}
					>
						<Likes />
					</Button>
				) : (
					<Button
						className={
							post?.likes.find((like) => like === user?.result?._id)
								? classes.likeButtonClicked
								: classes.likeButtonNotClicked
						}
						disabled
					>
						<Likes />
					</Button>
				)}
			</div>
			{user?.result?._id === post?.creator && (
				<>
					{deleteAsk ? (
						<>
							<Paper className={classes.postCardDelete}>
								<Typography className={classes.writePostText}>
									Вы точно хотите удалить отчет?
								</Typography>
								<div className={classes.yesNo}>
									<Button
										onClick={handleDeleteReport}
										className={`${classes.buttonYes} ${classes.writePost} `}
									>
										<Typography className={classes.writePostText}>
											Да
										</Typography>
									</Button>
									<Button
										onClick={() => setDeleteAsk(!deleteAsk)}
										className={`${classes.buttonNo} ${classes.writePost} `}
									>
										<Typography className={classes.writePostText}>
											Нет
										</Typography>
									</Button>
								</div>
							</Paper>
						</>
					) : (
						<>
							<Button
								onClick={() => setDeleteAsk(!deleteAsk)}
								className={`${classes.buttonSecondary} ${classes.deletePost} `}
							>
								<DeleteIcon className={classes.writePostImg} />
								<Typography className={classes.writePostText}>
									Удалить отчет
								</Typography>
							</Button>
							<Button
								className={classes.buttonPrimaryWidth}
								onClick={() => setIsUpdateReport(!isUpdateReport)}
							>
								<UpdateIcon className={classes.writePostImg} />
								{isUpdateReport ? (
									<Typography className={classes.writePostText}>
										Вернуться
									</Typography>
								) : (
									<Typography className={classes.writePostText}>
										Изменить отчет
									</Typography>
								)}
							</Button>
						</>
					)}
				</>
			)}
			{isPlacesExist ? (
				<Button
					onClick={handleButtonGeoClick}
					className={`${classes.geographyButton}`}
				>
					<RoomIcon className={classes.writePostImg} />
					<Typography className={classes.writePostText}>
						Показать отчет на карте
					</Typography>
				</Button>
			) : null}
		</>
	);
};

export default ReportActions;
