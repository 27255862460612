import React from "react";
import { Typography, Box, Button } from "@material-ui/core";
import { translatePlaceType, switchIcons } from "../../../utils/placeUtils";
import useStyles from "./styles.js";
import StarRating from "../../Maps/ShowGeo/PlaceBox/StarRating.jsx";
import AuthorInfo from "../../AuthorInfo/AuthorInfo.jsx";

const LocationHeader = ({
	place,
	averageRate,
	openCity,
	isAddCity,
	setIsAddCity,
	titlePhotoObject,
	user,
}) => {
	const classes = useStyles();

	return (
		<div className={classes.menuTitleBox}>
			<div className={classes.cityTitleBox}>
				<Typography variant="h4">{place?.placeName}</Typography>
			</div>
			<div className={classes.cityTitleBox}>
				<div className={classes.rating}>
					<div className={classes.ratingFlex}>
						<StarRating rating={averageRate} />
						<Typography className={classes.rateReports}>
							{place?.placeRate.length ? averageRate.toFixed(1) : "N/A"}
						</Typography>
					</div>
					<Typography className={classes.numberReports}>
						Отзывов: {place?.placeRate.length}
					</Typography>
				</div>
			</div>
			<div className={classes.typeAuthorFlexBox}>
				<div className={classes.cityTitleBoxFinal}>
					<div className={classes.placeTypeBoxes}>
						<Box className={classes.boxType}>
							<img
								src={switchIcons(place?.placeType)}
								className={classes.boxTypeImg}
							/>
							<Typography variant="subtitle1" className={classes.boxTypeText}>
								{translatePlaceType(place?.placeType)}{" "}
							</Typography>
						</Box>
						{place?.placeType !== "city" && place?.cityPlaceName ? (
							<Box className={classes.boxType}>
								<Button
									component="span"
									name="test"
									onClick={openCity}
									className={classes.buttonCity}
								>
									<img
										src="https://img.icons8.com/sf-regular/48/FFFFFF/city-buildings.png"
										className={classes.boxTypeImg}
									/>
									<Typography
										variant="subtitle1"
										className={classes.boxTypeText}
									>
										{place?.cityPlaceName}
									</Typography>
								</Button>
							</Box>
						) : null}
					</div>
					{place && place?.placeType !== "city" && !place?.city && user ? (
						<Button
							className={classes.buttonPrimary}
							onClick={() => {
								setIsAddCity((prev) => !prev);
							}}
						>
							{isAddCity ? "Закрыть" : "Указать город"}
						</Button>
					) : null}
				</div>
				{titlePhotoObject && (
					<div className={classes.authorBox}>
						<AuthorInfo photoObject={titlePhotoObject} />
					</div>
				)}
			</div>
		</div>
	);
};

export default LocationHeader;
