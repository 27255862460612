import React from "react";
import { Bar, Doughnut } from "react-chartjs-2";
import { Typography, Grid } from "@material-ui/core";

const TripStatistics = ({ trip, selectedCurrency, exchangeRates }) => {
	if (!trip) return null;

	const categoryCostData = { transport: 0, stay: 0, activities: 0 };
	const activityTypeCostData = {};
	const dailyCostData = {};
	const milisecondsInDay = 86400000;

	const allDates = trip.cities.flatMap((city) => [
		new Date(city.visitDates.startDate),
		new Date(city.visitDates.endDate),
	]);
	const minDate = new Date(Math.min(...allDates));
	const maxDate = new Date(Math.max(...allDates));

	for (let d = new Date(minDate); d <= maxDate; d.setDate(d.getDate() + 1)) {
		const dateStr = d.toISOString().split("T")[0];
		dailyCostData[dateStr] = 0;
	}

	trip.cities.forEach((city) => {
		if (city.arrival?.totalCost && city.arrival?.currency) {
			const { totalCost, currency } = city.arrival;
			const convertedCost = parseFloat(
				(
					(totalCost * (exchangeRates[selectedCurrency] || 1)) /
					(exchangeRates[currency] || 1)
				).toFixed(2)
			);
			categoryCostData.transport += convertedCost;
			const arrivalDate = new Date(city.visitDates.startDate)
				.toISOString()
				.split("T")[0];
			dailyCostData[arrivalDate] += convertedCost;
		}

		if (city.stay?.totalCost && city.stay?.currency) {
			const { totalCost, currency } = city.stay;
			const convertedCost = parseFloat(
				(
					(totalCost * (exchangeRates[selectedCurrency] || 1)) /
					(exchangeRates[currency] || 1)
				).toFixed(2)
			);
			categoryCostData.stay += convertedCost;
			const stayStartDate = new Date(city.visitDates.startDate);
			const stayEndDate = new Date(city.visitDates.endDate);
			for (
				let d = new Date(stayStartDate);
				d <= stayEndDate;
				d.setDate(d.getDate() + 1)
			) {
				const dateStr = d.toISOString().split("T")[0];
				dailyCostData[dateStr] +=
					convertedCost /
					(stayEndDate / milisecondsInDay -
						stayStartDate / milisecondsInDay +
						1);
			}
		}

		if (city.activities) {
			city.activities.forEach((activity) => {
				if (activity.totalCost && activity.currency) {
					const { totalCost, currency } = activity;
					const convertedCost = parseFloat(
						(
							(totalCost * (exchangeRates[selectedCurrency] || 1)) /
							(exchangeRates[currency] || 1)
						).toFixed(2)
					);
					categoryCostData.activities += convertedCost;
					activityTypeCostData[activity.type] =
						(activityTypeCostData[activity.type] || 0) + convertedCost;

					const activityDate = new Date(activity.date)
						.toISOString()
						.split("T")[0];
					dailyCostData[activityDate] += convertedCost;
				}
			});
		}
	});

	if (trip?.endPlace?.arrival?.totalCost && trip?.endPlace?.arrival?.currency) {
		const { totalCost, currency } = trip?.endPlace?.arrival;
		const convertedCost = parseFloat(
			(
				(totalCost * (exchangeRates[selectedCurrency] || 1)) /
				(exchangeRates[currency] || 1)
			).toFixed(2)
		);
		categoryCostData.transport += convertedCost;
		const arrivalDate = new Date(trip?.endPlace?.arrival?.departureTime)
			.toISOString()
			.split("T")[0];
		dailyCostData[arrivalDate] += convertedCost;
	}

	// Prepare data for Bar chart
	const dailyCostChartData = {
		labels: Object.keys(dailyCostData),
		datasets: [
			{
				label: `Стоимость по дням`,
				data: Object.values(dailyCostData).map((val) =>
					parseFloat(val.toFixed(2))
				),
				backgroundColor: "rgba(54, 162, 235, 0.6)",
			},
		],
	};

	const dailyCostChartOptions = {
		scales: {
			xAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
			yAxes: [
				{
					gridLines: {
						display: false,
					},
				},
			],
		},
	};

	// Pie chart: Costs by category
	const categoryChartData = {
		labels: ["Транспорт", "Проживание", "Активности"],
		datasets: [
			{
				data: Object.values(categoryCostData).map((val) =>
					parseFloat(val.toFixed(2))
				),
				backgroundColor: ["#ff6384", "#36a2eb", "#ffcd56"],
			},
		],
	};

	// Doughnut chart: Activity costs by type
	const activityChartData = {
		labels: Object.keys(activityTypeCostData),
		datasets: [
			{
				data: Object.values(activityTypeCostData).map((val) =>
					parseFloat(val.toFixed(2))
				),
				backgroundColor: [
					"#ff6384",
					"#36a2eb",
					"#ffcd56",
					"#4bc0c0",
					"#9966ff",
					"#ff9f40",
				],
			},
		],
	};

	return (
		<div>
			{Object.values(dailyCostData).some((amount) => amount > 0) && (
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Typography variant="h6">
							Стоимость по дням, {selectedCurrency}
						</Typography>
						<Bar data={dailyCostChartData} options={dailyCostChartOptions} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="h6">
							Распределение затрат, {selectedCurrency}
						</Typography>
						<Doughnut data={categoryChartData} />
					</Grid>
					<Grid item xs={12} md={6}>
						<Typography variant="h6">
							Затраты по типам активности, {selectedCurrency}
						</Typography>
						<Doughnut data={activityChartData} />
					</Grid>
				</Grid>
			)}
		</div>
	);
};

export default TripStatistics;
