import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getTripById, updateTrip } from "../../../actions/trips";
import { useParams, useHistory } from "react-router-dom";
import {
	Button,
	TextField,
	Typography,
	IconButton,
	List,
	ListItem,
	Card,
	Container,
} from "@material-ui/core";
import { Add, Delete } from "@material-ui/icons";
import Select from "react-select";
import countryList from "react-select-country-list";
import moment from "moment";
import useStyles from "./styles";
import SearchGeo from "../../Maps/SelectGeo/SearchGeo/SearchGeo";
import { validateInput } from "../../../utils/validationUtils";

const TripEdit = ({ user }) => {
	const { id } = useParams();
	const history = useHistory();
	const dispatch = useDispatch();
	const { trip, isLoading } = useSelector((state) => state.trips);
	const [tripData, setTripData] = useState(null);
	const [error, setError] = useState("");
	const [errors, setErrors] = useState("");
	const [isSuccess, setIsSuccess] = useState(false);
	const options = useMemo(() => countryList().getData(), []);
	const classes = useStyles();

	const limits = {
		tripName: 500,
		startPlace: 200,
		newCity: 200,
		endPlace: 200,
	};

	useEffect(() => {
		dispatch(getTripById(id));
	}, [dispatch, id]);

	useEffect(() => {
		if (trip) setTripData({ ...trip });
	}, [trip]);

	const handleSelectedCity = (selectedPlace, index) => {
		const updatedCities = [...tripData.cities];
		updatedCities[index] = {
			...updatedCities[index],
			name: selectedPlace.placeName,
			cityId: selectedPlace?._id ? selectedPlace?._id : null,
		};
		setTripData({ ...tripData, cities: updatedCities });
	};

	const handleDateChange = (index, field, value) => {
		const updatedCities = [...tripData.cities];
		updatedCities[index].visitDates = {
			...updatedCities[index].visitDates,
			[field]: value,
		};
		setTripData({ ...tripData, cities: updatedCities });
	};

	const addCity = () => {
		setTripData({
			...tripData,
			cities: [
				...tripData.cities,
				{ name: "", visitDates: { startDate: "", endDate: "" } },
			],
		});
	};

	const removeCity = (index) => {
		const updatedCities = tripData.cities.filter((_, i) => i !== index);
		setTripData({ ...tripData, cities: updatedCities });
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!tripData.tripName || tripData.cities.length === 0) {
			setError("Все поля должны быть заполнены.");
			return;
		}

		const updatedTripData = {
			...tripData,
			userId:
				typeof tripData.userId === "object" && tripData.userId._id
					? tripData.userId._id
					: tripData.userId,
			cities: tripData.cities.map((city) => ({
				...city,
				cityId:
					typeof city.cityId === "object" && city.cityId._id
						? city.cityId._id
						: city.cityId,
			})),
		};

		dispatch(updateTrip(id, updatedTripData));
		setIsSuccess(true);
		history.push(`/trip/${id}`);
	};

	if (isLoading || !tripData) return <Typography>Loading...</Typography>;

	return (
		<Container className={classes.container} elevation={3}>
			{user?.result?._id === trip?.userId?._id ? (
				<>
					<Typography variant="h5" gutterBottom>
						Редактирование поездки
					</Typography>
					<TextField
						label="Название поездки"
						fullWidth
						value={tripData.tripName}
						onChange={(e) => {
							setTripData({ ...tripData, tripName: e.target.value });
							validateInput("tripName", e.target.value, limits, setErrors);
						}}
						margin="normal"
						error={!!errors?.tripName}
						helperText={errors?.tripName}
					/>
					<Typography variant="h6">Города</Typography>
					<List>
						{tripData.cities.map((city, index) => (
							<ListItem key={index} className={classes.cityRow}>
								<div>
									<Typography>
										{index + 1} {city?.name}
									</Typography>
									<Typography>Воспользуйтесь поиском города</Typography>
									<SearchGeo
										onSelectedPlace={(place) =>
											handleSelectedCity(place, index)
										}
										filter="city"
									/>
									<Typography>
										Или же введите название самостоятельно
									</Typography>
									<TextField
										size="small"
										label="Название города*"
										variant="outlined"
										fullWidth
										value={city.name}
										onChange={(e) => {
											handleSelectedCity(e, index);
											validateInput(
												"newCity",
												e.target.value,
												limits,
												setErrors
											);
										}}
										error={!!errors?.newCity}
										helperText={errors?.newCity}
									/>
									<TextField
										label="Дата приезда"
										type="date"
										value={moment(city.visitDates.startDate).format(
											"YYYY-MM-DD"
										)}
										onChange={(e) =>
											handleDateChange(index, "startDate", e.target.value)
										}
										InputLabelProps={{ shrink: true }}
										margin="normal"
									/>
									<TextField
										label="Дата отъезда"
										type="date"
										value={moment(city.visitDates.endDate).format("YYYY-MM-DD")}
										onChange={(e) =>
											handleDateChange(index, "endDate", e.target.value)
										}
										InputLabelProps={{ shrink: true }}
										margin="normal"
									/>
								</div>
								<IconButton onClick={() => removeCity(index)}>
									<Delete />
								</IconButton>
							</ListItem>
						))}
					</List>
					<Button
						onClick={addCity}
						startIcon={<Add />}
						variant="contained"
						className={classes.buttonSecondary}
					>
						Добавить город
					</Button>
					{/* <Typography variant="h6" gutterBottom>
				Страны поездки
			</Typography>
			<Select
				options={options}
				value={options.find((option) => option.label === tripData.countries)}
				styles={{
					container: (styles) => ({
						...styles,
						minHeight: "40px",
					}),
					control: (styles) => ({
						...styles,
						fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
						minHeight: "40px",
					}),
					option: (styles) => {
						return {
							...styles,
							fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
						};
					},
				}}
				onChange={(selected) =>
					setTripData({ ...tripData, countries: selected.label })
				}
			/>*/}

					{error && <Typography color="error">{error}</Typography>}
					{isSuccess && (
						<Typography color="primary">Поездка успешно обновлена!</Typography>
					)}
					<div className={classes.buttonsFlex}>
						<Button
							onClick={handleSubmit}
							color="primary"
							variant="contained"
							className={classes.buttonPrimary}
							disabled={Object.values(errors).some((err) => err)}
						>
							Сохранить изменения
						</Button>
						<Button
							variant="contained"
							color="secondary"
							onClick={() => history.push(`/trip/${id}`)}
							className={classes.buttonSecondary}
						>
							Вернуться в поездку
						</Button>
					</div>
				</>
			) : (
				<Typography variant="h5">
					Вы не можете редактировать эту поездку
				</Typography>
			)}
		</Container>
	);
};

export default TripEdit;
