// File: src/components/ReportPostsList/ReportPostsList.jsx
import React from "react";
import { Paper, Button, Typography } from "@material-ui/core";
import CreateIcon from "@material-ui/icons/Create";
import PostReport from "../../PostReport/PostReport";

const ReportPostsList = ({
	post,
	user,
	classes,
	setCreatePost,
	createPost,
}) => {
	return (
		<Paper className={classes.paperPost}>
			<PostReport user={user} post={post} />
			{user?.result?._id === post?.creator && (
				<Button
					onClick={() => setCreatePost(!createPost)}
					className={`${classes.buttonPrimary} ${classes.writePost} `}
				>
					<CreateIcon className={classes.writePostImg} />
					<Typography className={classes.writePostText}>
						Написать пост
					</Typography>
				</Button>
			)}
		</Paper>
	);
};

export default ReportPostsList;
