// src/components/Profile/ProfileStatistics.jsx

import React from "react";
import {
	Card,
	Typography,
	Box,
	LinearProgress,
	Button,
	CircularProgress,
} from "@material-ui/core";
import Select from "react-select";
import MapIcon from "@material-ui/icons/Map";
import useStyles from "../styles";

const ProfileStatistics = ({
	user,
	isLoadingUser,
	country,
	countryEmoji,
	countryReport,
	countryReportEmoji,
	numberCountries,
	isChangeCountries,
	setIsChangeCountries,
	changeHandler,
	submitCountryList,
	checkUser,
	options,
}) => {
	const classes = useStyles();

	return (
		<Card className={classes.cardStatistics}>
			<div className={classes.titleStatisticsFlex}>
				<Typography variant="h5">Статистика</Typography>
				<MapIcon className={classes.mapIconNoCountries} />
			</div>
			{isLoadingUser ? (
				<div className={classes.circularOther}>
					<CircularProgress color="secondary" />
				</div>
			) : (
				<>
					{country.length > 0 ? (
						<div className={classes.statisticsFlex}>
							<div className={classes.flexVisited}>
								<Typography className={classes.visitedCountriesText}>
									Пользователь посетил
								</Typography>
								<div className={classes.flex}>
									<Box className={classes.countriesNumberBox}>
										<Typography
											className={classes.countriesNumberText}
										>{`${country.length}`}</Typography>
									</Box>
									<Typography>
										{country?.length === 1
											? `страну из 233`
											: country?.length > 4
											? `стран из 233`
											: `страны из 233`}
									</Typography>
								</div>
							</div>
							<LinearProgress
								variant="determinate"
								value={(country.length / numberCountries) * 100}
								className={classes.linearProgress}
								color="secondary"
							/>
						</div>
					) : null}

					<Typography variant="subtitle1" className={classes.emojiList}>
						{countryEmoji.length > 0
							? countryEmoji.join("")
							: "Пользователь не указал посещенные страны"}
					</Typography>
					{countryReport.length > 0 ? (
						<div className={classes.statisticsFlex}>
							<div className={classes.flexVisited}>
								<Typography className={classes.visitedCountriesText}>
									Написано отчетов о
								</Typography>
								<div className={classes.flex}>
									<Box className={classes.countriesNumberBox}>
										<Typography
											className={classes.countriesNumberText}
										>{`${countryReport.length}`}</Typography>
									</Box>
									<Typography>
										{countryReport.length === 1 ? `стране` : `странах`}
									</Typography>
								</div>
							</div>
							<LinearProgress
								variant="determinate"
								value={(countryReport.length / numberCountries) * 100}
								className={classes.linearProgress}
								color="secondary"
							/>
						</div>
					) : null}
					<Typography variant="subtitle1" className={classes.emojiList}>
						{countryReportEmoji.length > 0
							? countryReportEmoji.join("")
							: "Пользователь пока не написал отчеты"}
					</Typography>
					{isChangeCountries ? (
						<div>
							<Select
								options={options}
								value={country}
								onChange={changeHandler}
								styles={{
									menu: (styles) => ({
										...styles,
										position: "static",
										zIndex: 1000,
									}),
									control: (styles) => ({
										...styles,
										fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
									}),
									option: (styles) => {
										return {
											...styles,
											fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
										};
									},
								}}
								isMulti
								placeholder="Выберите страну"
							/>
							<Button
								onClick={submitCountryList}
								className={classes.buttonPrimary}
							>
								Обновить список
							</Button>
						</div>
					) : checkUser ? (
						<div>
							<Button
								onClick={() => {
									setIsChangeCountries(!isChangeCountries);
								}}
								className={classes.buttonPrimary}
							>
								<MapIcon className={classes.mapIcon} />
								<Typography className={classes.addCountriesText}>
									Добавить страны
								</Typography>
							</Button>
						</div>
					) : null}
				</>
			)}
		</Card>
	);
};

export default ProfileStatistics;
