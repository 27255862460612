import React from "react";
import {
	Card,
	Typography,
	Button,
	CircularProgress,
	Grid,
} from "@material-ui/core";

import useStyles from "../styles";
import Post from "../../Posts/Post/Post";

const ProfileReports = ({ userPosts }) => {
	const classes = useStyles();

	return (
		<Card className={classes.cardPost}>
			<Typography variant="h5" className={classes.reportsTitle}>
				Отчеты пользователя
			</Typography>
			<div className={classes.reportsBox}>
				{userPosts === undefined ? (
					<div className={classes.circularProfile}>
						<CircularProgress color="secondary" />
					</div>
				) : userPosts.length === 0 ? (
					<div>
						<Typography>Отчетов пока нет</Typography>
						<Typography>
							Вы можете написать отчет, кликнув на кнопку ниже
						</Typography>
						<Button className={classes.buttonPrimary} href="/posts">
							<Typography>Написать отчет</Typography>
						</Button>
					</div>
				) : (
					<div>
						<Grid
							container
							justifyContent="space-evenly"
							alignItems="stretch"
							spacing={3}
							className={classes.gridContainerPosts}
						>
							{userPosts.map((post) => (
								<Grid key={post._id} item xs={12} sm={12} md={6} lg={4}>
									<Post post={post} setCurrentId={0}></Post>
								</Grid>
							))}
						</Grid>
					</div>
				)}
			</div>
		</Card>
	);
};

export default ProfileReports;
